import React, { Fragment, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const GridColumnInsertMenuItem = props => {
  const { colDef, onClick, setScenarioChanged, setGridData, setGridColumns } =
    props;
  const apiRef = useGridApiContext()
  const inputEl = React.useRef(null)

  const [open, setOpen] = React.useState(false)
  const [newColumn, setNewColumn] = useState('');

  const stopPropagationForTab = event => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  const [valueError, setValueError] = useState(false)
  const handleChange = event => {
    let currentColumns = apiRef.current.getAllColumns()
    setValueError(currentColumns.some(c => c.headerName === event.target.value || c.field === event.target.value))
    setNewColumn(event.target.value)
  }


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (event) => {
    setNewColumn('')
    setOpen(false)
    if (onClick) {
      onClick(event)
    }
  }

  if (!colDef) {
    return null;
  }

  const handleInsertTagButtonClick = event => {
    let mutatedColumns = apiRef.current.getAllColumns()
    let columnIndex = mutatedColumns.findIndex(
      (c) => c.headerName === colDef.headerName
    );
    mutatedColumns.splice(columnIndex + 1,0,{
      field: `tag_${newColumn}`,
      headerName: newColumn,
      flex: 1,
      minWidth: 150,
      editable: true,
      valueGetter: params => {
        return params.row.tags ? params.row.tags[`${newColumn}`] : params.row[`${newColumn}`]
      },
      valueSetter: (params) => {
        return {
          ...params.row,
          tags: {
            ...params.row.tags,
            [newColumn]: params.value
          }
        }
      },
    })

    let rowModelsMap = apiRef.current.getRowModels()
    let mutatedRowModels = Array.from(rowModelsMap.values()).map(row => {
      let newRow = Object.assign({}, row)
      if (newRow.tags) {
        newRow.tags[newColumn] = null
      } else {
        newRow.tags = {[newColumn]: null}
      }
      return newRow
    })
    apiRef.current.updateColumns(mutatedColumns)
    apiRef.current.setRows(mutatedRowModels)
    apiRef.current.forceUpdate()

    // Wait for the grid to render with the new row
    setTimeout(() => {
      setGridColumns(mutatedColumns)
      setGridData(mutatedRowModels)
    })
    setScenarioChanged(true)
    setNewColumn('')
    setOpen(false)
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Fragment>
      <MenuItem onClick={handleClickOpen}>Insert Tag</MenuItem>
      <Dialog 
        open={open} 
        onClose={handleClose}
        onKeyDown={stopPropagationForTab}
        ref={inputEl}
        disableEnforceFocus
        >
        <DialogTitle>New Tag</DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2} sx={{minWidth: "300px", minHeight: "140px"}}>
            <DialogContentText>This will insert a new column</DialogContentText>
            <Divider variant='middle' />
            <TextField
              id={`insert-new-column`}
              type='text'
              label='New Tag'
              autoFocus
              margin='dense'
              value={newColumn}
              onChange={handleChange}
              onKeyDown={(e) => {e.stopPropagation()}}
              error={valueError}
              helperText={valueError ? 'The new tag (header) must be unique' : undefined}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={valueError} onClick={handleInsertTagButtonClick}>Create</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export { GridColumnInsertMenuItem }
