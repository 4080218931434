/* eslint-disable */

export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owners
      }
      nextToken
    }
  }
`;
export const listScenarioNames = /* GraphQL */ `
  query ListScenarioNames(
    $filter: ModelScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const listScenarioStore = /* GraphQL */ `
  query ListScenarioStore(
    $filter: ModelScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        updatedAt
        owners
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      spoc_email
      api_key
      region
      params {
        name
        values
      }
      integrations {
        integration_type
        primary_property
        secondary_property
        bp_integration {
          _id
          name
          stream_id
          type
          source_system
          transport
          parent_source_system
          dataset_options
        }
      }
      owners
      bporgname
    }
  }
`;
export const getScenarioOrgs = /* GraphQL */ `
  query getScenarioOrgs($id: ID!) {
    getScenario(id: $id) {
      id
      name
      orgs {
        items {
          org {
            id
            name
          }
        }
      }
    }
  }
`;
export const getScenarioMeta = /* GraphQL */ `
query GetScenario($id: ID!) {
  getScenario(id: $id) {
    id
    name
    eventPages {
      items {
        id
      }
      nextToken
    }
    changePages {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const getScenario = /* GraphQL */ `
  query GetScenario($id: ID!) {
    getScenario(id: $id) {
      id
      name
      description
      category
      events {
        event_type
        _offset
        seconds
        integration_type
        primary_property
        secondary_property
        tags
      }
      changes {
        integration_type
        _offset
        identifier
        status
        summary
        ticket_url
        tags
      }
      groupsCanEdit
      groupsCanRead
      owners
      eventPages {
        items {
          id
          page
          events {
            event_type
            _offset
            seconds
            integration_type
            primary_property
            secondary_property
            tags
          }
          groupsCanEdit
          groupsCanRead
          owners
          scenarioEventPagesId
        }
        nextToken
      }
      changePages {
        items {
          id
          page
          changes {
            integration_type
            _offset
            identifier
            status
            summary
            ticket_url
            tags
          }
          groupsCanEdit
          groupsCanRead
          owners
          scenarioChangePagesId
        }
        nextToken
      }
    }
  }
`;

export const listOrgScenarios = /* GraphQL */ `
  query ListOrgScenarios(
    $filter: ModelOrgScenarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgID
        scenarioID
        owners
        scenario {
          id
          name
          owners
        }
      }
      nextToken
    }
  }
`;

// export const listOrgScenarioIDs = /* GraphQL */ `
//   query ListOrgScenarioIDs(
//     $filter: ModelOrgScenarioFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listOrgScenarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
//       items {
//         id
//       }
//       nextToken
//     }
//   }
// `;
export const listOrgScenarioIDs = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      scenarios {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const getOrgScenario = /* GraphQL */ `
  query GetOrgScenario($id: ID!) {
    getOrgScenario(id: $id) {
      id
      orgID
      scenarioID
      owners
      scenario {
        id
        name
        category
      }
    }
  }
`;
export const listBpIntegrations = /* GraphQL */ `
  query ListBpIntegrations($query: QueryListBpIntegrationsQueryInput!) {
    listBpIntegrations(query: $query) {
      response {
        status
      }
      item {
        _id
        name
        stream_id
        type
        source_system
        transport
        parent_source_system
        dataset_options
      }
    }
  }
`;
export const listBpIntegrationsEU = /* GraphQL */ `
  query ListBpIntegrationsEU($query: QueryListBpIntegrationsEUQueryInput!) {
    listBpIntegrationsEU(query: $query) {
      response {
        status
      }
      item {
        _id
        name
        stream_id
        type
        source_system
        transport
        parent_source_system
        dataset_options
      }
    }
  }
`;
export const getBpOrgInfo = /* GraphQL */ `
  query GetBpOrgInfo($query: QueryGetBpOrgInfoQueryInput!) {
    getBpOrgInfo(query: $query) {
      _id
      username
      name
      organization {
        _id
        name
        display_name
        created_at
        api_token
        config
        feature_toggles
      }
      createdAt
      measure
      roles
      state
      firstLoginAt
      lastLoginAt
      ip
      intercom_user_hash
      permissions
      granular_permissions
    }
  }
`;
export const getBpOrgInfoEU = /* GraphQL */ `
  query GetBpOrgInfoEU($query: QueryGetBpOrgInfoEUQueryInput!) {
    getBpOrgInfoEU(query: $query) {
      _id
      username
      name
      organization {
        _id
        name
        display_name
        created_at
        api_token
        config
        feature_toggles
      }
      createdAt
      measure
      roles
      state
      firstLoginAt
      lastLoginAt
      ip
      intercom_user_hash
      permissions
      granular_permissions
    }
  }
`;
export const listEventPages = /* GraphQL */ `
  query ListEventPages(
    $filter: ModelEventPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        page
        events {
          event_type
          _offset
          seconds
          integration_type
          primary_property
          secondary_property
          tags
        }
        groupsCanEdit
        groupsCanRead
        owners
        scenarioEventPagesId
      }
      nextToken
    }
  }
`;
export const listChangePages = /* GraphQL */ `
  query ListChangePages(
    $filter: ModelChangePageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangePages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        page
        changes {
          integration_type
          _offset
          identifier
          status
          summary
          ticket_url
          tags
        }
        groupsCanEdit
        groupsCanRead
        owners
        scenarioChangePagesId
      }
      nextToken
    }
  }
`;

export const getBpIncidents = /* GraphQL */ `
  query GetBpIncidents(
    $access_token: String!
    $region: String!
    $envId: String!
    $filter: AWSJSON
  ) {
    getBpIncidents(
      access_token: $access_token
      region: $region
      envId: $envId
      filter: $filter
    )
  }
`;
export const getBpIncidentEvents = /* GraphQL */ `
  query GetBpIncidentEvents(
    $access_token: String!
    $region: String!
    $incidentId: String
  ) {
    getBpIncidentEvents(
      access_token: $access_token
      region: $region
      incidentId: $incidentId
    )
  }
`;

export const getBpEnvironments = /* GraphQL */ `
  query GetBpEnvironments($query: QueryGetBpEnvironmentsQueryInput!) {
    getBpEnvironments(query: $query)
  }
`;
export const getBpEnvironmentsEU = /* GraphQL */ `
  query GetBpEnvironmentsEU($query: QueryGetBpEnvironmentsEUQueryInput!) {
    getBpEnvironmentsEU(query: $query)
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        description
        type
        secret
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $event_id: ID
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      event_id: $event_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        event_id
        title
        start
        end
        disabled
        color
        textColor
        editable
        deletable
        draggable
        allDay
        project
        history
        comment
        owner
        scheduleResourceId
      }
      nextToken
    }
  }
`;