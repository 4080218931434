import React, { useState, useContext, useEffect } from "react";

// MUI components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

// custom components
import { useSnackbar } from "notistack";
import AuthContext from "store/AuthContext";
import useAppState from "store/appState";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import CloneOrgDialog from "../../components/CloneOrgDialog/CloneOrgDialog";
import DeleteOrgDialog from "../../components/DeleteOrgDialog/DeleteOrgDialog";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

import { Logger } from "aws-amplify";
const logger = new Logger("OrgMetaTab", "INFO");

const OrgMetaTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const { user } = useContext(AuthContext);

  // local state
  const [apiKey, setApiKey] = useState(demoConfig?.api_key);
  const [spocEmail, setSpocEmail] = useState(demoConfig?.spoc_email);
  const [owners, setOwners] = useState(demoConfig?.owners);
  const [cloneOrgDialogOpen, setCloneOrgDialogOpen] = useState(false);
  const [deleteOrgDialogOpen, setDeleteOrgDialogOpen] = useState(false);

  useEffect(() => {
    if (demoConfig?.id) logger.info("Demo Config data:", demoConfig);
  }, [demoConfig]);

  useEffect(() => {
    setApiKey(demoConfig?.api_key);
  }, [demoConfig?.api_key]);

  function handleChangeApiKey(value) {
    if (value !== demoConfig.api_key) {
      setApiKey(value);
      updateDemoConfig.mutate({
        ...demoConfig,
        api_key: value,
      });
    }
  }

  function handleApiKeyKeyPress(event) {
    if (event.keyCode === 13) {
      if (event.target.value !== demoConfig?.api_key) {
        handleChangeApiKey(event.target.value);
      }
    }
  }

  function handleApiKeyBlur(event) {
    if (event.target.value !== demoConfig?.api_key) {
      handleChangeApiKey(event.target.value);
    }
  }

  useEffect(() => {
    setSpocEmail(demoConfig?.spoc_email);
  }, [demoConfig?.spoc_email]);

  function handleChangeSpocEmail(value) {
    if (value !== demoConfig.spoc_email) {
      setSpocEmail(value);
      updateDemoConfig.mutate({
        ...demoConfig,
        spoc_email: value,
      });
    }
  }

  function handleSpocEmailKeyPress(event) {
    if (event.keyCode === 13) {
      if (event.target.value !== demoConfig.spoc_email) {
        handleChangeSpocEmail(event.target.value);
      }
    }
  }

  function handleSpocEmailBlur(event) {
    if (event.target.value !== demoConfig.spoc_email) {
      handleChangeSpocEmail(event.target.value);
    }
  }

  useEffect(() => {
    setOwners(demoConfig?.owners);
  }, [demoConfig?.owners]);

  const handleChangeOwners = React.useCallback(
    (event, newOwners) => {
      logger.info("handleChangeOwners", newOwners);
      if (!newOwners.includes(user.username)) {
        enqueueSnackbar(
          `Sorry, you can't remove yourself (${user.username}). Add someone else to the owners list, then they can remove you.`,
          { variant: "error" }
        );
      } else {
        // local state set first for performance ( a change on demoConfig will override anyway)
        setOwners(newOwners);
        updateDemoConfig.mutate({
          ...demoConfig,
          owners: newOwners,
        });
      }
    },
    [demoConfig, setOwners, updateDemoConfig, user.username, enqueueSnackbar]
  );

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <LoadingSpinner />
      <Grid
        id="org config settings"
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="flex-start"
        maxWidth="50%"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5">Demo Config Settings</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="caption">
            Link this Demo Config to a BigPanda Org
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={"flex-start"}
          order={{ xs: 3, sm: 1 }}
        >
          <TextField
            id="org-name"
            inputProps={{ "data-test": "org-name" }}
            fullWidth
            label="Demo Config Name"
            value={demoConfig?.name}
            disabled={true}
            helperText="Describe this Demo Config."
            variant="standard"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          order={{ xs: 1, sm: 2 }}
        >
          <Button
            id="btn-org-delete"
            data-test="btn-org-delete"
            size="small"
            sx={{ height: "max-content", whiteSpace: "pre-line" }}
            color="warning"
            variant="contained"
            onClick={() => setDeleteOrgDialogOpen(true)}
          >
            {`Delete 
          Demo Config`}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          container
          justifyContent={{ xs: "center", sm: "flex-end" }}
          order={{ xs: 1, sm: 2 }}
        >
          <Button
            id="btn-org-clone"
            data-test="btn-org-clone"
            size="small"
            sx={{ height: "max-content", whiteSpace: "pre-line" }}
            color="secondary"
            variant="contained"
            onClick={() => setCloneOrgDialogOpen(true)}
            disabled={demoConfig.bporgname ? false : true}
          >
            {`Clone
          Demo Config`}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={"flex-start"}
          order={{ xs: 5, sm: 4 }}
        >
          <TextField
            id="api-key"
            inputProps={{ "data-test": "api-key", "data-lpignore": true }}
            label="BigPanda Org User API Key"
            fullWidth
            value={apiKey}
            onChange={(event) => setApiKey(event.target.value)}
            onKeyDown={handleApiKeyKeyPress}
            onBlur={handleApiKeyBlur}
            autoComplete="off"
            helperText="Links this Demo Config to a BigPanda Org."
            variant="standard"
            type="password"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={"flex-start"}
          order={{ xs: 4, sm: 5 }}
        >
          <TextField
            id="org-spoc-email"
            inputProps={{
              "data-test": "org-spoc-email",
              "data-lpignore": true,
            }}
            label="SPOC Email"
            fullWidth
            value={spocEmail}
            onChange={(event) => setSpocEmail(event.target.value)}
            onKeyDown={handleSpocEmailKeyPress}
            onBlur={handleSpocEmailBlur}
            autoComplete="off"
            helperText="This Demo Config's Single Point Of Contact."
            variant="standard"
            type="email"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={"flex-start"}
          order={{ xs: 7, sm: 6 }}
        >
          <Autocomplete
            clearIcon={false}
            id="autocomplete-scenario-owners"
            data-test="autocomplete-scenario-owners"
            fullWidth
            freeSolo
            multiple
            size="small"
            options={owners}
            disabled={!owners.includes(user.username)}
            value={owners}
            onChange={handleChangeOwners}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Demo Config Owners"
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent={"start"}
          order={{ xs: 7, sm: 8 }}
        >
          <TextField
            id="bp-region"
            inputProps={{ "data-test": "bp-region" }}
            label="Region"
            value={`${demoConfig?.region}`}
            disabled={true}
            helperText="The region for this Demo Config"
            variant="standard"
          />
        </Grid>
        <CloneOrgDialog
          open={cloneOrgDialogOpen}
          setOpen={setCloneOrgDialogOpen}
        />
        <DeleteOrgDialog
          open={deleteOrgDialogOpen}
          setOpen={setDeleteOrgDialogOpen}
        />
      </Grid>
    </Box>
  );
};

export default OrgMetaTab;
