import { useState, useEffect, useCallback, useMemo } from "react";
import { Logger } from "aws-amplify";
import ReactJson from "@microlink/react-json-view";
import { parse, isValid as isTimeDate, format } from "date-fns";

// MUI components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

// Custom components
import OrgSelector from "../../../components/OrgSelector/OrgSelector.js";
import IncidentSearch from "../../../components/IncidentSearch/IncidentSearch";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalLinearStepper";

// Custom hooks and functions
import useAppState from "../../../store/appState";
import { useDemoConfig } from "../../../store/serverState";
import CheckList from "../../../components/CheckList/CheckList";
import AlertTagSummary from "../../../components/DataItemDisplay/AlertTagSummary";
import { isValidJSON } from "../../../lib/transform_funcs";
import {
  queryOpenAI,
  removeFirstAndLastIfJSONBlock,
  tightStringify,
} from "../../../lib/ai_funcs";

const logger = new Logger("AiAssist/SiConfigGen", "INFO");

const model = "gpt-4o-mini";

export default function SiConfigGen() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  // Local state
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [sampleTags, setSampleTags] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [shouldIncludeUncategorized, setShouldIncludeUncategorized] =
    useState(false);
  const [llmResponse, setLlmResponse] = useState(null);
  const [llmResponseError, setLlmResponseError] = useState(null);
  const [llmResponseLoading, setLlmResponseLoading] = useState(false);

  // reset local state
  const handleReset = () => {
    setSelectedIncidents([]);
    setSampleTags({});
    setSelectedTags([]);
    setShouldIncludeUncategorized(false);
    setLlmResponse(null);
    setLlmResponseError(null);
    setLlmResponseLoading(false);
  };

  const prompt = useMemo(() => {
    return `
    System: You are an expert in AIOps, specifically in event correlation and incident management within IT Operations.
    
    User: Given the context of IT Operations and AIOps, I need you to categorize a set of unique fields commonly found in IT alerts. The categories are:

    - Entity: Fields describing what specifically is having the problem (e.g., host, node, object).
    - Problem: Fields describing what the problem is (e.g., check, health_rule, alert_name).
    - Impact: Fields describing what is impacted, besides the direct entity (e.g., application, service).
    - Topological: Fields describing additional relational/topological information (e.g., cluster, location, environment).
    ${shouldIncludeUncategorized ? "- Uncategorized: Any field that does not clearly fit into any of the above 4 categories." : ``}

    Refer to the example categorizations below for two key reasons:
    1) To gain a general (and deeper) understanding of how to categorize various types of fields.
    2) To know how to categorize these specific fields if they appear in the list of fields I provide for you to categorize.
    (Note: Fields that would fall under an 'Uncategorized' category are not included in this example.)
    Example categorizations:
    [
      {
         "problemFields":[
            "check",
            "bp_check",
            "problem",
            "health_rule",
            "alert_name",
            "search_name",
            "monitor_name",
            "issue",
            "alert_metric",
            "event_name",
            "rsTrapDescr",
            "mail_subject",
            "title",
            "rulename",
            "eventRuleName",
            "description",
            "bp_description",
            "EventDescription"
         ]
      },
      {
         "entityFields":[
            "host",
            "bp_host",
            "object",
            "device",
            "device_name",
            "qmgr_name",
            "eventInstance",
            "node",
            "container_name",
            "database",
            "instance",
            "ap_serial_number",
            "monitoried_url",
            "endpoint",
            "network_device",
            "impacted_service",
            "app",
            "app_id",
            "agent"
         ]
      },
      {
         "impactFields":[
            "app",
            "application",
            "bp_application",
            "bp_application_grp",
            "service",
            "platform",
            "business_svc",
            "component",
            "impacted_service",
            "app_id",
            "appname",
            "apiproxyname"
         ]
      },
      {
         "topologyFields":[
            "dependency",
            "connected_to",
            "cluster",
            "location",
            "rack",
            "datacenter",
            "dc",
            "region",
            "zone",
            "aws_region",
            "server_environment",
            "topo_dependencies_app",
            "server_location",
            "envname",
            "env"
         ]
      }
   ]
    
    Actual Fields for Categorization:
    The unique fields you are tasked with categorizing are: ${selectedTags.map((tag) => tag.name).join(", ")}
  
    Important: You should only categorize a field into the 4 categories - Entity, Problem, Impact, Topological - if you are confident that that the field belongs there. There will be many fields that do not belong in any of those 4 main categories, and that is okay. ${shouldIncludeUncategorized ? "If a field does not fit into any of these categories, it should be placed in the  'Uncategorized' category. " : "If a field does not fit into any of these 4 categories, then just ignore it."}

    You must return your categorizations as a JSON array, where each element is an object with the field name and its category. Your entire response must be valid JSON in the below structure:
    [
      { "entityFields": [ "field1", "field2", etc ] },
      { "problemFields": [  "field4", "field5", etc ] },
      { "impactFields": [  "field7", "field8", etc ] },
      { "topologyFields": [  "field9", "field10", etc ] },
      ${shouldIncludeUncategorized ? '{ "uncategorizedFields": [  "field11", "field12", etc ] },' : ""}
    ]

    Important: Do not return anything but the JSON array. Do not include any other text or comments in your response.
  `;
  }, [selectedTags, shouldIncludeUncategorized]);

  // set local state based on selected incidents and goals
  useEffect(() => {
    const invalidTags = [
      "alert_updates",
      // "description",
      // "short_description",
      // "check",
      "timestamp",
      "start",
      "end",
      "opened_at",
      "alert_updates",
      "primary_property",
      "secondary_property",
      "email",
      "username",
      "phone",
      "status",
      "severity",
      "priority",
      "impact",
      // "state",
      "assignment_group",
      "team",
      "source",
      "integration_name",
      "integration_type",
      "integration_id",
      "sys_updated_on",
      "sys_updated_by",
      "assigned_to",
      "null",
      "undefined",
      "",
    ];
    let newTags = {};
    selectedIncidents.forEach((incident) => {
      incident.alerts.forEach((alert) => {
        alert.tags
          .filter(
            (tag) =>
              !tag.name.startsWith("alert_context_") &&
              !tag.name.startsWith("ProblemDetailsJSON_") &&
              !tag.name.startsWith("failuredetail") &&
              !isTimeDate(tag.value)
          )
          .filter((tag) => !invalidTags.includes(tag.name))
          .forEach((tag) => {
            // use Set to avoid duplicates. wrap set in an array to convert back to array
            // oldTags[tag.name] = [...oldTags[tag.name], tag.value];
            if (Array.isArray(newTags[tag.name])) {
              if (Array.isArray(tag.value)) {
                newTags[tag.name] = [
                  ...new Set([...newTags[tag.name], ...tag.value]),
                ];
              } else
                newTags[tag.name] = [
                  ...new Set([...newTags[tag.name], tag.value]),
                ];
            } else newTags[tag.name] = [tag.value];
          });
      });
    });
    setSampleTags(newTags);
    logger.info("sampleTags updated", newTags);
  }, [selectedIncidents]);

  const getLlmResponse = useCallback(async () => {
    setLlmResponseLoading(true);
    setLlmResponse(null);
    try {
      const chatCompletion = await queryOpenAI({
        messages: [
          { role: "system", content: "You are an IT Operations expert" },
          { role: "user", content: prompt },
        ],
        model: model,
        temperature: 0,
      });
      let llmResultStr = removeFirstAndLastIfJSONBlock(
        chatCompletion?.choices[0]?.message?.content
      );
      setLlmResponse(llmResultStr);
      logger.info("chatCompletion", chatCompletion);
    } catch (error) {
      logger.error("Error in getLlmResponse", error);
      setLlmResponseError(error);
    } finally {
      setLlmResponseLoading(false);
    }
  }, [prompt]);

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Similar Incidents Config Generator enables you to:
      - Select a subset of incidents from your BigPanda org
      - Use AI to identify similar tags
      - Generate and download a Similar Incidents Configuration file

      To perform the following steps you need a "Demo Config" with a BigPanda Org User API Key.

      If you've already created a Demo Config, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        // contextComponent: (
        // ),
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Query BigPanda and select incidents",
        optional: false,
        description: `Use the query parameters here to filter incidents from BigPanda.

        The query results will be displayed in the Incident List below. 

        None of the incidents will be selected by default; 
        you must select the incidents you want to use in your analysis.`,
        component: (
          <IncidentSearch
            selectedIncidents={selectedIncidents}
            setSelectedIncidents={setSelectedIncidents}
            expanded={true}
          />
        ),
        gate: selectedIncidents.length > 0,
      },
      {
        label: "Validate Tags",
        optional: false,
        description: `Review the tags from the selected incidents.
        Deselect any tags that you do not want considered in the analysis`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <CheckList
              itemList={Object.keys(sampleTags).map((tag) => {
                return { name: tag, value: sampleTags[tag] };
              })}
              selectedItems={selectedTags}
              setSelectedItems={setSelectedTags}
              matchProp="name"
              DisplayComponent={AlertTagSummary}
            />
          </Stack>
        ),
        gate: selectedTags.length > 0,
      },
      {
        label: "Analyze & Download",
        optional: false,
        description: `Click the ANALYZE button to generate an AI-assisted SI analysis.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Checkbox
                checked={shouldIncludeUncategorized}
                onChange={(e) => {
                  setShouldIncludeUncategorized(e.target.checked);
                }}
              />
              <Typography>
                Include an "Uncategorized" category for fields that don't fit
                into the other categories
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              onClick={getLlmResponse}
              sx={{ width: "200px" }}
            >
              Analyze
            </Button>

            {llmResponseLoading && <LinearProgress />}
            {llmResponseError && (
              <Typography color="error">
                Error: {llmResponseError.message}
              </Typography>
            )}
            {llmResponse && (
              <Card>
                <CardHeader title="AI Analysis" />
                <CardContent>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ marginBottom: 2 }}
                    disabled={
                      !isValidJSON(llmResponse) ||
                      llmResponseLoading ||
                      llmResponseError ||
                      llmResponse === null ||
                      llmResponse === "" ||
                      llmResponse.length === 0
                    }
                    onClick={() => {
                      const blob = new Blob([llmResponse], {
                        type: "application/json",
                      });
                      const url = URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = "si_config.json";
                      a.click();
                    }}
                  >
                    Download Configuration File
                  </Button>
                  <ReactJson
                    src={
                      isValidJSON(llmResponse) ? JSON.parse(llmResponse) : {}
                    }
                    theme="monokai"
                    collapsed={false}
                    name={null}
                  />
                </CardContent>
              </Card>
            )}
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    demoConfig.bporgname,
    selectedIncidents,
    sampleTags,
    selectedTags,
    llmResponse,
    shouldIncludeUncategorized,
    llmResponseError,
    llmResponseLoading,
    getLlmResponse,
  ]);

  return <HorizontalLinearStepper steps={steps} resetFunction={handleReset} />;
}

