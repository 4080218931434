import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Logger } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";

// MUI components
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

// Custom components
import OrgSelector from "../../components/OrgSelector/OrgSelector.js";
import DataGridEditor from "../../components/DataGridEditor/DataGridEditor";
import ScenarioCreator from "../../components/ScenarioCreator/ScenarioCreator";
import { menuCategories } from "../../templates/initialStateTemplates";
import IncidentSearch from "../../components/IncidentSearch/IncidentSearch";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../components/WizardStepper/HorizontalLinearStepper";
// Custom hooks and functions
import useAppState from "../../store/appState";
import { useDemoConfig } from "../../store/serverState";
import { getBpIncidentEvents } from "../../store/graphql-functions";

import { useSnackbar } from "notistack";

const logger = new Logger("ScenarioLab", "INFO");

export default function ScenarioLab() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { enqueueSnackbar } = useSnackbar();

  // Local state
  const [eventData, setEventData] = useState([]);
  const [eventColumns, setEventColumns] = useState([]);
  const [selectedIncidents, setSelectedIncidents] = useState([]);
  const [bpIncidentEvents, setBpIncidentEvents] = useState([]);
  const [isBpIncidentEventsLoading, setIsBpIncidentEventsLoading] =
    useState(false);
  // define a function using getBpIncidentEvents to get incident events
  const getIncidentEvents = useCallback(
    async function getIncidentEvents() {
      try {
        const events = await getBpIncidentEvents({
          region: demoConfig.region,
          access_token: demoConfig.api_key,
          incidentIDs: selectedIncidents.map((incident: any) => incident.id),
        });
        return events;
      } catch (error) {
        logger.error("getIncidentEvents error:", error);
      }
    },
    [demoConfig.api_key, demoConfig.region, selectedIncidents]
  );

  const integrationTypes = React.useMemo(
    () => demoConfig.integrations.map((i: any) => i.integration_type),
    [demoConfig.integrations]
  );

  const eventsToColumns = useCallback(
    function eventsToColumns(events) {
      return events.reduce(
        (headers, event) => {
          if (event.event_type === "ALERT" || event.event_type === "ITAG") {
            let accessors = headers.map((h) => h?.field);
            let tags = Boolean(event?.tags)
              ? Object.keys(JSON.parse(event.tags))
              : [];
            let newTags = tags.filter((t) => !accessors.includes(`tag_${t}`));
            newTags.forEach((t) =>
              headers.push({
                headerName: t,
                field: `tag_${t}`,
                flex: 1,
                minWidth: 150,
                editable: true,
                valueGetter: (params) => {
                  return params.row.tags
                    ? params.row.tags[`${t}`]
                    : params.row[`${t}`];
                },
                valueSetter: (params) => {
                  return {
                    ...params.row,
                    tags: {
                      ...params.row.tags,
                      [t]: params.value,
                    },
                  };
                },
              })
            );
          }
          return [...headers];
        },
        [
          {
            field: "event_type",
            description: "ALERT, ITAG, or PAUSE",
            width: 120,
            editable: false,
            type: "singleSelect",
            valueOptions: ["ALERT", "PAUSE", "ITAG"],
          },
          {
            field: "_offset",
            description:
              'Seconds from scenario start. Scenario starts at "now" minus the highest scenario offset',
            width: 120,
            editable: true,
            type: "number",
            valueFormatter: ({ value }) => Number(value),
          },
          {
            field: "seconds",
            description: "Seconds to pause (for PAUSE events)",
            width: 120,
            editable: true,
            type: "number",
            valueFormatter: (params) =>
              params.value !== 0 ? params.value : "",
          },
          {
            field: "integration_type",
            description:
              "The integration type for this event. Assign this type to an Org integration in the integrations tab.",
            width: 180,
            editable: true,
            type: "singleSelect",
            valueOptions: [
              ...integrationTypes.filter((t) => t !== "change-man"),
              " ",
            ],
          },
          {
            field: "primary_property",
            description: "The primary property for this event.",
            width: 190,
            editable: true,
            preProcessEditCellProps: (params) => {
              // reset error to false
              const newProp = { ...params.props, error: false };
              logger.info("params:", params);
              //TODO cannot read 'value' if undefined
              if (params.row.event_type === "ALERT") {
                let target_integration = demoConfig.integrations.find(
                  (i) =>
                    i.integration_type ===
                    params.otherFieldsProps.integration_type?.value
                );
                if (
                  params.hasChanged &&
                  target_integration.primary_property !== params.props.value
                )
                  newProp.error = true;
                if (
                  !params.hasChanged &&
                  target_integration.primary_property !== params.props.value
                )
                  newProp.value = target_integration.primary_property;
                if (
                  !params.otherFieldsProps.hasOwnProperty(
                    `tag_${newProp.value}`
                  )
                )
                  newProp.error = true;
              }
              return newProp;
            },
          },
          {
            field: "secondary_property",
            description: "The secondary property for this event.",
            width: 210,
            editable: true,
            preProcessEditCellProps: (params) => {
              const newProp = { ...params.props, error: false };
              if (params.row.event_type === "ALERT") {
                let target_integration = demoConfig.integrations.find(
                  (i) =>
                    i.integration_type ===
                    params.otherFieldsProps.integration_type?.value
                );
                if (
                  params.hasChanged &&
                  target_integration.secondary_property !== params.props.value
                )
                  newProp.error = true;
                if (
                  !params.hasChanged &&
                  target_integration.secondary_property !== params.props.value
                )
                  newProp.value = target_integration.secondary_property;
                if (
                  !params.otherFieldsProps.hasOwnProperty(
                    `tag_${newProp.value}`
                  )
                )
                  newProp.error = true;
              }
              return newProp;
            },
          },
          {
            headerName: "status",
            field: `tag_status`,
            flex: 1,
            minWidth: 150,
            editable: true,
            type: "singleSelect",
            valueOptions: [
              "critical",
              "warning",
              "ok",
              "unknown",
              "acknowledged",
            ],
            valueGetter: (params) => {
              return params.row.tags
                ? params.row.tags[`status`]
                : params.row[`status`];
            },
            valueSetter: (params) => {
              return {
                ...params.row,
                tags: {
                  ...params.row.tags,
                  status: params.value,
                },
              };
            },
          },
        ]
      );
    },
    [demoConfig.integrations, integrationTypes]
  );

  function eventsToRows(events) {
    return events.map((event) => {
      return {
        id: uuidv4(),
        event_type: event.event_type,
        _offset: Number(event._offset),
        seconds: Number(event.seconds),
        integration_type: event.integration_type,
        primary_property: event.primary_property,
        secondary_property: event.secondary_property,
        tags: JSON.parse(event.tags),
      };
    });
  }

  useEffect(() => {
    if (
      Array.isArray(bpIncidentEvents) &&
      bpIncidentEvents.length > 0 &&
      selectedIncidents.length > 0
    ) {
      let formattedEvents = bpIncidentEvents.map((event: any) => {
        const {
          event_type,
          _offset,
          seconds,
          integration_type,
          primary_property,
          secondary_property,
          ...eventTags
        } = event;
        return {
          event_type,
          _offset: Number(_offset),
          seconds: Number(seconds) || 0,
          integration_type,
          primary_property,
          secondary_property,
          tags: JSON.stringify({ ...eventTags }),
        };
      });
      setEventColumns(eventsToColumns(formattedEvents));
      setEventData(eventsToRows(formattedEvents));
    } else {
      setEventColumns([]);
      setEventData([]);
    }
  }, [bpIncidentEvents, selectedIncidents, eventsToColumns]);

  const handlePublish = useCallback(
    (message, options) => {
      enqueueSnackbar(message, options);
    },
    [enqueueSnackbar]
  );

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Scenario Lab retrieves incidents from a BigPanda Org to create a DemoSim scenario.

      To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Query BigPanda and select incidents",
        optional: false,
        description: `Use the query parameters here to filter incidents from BigPanda.

        The query results will be displayed in the Incident List below. 
        NOTE: No more than 500 incidents will be retrieved.

        None of the incidents will be selected by default; 
        you must select the incidents you want to use in your scenario.`,
        component: (
          <IncidentSearch
            selectedIncidents={selectedIncidents}
            setSelectedIncidents={setSelectedIncidents}
          />
        ),
        gate: selectedIncidents.length > 0,
      },
      {
        label: "Edit your scenario",
        optional: false,
        description: `Load incident events (using the button below)
        then use the scenario editor below to edit your scenario.

      NOTE: Changing the incident query or selected incidents (previous step) will reset all the data and columns in this scenario editor.
      `,
        component: (
          <>
            <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
              <Button
                variant="contained"
                onClick={async () => {
                  setIsBpIncidentEventsLoading(true);
                  try {
                    const events = await getIncidentEvents();
                    setBpIncidentEvents(events);
                  } catch (error) {
                    logger.error("getIncidentEvents error:", error);
                  } finally {
                    setIsBpIncidentEventsLoading(false);
                  }
                }}
              >
                Load Incident Events
              </Button>
            </Stack>
            <Card sx={{ boxShadow: 2 }}>
              <CardHeader
                avatar={<Assignment color="primary" />}
                title="Events"
                titleTypographyProps={{ color: "primary", variant: "h4" }}
              />
              <CardContent>
                <DataGridEditor
                  setScenarioChanged={() => {}}
                  defaultPageSize={10}
                  gridData={eventData}
                  setGridData={setEventData}
                  gridColumns={eventColumns}
                  setGridColumns={setEventColumns}
                  isLoading={isBpIncidentEventsLoading}
                />
              </CardContent>
            </Card>
          </>
        ),
        gate: eventData.length > 0,
      },
      {
        label: "Publish your scenario",
        optional: false,
        description: `Enter the name and details of your scenario, 
      then click the Publish button to add your scenario to this Demo Config.
      
      From there, you can further edit and run your scenario in the Demo Admin Scenario tab.`,
        component: (
          <Stack direction="row" spacing={2} sx={{ m: 2, p: 1 }}>
            <ScenarioCreator
              menuCategories={menuCategories}
              newEvents={eventData}
              enqueueSnackbar={handlePublish}
            />
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    demoConfig.bporgname,
    eventColumns,
    eventData,
    isBpIncidentEventsLoading,
    selectedIncidents,
    getIncidentEvents,
    handlePublish,
  ]);

  return (
    <Paper className="RouteContainer">
      <Typography sx={{ mb: 1 }} variant="h3">
        Scenario Lab
      </Typography>
      <HorizontalLinearStepper steps={steps} />
    </Paper>
  );
}
