import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// MUI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import IntegrationEditor from "components/IntegrationEditor/IntegrationEditor";
import useAppState from "store/appState";
import AddIntegrationTypeDialog from "components/AddIntegrationTypeDialog/AddIntegrationTypeDialog";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useDemoConfig } from "store/serverState";

const OrgIntegrationsTab = () => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { currentDemoConfigId } =    useAppState();
  const {
    demoConfig,
    bpOrgInfo,
  } = useDemoConfig(currentDemoConfigId);

  return demoConfig?.integrations ? (
    <div>
      <LoadingSpinner />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={0} lg={3} />
          <Grid
            item
            xs={12}
            lg={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5">Integration Type Mapping</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography mb={2} variant="caption">
                {`Assign each integration type to one of ${bpOrgInfo.organization.name}'s integrations`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            marginBottom={2}
            xs={12}
            lg={3}
            container
            justifyContent={() => (mobileScreen ? "center" : "flex-end")}
            alignItems="center"
          >
            <AddIntegrationTypeDialog />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
        >
          {demoConfig.integrations.map((integration) => (
            <Grid
              key={`${integration.integration_type}`}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              <IntegrationEditor integration={integration} integration_type={integration.integration_type}/>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  ) : (
    <div>
      <LoadingSpinner />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          lg={6}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5">Integration Type Mapping</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Typography mb={2} variant="caption">
              Assign each source type to one of your Org's integrations
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Alert
            data-test="alert-no-integrations"
            sx={{ m: 2 }}
            elevation={6}
            variant="filled"
            severity="info"
          >
            Looking for inbound and change integrations in{" "}
            {demoConfig.bporgname}
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrgIntegrationsTab;
