/* 
  This page enables the user to load a CSV file of alerts, 
  map the original schema to a BigPanda Alerts schema, 
  edit the data and schema in a Material React Table (MRT) DataGrid component,
  and then post the alerts to a specified webhook or a BigPanda org integration.
  It also enables the user to export the final (presumably acceptable) alerts from the DataGrid to a CSV or Excel file.
  It also enables the user to download a CLI tool that can be used to post alerts into a running BigPanda org.
  It also enables the user to download a CLI tool that can be used to post alerts to a specified webhook.

Downloads these files:
bpinjector-linux-x64
bpinjector-macos-arm64
bpinjector-macos-x64
bpinjector-win-x64.exe
bpWebhookPoster-win-x64.exe
bpWebhookPoster-linux-x64
bpWebhookPoster-macos-arm64
bpWebhookPoster-macos-x64

Structure of the page is as follows:
  It uses a WizardStepper component to display the steps.
  It also uses a MRT DataGrid to display the data and enable editing.
  Thirdly, if there are CLI versions of the tool available, it displays a CliDownloads component.

  Steps are defined in the steps array. Each step has the following properties:
  label: the text to display in the step header
  optional: whether the step is optional
  description: the text to display in the step body
  component: the component to display in the step body
  gate: a boolean that determines whether the step is enabled
 */

import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";
import { parse } from "csv-parse/browser/esm/sync";
import { Logger } from "aws-amplify";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import ReactJson from "@microlink/react-json-view";

// MUI components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

// Custom components
import CliDownloads from "./CliDownloads";
import OrgSelector from "../../../components/OrgSelector/OrgSelector.js";
import InputFileOpen from "../../../components/Inputs/InputFileOpen";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalLinearStepper";
import MrtExportMenu from "../../../components/Tables/MrtExportMenu";
import ControlledRadioButtonsGroup from "../../../components/OptionSelectors/ControlledRadioButtonsGroup";

// Custom hooks and functions
import useAppState from "../../../store/appState";
import { useDemoConfig } from "../../../store/serverState";
import { useSnackbar } from "notistack";
import {
  fixTimestamp,
  tableToHeadersColumns,
  isValidJSON,
} from "../../../lib/transform_funcs";
import Bottleneck from "bottleneck";
import { postUtil } from "../../../store/graphql-functions.js";

const logger = new Logger("SETools/BpAlertLoader", "INFO");

type GenericAlertRecord = {
  app_key?: string;
  status?: string;
  timestamp?: number;
  description?: string;
  incident_identifier?: string;
  primary_property?: string;
  secondary_property?: string;
  [key: string]: string | number | undefined;
};

export default function BpAlertLoader() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgIntegrations } = useDemoConfig(currentDemoConfigId);
  const { enqueueSnackbar } = useSnackbar();
  // const noticeAction = useNoticeAction();
  const [postLog, setPostLog] = useState<string[]>([]);
  // const { postChanges, postAlerts } = useScenarios();

  const integrationOptions = useMemo(
    () =>
      bpOrgIntegrations
        .filter((i) => i.parent_source_system !== "changes")
        .map((i) => {
          // set integration url based on the parent_source_system
          return {
            name: i.name,
            stream_id: i.stream_id,
          };
        }),
    [bpOrgIntegrations]
  );

  const storedEndpointUrl = localStorage.getItem("endpointUrl");
  // local state
  const [throttle_rate, setThrottleRate] = useState<number>(10);
  const [alertDataRows, setAlertDataRows] = useState<GenericAlertRecord[]>([]);
  const [alertDataObjects, setAlertDataObjects] = useState<any[]>([]);
  const [alertDataKeys, setAlertDataKeys] = useState<string[]>([]);
  const [endpointType, setEndpointType] = useState<string>("webhook");
  const [endpointUrl, setEndpointUrl] = useState<string>(
    storedEndpointUrl || ""
  );
  const [integrationDest, setIntegrationDest] = useState(null);
  const [integrationDestInput, setIntegrationDestInput] = useState("");
  const [posting, setPosting] = useState<boolean>(false);
  const alertDataColumns = useMemo<MRT_ColumnDef<GenericAlertRecord>[]>(
    () =>
      alertDataKeys.map((key) => ({
        accessorKey: key,
        header: key,
      })),
    [alertDataKeys]
  );

  // BigPanda Alert schema attribute mapping state
  const [statusColumn, setStatusColumn] = useState<string>(null);
  const [timestampColumn, setTimestampColumn] = useState<string>(null);
  const [descriptionColumn, setDescriptionColumn] = useState<string>(null);
  const [primaryPropertyColumn, setPrimaryPropertyColumn] =
    useState<string>(null);
  const [secondaryPropertyColumn, setSecondaryPropertyColumn] =
    useState<string>(null);
  const [incidentIdentifierColumn, setIncidentIdentifierColumn] =
    useState<string>(null);

  // Validation states
  const [statusValuesValidated, setStatusValuesValidated] =
    useState<boolean>(false);
  const [timestampValuesValidated, setTimestampValuesValidated] =
    useState<boolean>(false);

  function validateStatusValues() {
    if (statusColumn === null || alertDataRows.length === 0) return;
    setStatusValuesValidated(
      alertDataRows.every((row) => {
        return (
          row[statusColumn] === "ok" ||
          row[statusColumn] === "critical" ||
          row[statusColumn] === "warning" ||
          row[statusColumn] === "unkown" ||
          row[statusColumn] === "acknowledged"
        );
      })
    );
  }

  useEffect(validateStatusValues, [alertDataRows, statusColumn]);

  function validateTimestamps() {
    if (timestampColumn === null || alertDataRows.length === 0) return;
    // unix epoch of now minus one year
    let oneYearAgo = Math.floor(Date.now() / 1000) - 31536000;
    setTimestampValuesValidated(
      alertDataRows.every((row) => {
        let ts = row[timestampColumn];
        return fixTimestamp(ts) > oneYearAgo;
      })
    );
  }

  useEffect(validateTimestamps, [alertDataRows, timestampColumn]);

  const table = useMaterialReactTable({
    columns: alertDataColumns,
    data: alertDataRows,
    enableFullScreenToggle: false,
    muiTablePaperProps: {
      elevation: 1, //change the mui box shadow
      //customize paper styles
      sx: {
        padding: 1,
        width: "100%",
        maxWidth: 1500,
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <MrtExportMenu table={table} />
    ),
  });

  // log the current state of the grid every time sourceFileRows or sourceFileColumns changes
  useEffect(() => {
    logger.info("alertDataColumns:", alertDataColumns);
    logger.info("alertDataRows:", alertDataRows);
    logger.info("bpOrgIntegrations:", bpOrgIntegrations);
    logger.info("integrationDest:", integrationDest);
    logger.info("integrationOptions:", integrationOptions);
  }, [
    alertDataColumns,
    alertDataRows,
    bpOrgIntegrations,
    integrationDest,
    integrationOptions,
  ]);

  const handleCsvFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setAlertDataObjects([]);

    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        if (readerEvent?.target?.result) {
          const csvResults = parse(readerEvent?.target?.result.toString(), {
            // columns: true,
            skip_empty_lines: true,
            trim: true,
            skip_records_with_empty_values: true,
            relax_column_count: true,
            cast: (value, context) => value.replace(/[\r\n]/g, " "),
          });
          let { headers, rows } = tableToHeadersColumns(csvResults);

          setAlertDataRows(rows);
          setAlertDataKeys(headers);
        }
      };
    }
    e.target.value = null;
  };

  const handleJsonFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setAlertDataKeys([]);
      setAlertDataRows([]);

      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.readAsText(e.target.files[0], "UTF-8");
        reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
          if (
            readerEvent?.target?.result &&
            isValidJSON(readerEvent?.target?.result.toString())
          ) {
            logger.info("result", readerEvent?.target?.result.toString());
            const jsonResults = JSON.parse(
              readerEvent?.target?.result.toString()
            );
            Array.isArray(jsonResults)
              ? setAlertDataObjects(jsonResults)
              : setAlertDataObjects([jsonResults]);
          } else {
            enqueueSnackbar("Invalid JSON file", { variant: "error" });
          }
        };
      }
      e.target.value = null;
    },
    [enqueueSnackbar]
  );

  // dynamically clear out the identifier, status, description, timestamp, end, and ticket_url columns if they're not in the headers
  useEffect(() => {
    if (
      timestampColumn &&
      !alertDataColumns.some((column) => column.accessorKey === timestampColumn)
    ) {
      setTimestampColumn(null);
    }
  }, [
    alertDataColumns,
    incidentIdentifierColumn,
    statusColumn,
    descriptionColumn,
    timestampColumn,
  ]);

  const handlePost = useCallback(async () => {
    // Setup throttling handler
    const limiter = new Bottleneck({
      // reservoir: 3, // initial value
      // reservoirRefreshAmount: 3,
      // reservoirRefreshInterval: 1000, // must be divisible by 250
      minTime: Math.round(1000 / throttle_rate),
      maxConcurrent: throttle_rate,
    });
    limiter.on("empty", function () {
      // This will be called when `limiter.empty()` becomes true.
      setPosting(false);
    });
    limiter.on("failed", async (error, jobInfo: any) => {
      logger.error(`Failed to post record ${jobInfo.options.id}`, error);
      if (error.code === "ECONNABORTED") {
        logger.warn(`Throttle error posting record ${jobInfo.options.id}`);
        setPostLog((loghistory) => [
          `${new Date().toLocaleTimeString()}\t\tRecord ${
            jobInfo.options.id
          }: ${error.message} (${error.code}), Retrying after 1 second\n`,
          ...loghistory,
        ]);

        return 1000; // wait 200ms before retrying
      } else {
        setPostLog((loghistory) => [
          `${new Date().toLocaleTimeString()}\tFailed to post record ${
            jobInfo.options.id
          }. Error: ${error.message} (${error.code})\n`,
          ...loghistory,
        ]);
      }
    });
    limiter.on("done", () => {
      setPosting(false);
    });

    setPosting(true);
    logger.info("Posting");

    // removed  rows, rowsById
    let { flatRows } = table.getFilteredRowModel();
    let filteredAlertDataRows = flatRows.map((row) => row.original);

    let alertsToPost =
      alertDataObjects.length > 0 ? alertDataObjects : filteredAlertDataRows;
    logger.info("alertsToPost:", alertsToPost);
    enqueueSnackbar(
      "Posting alerts. Please wait for confirmation popup before leaving.",
      { variant: "info" }
    );
    alertsToPost.forEach((alert, idx) => {
      let identifier = alert.incident_identifier || `${idx + 1}`;
      limiter
        .schedule({ id: identifier }, postUtil, {
          access_token: demoConfig.api_key,
          region: demoConfig.region,
          params: {
            action: "postGeneric",
            headers: {},
            rawUrl:
              endpointType === "bigpanda" ? integrationDest.url : endpointUrl,
          },
          payload: alert,
        })
        .then((result: any) => {
          logger.info(
            `Post Result: ${JSON.stringify(result)} for alert id ${identifier}`
          );
          setPostLog((loghistory) => [
            `${new Date().toLocaleTimeString()}\tAlert ${identifier}: Posted\n`,
            ...loghistory,
          ]);
        })
        .catch((error: Error) => {
          if (error instanceof Bottleneck.BottleneckError) {
            logger.error(`Scheduling error`, error);
            setPostLog((loghistory) => [
              `${new Date().toLocaleTimeString()}\t\tScheduling error: ${JSON.stringify(
                error
              )}\n`,
              ...loghistory,
            ]);
          } else {
            logger.error(error);
            setPostLog((loghistory) => [
              `${new Date().toLocaleTimeString()}\t\tError: ${JSON.stringify(
                error
              )}\n`,
              ...loghistory,
            ]);
          }
        });
    });
  }, [
    endpointUrl,
    throttle_rate,
    demoConfig.api_key,
    demoConfig.region,
    table,
    alertDataObjects,
    endpointType,
    // integrationDest?.url,
    enqueueSnackbar,
  ]);

  const handleChangeEndpointType = (value) => {
    setEndpointType(value);
  };

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Alerts Loader enables you to:
      - load alerts from a CSV or JSON file
      - map the original schema to a destination schema
      - edit the data and schema in a DataGrid
      - and then post the alerts to a BigPanda integration or a custom webhook.

      To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Set Endpoint",
        optional: false,
        description: `Set the destination endpoint for the alerts.
        Enter the URL of the webhook.`,
        //         If you're posting directly to your BigPanda org, select the integration target from the list.
        // present components, one selector to determine if posting to BigPanda or a webhook, a selector to choose an integration, and a text field to enter the webhook URL
        component: (
          <Stack direction="column" spacing={2} sx={{ m: 2 }}>
            <ControlledRadioButtonsGroup
              name="Endpoint Type"
              options={[
                // { value: "bigpanda", label: "BigPanda" },
                { value: "webhook", label: "Webhook" },
              ]}
              value={endpointType}
              setValue={handleChangeEndpointType}
            />
            {endpointType === "bigpanda" && (
              <Autocomplete
                sx={{ width: "50%" }}
                openOnFocus
                blurOnSelect
                size="small"
                options={integrationOptions}
                getOptionLabel={(option) =>
                  `${option.name} (***${option?.stream_id?.slice(-6)})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    variant="outlined"
                    placeholder="Select Integration Target"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.stream_id === value.stream_id
                }
                value={integrationDest}
                onChange={(event: any, newValue: string | null) => {
                  setIntegrationDest(newValue);
                }}
                inputValue={integrationDestInput}
                onInputChange={(event, newInputValue) => {
                  setIntegrationDestInput(newInputValue);
                }}
              />
            )}
            {endpointType === "webhook" && (
              <TextField
                sx={{ width: "50%" }}
                size="medium"
                variant="outlined"
                label="Webhook URL"
                type="url"
                error={
                  Boolean(endpointUrl) && !/https?:\/\/.+\..+/.test(endpointUrl)
                }
                helperText="Must be a valid URL starting with http:// or https://"
                placeholder="https://example.com/webhook"
                value={endpointUrl}
                onChange={(e) => {
                  localStorage.setItem("endpointUrl", e.target.value);
                  setEndpointUrl(e.target.value);
                }}
              />
            )}
          </Stack>
        ),
        gate: integrationDest || endpointUrl,
      },
      {
        label: "Load Alert Data",
        optional: false,
        description: `Load a file containing your alert data.  If you're posting to a BigPanda integration then the file must be in CSV format.
        If you're posting to a webhook, you may also load either a CSV or JSON file.

        Note: CSV files will be displayed in a table, whereas JSON files will be displayed in an expandable JSON viewer.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <InputFileOpen
              type="file"
              text="Load CSV file"
              accept=".csv"
              onChange={handleCsvFileUpload}
            />
            {endpointType === "webhook" && (
              <InputFileOpen
                type="file"
                text="Load JSON file"
                accept=".json"
                onChange={handleJsonFileUpload}
              />
            )}
          </Stack>
        ),
        gate:
          Boolean(alertDataRows.length > 0) ||
          Boolean(alertDataObjects.length > 0),
      },
      // {
      //   label: "Map Schema",
      //   optional: false,
      //   description: `Assign columns to required attributes in the BigPanda alert schema.`,
      //   component: (
      //     <Stack direction="column" spacing={2} margin={2}>
      //       <SelectColumn
      //         label="status"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={statusColumn}
      //         setSelectedColumn={setStatusColumn}
      //         required={true}
      //       />
      //       <SelectColumn
      //         label="timestamp"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={timestampColumn}
      //         setSelectedColumn={setTimestampColumn}
      //         required={false}
      //       />
      //       <SelectColumn
      //         label="description"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={descriptionColumn}
      //         setSelectedColumn={setDescriptionColumn}
      //         required={false}
      //       />
      //       <SelectColumn
      //         label="primary_property"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={primaryPropertyColumn}
      //         setSelectedColumn={setPrimaryPropertyColumn}
      //         required={false}
      //       />
      //       <SelectColumn
      //         label="secondary_property"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={secondaryPropertyColumn}
      //         setSelectedColumn={setSecondaryPropertyColumn}
      //         required={false}
      //       />
      //       <SelectColumn
      //         label="incident_identifier"
      //         availableColumns={alertDataColumns}
      //         selectedColumn={incidentIdentifierColumn}
      //         setSelectedColumn={setIncidentIdentifierColumn}
      //         required={false}
      //       />
      //     </Stack>
      //   ),
      //   gate: statusColumn != null,
      // },
      // {
      //   label: "Edit Data",
      //   optional: true,
      //   description: `Use these utilities to quickly "fix" data.`,
      //   component: (
      //     <Stack direction="column" spacing={2} margin={2}>
      //       {!timestampValuesValidated && (
      //         <Alert severity="error">
      //           All timestamp values must be valid *NIX epoch timestamps or
      //           Date/Time strings. Cannot proceed. Repair in original data file
      //           and reload.
      //         </Alert>
      //       )}

      //       <Stack direction="row" spacing={2}>
      //         <Button
      //           variant="outlined"
      //           color={timestampValuesValidated ? "primary" : "error"}
      //           disabled={timestampColumn == null}
      //           sx={{
      //             margin: 2,
      //             width: "fit-content",
      //             height: "fit-content",
      //           }}
      //           onClick={() => handleOffsets("end")}
      //         >
      //           OFFSET
      //         </Button>
      //         <Typography sx={{ whiteSpace: "pre-line" }}>
      //           Offset timestamps so that the dataset "ends" now. E.G. if the
      //           original data spans 3 days, the offset data will start 3 days
      //           ago and end now.
      //         </Typography>
      //       </Stack>
      //     </Stack>
      //   ),
      //   gate: timestampValuesValidated,
      // },
      {
        label: "Post",
        optional: false,
        description: `Click the POST button to post the alert records.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <Button
              variant="outlined"
              sx={{
                margin: 2,
                width: "fit-content",
                height: "fit-content",
              }}
              onClick={handlePost}
              disabled={
                endpointType === "bigpanda" ? !integrationDest : !endpointUrl
              }
            >
              Post Alerts
            </Button>
            <Alert severity="info">
              HINT: Use the table filters to limit which columns/fields/tags are
              posted
            </Alert>
            {posting && (
              <Stack direction="column" spacing={2} margin={2}>
                <Alert severity="info">Posting to BigPanda</Alert>
                <LinearProgress />
              </Stack>
            )}
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    alertDataRows,
    alertDataObjects,
    demoConfig.bporgname,
    integrationOptions,
    integrationDest,
    integrationDestInput,
    posting,
    endpointType,
    endpointUrl,
    handlePost,
    setEndpointUrl,
    handleJsonFileUpload,
  ]);

  return (
    <Grid container>
      <Grid container item direction="column" paddingRight={8} xs={12} md={10}>
        <Typography variant="h3" color="red">
          BETA
        </Typography>
        <HorizontalLinearStepper steps={steps} />
        <TextField
          sx={{ mt: 2 }}
          id="post-log"
          label="Post Log"
          multiline
          maxRows={10}
          // variant="filled"
          value={postLog.join("")}
          disabled
        />
        <Divider sx={{ margin: 2 }} />
        {alertDataObjects.length > 0 && <ReactJson src={alertDataObjects} />}
        {alertDataRows.length > 0 && <MaterialReactTable table={table} />}
      </Grid>
      <Grid item xs={12} md={2}>
        <CliDownloads
          fileNames={[
            "bpinjector-linux-x64",
            "bpinjector-macos-arm64",
            "bpinjector-macos-x64",
            "bpinjector-win-x64.exe",
            "bpWebhookPoster-win-x64.exe",
            "bpWebhookPoster-linux-x64",
            "bpWebhookPoster-macos-arm64",
            "bpWebhookPoster-macos-x64",
          ]}
        />
      </Grid>
    </Grid>
  );
}
