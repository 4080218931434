import { ReactNode, SyntheticEvent, useState } from "react";
// import { Logger } from "aws-amplify";

// MUI components
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom components
import BpChangeLoader from "./ToolTabPanels/BpChangeLoader.tsx";
import BpClearIncidents from "./ToolTabPanels/BpClearIncidents.tsx";
import BpAlertLoader from "./ToolTabPanels/BpAlertLoader.tsx";
import BpJson2Csv from "./ToolTabPanels/BpJson2Csv.tsx";
import BpUserTools from "./ToolTabPanels/BpUserTools.tsx";
import BpOrgTags from "./ToolTabPanels/BpOrgTags.tsx";
// Custom hooks and functions

// const logger = new Logger("ScenarioLab", "INFO");

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export default function SeTools() {
  // Global state

  // Local state
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  //TODO Reset passwords
  return (
    <Paper id="SE Tools Page" className="RouteContainer">
      <AppBar position="static" color="transparent" sx={{ mb: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs example"
        >
          <Tab label="Change Loader" {...a11yProps(0)} />
          <Tab label="Alert Loader" {...a11yProps(1)} />
          <Tab label="Clear Incidents" {...a11yProps(2)} />
          <Tab label="JSON<>CSV" {...a11yProps(3)} />
          <Tab label="Users" {...a11yProps(4)} />
          <Tab label="Org Tags" {...a11yProps(5)} />
          {/* <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <BpChangeLoader />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BpAlertLoader />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BpClearIncidents />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BpJson2Csv />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BpUserTools />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <BpOrgTags />
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </Paper>
  );
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
