import React from "react";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

type Props = {
  href: string;
  filename: string;
};

export default function ButtonDownload(props: Props) {
  const { href, filename } = props;

  return (
    <Button
      variant="contained"
      color="info"
      startIcon={<CloudDownloadIcon />}
      href={href}
      download
      sx={{ textTransform: "none", justifyContent: "flex-start" }}
    >
      {filename}
    </Button>
  );
}
