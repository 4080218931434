/* 
Downloads these files:
bpJson2Csv-linux
bpJson2Csv-macos
bpJson2Csv-win.exe
*/

// MUI components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

// Custom components
import CliDownloads from "./CliDownloads";

export default function BpAlertLoader() {
  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Paper
          sx={{
            borderRadius: 2,
            padding: 2,
            margin: 2,
            height: "100%",
          }}
          elevation={1}
        >
          <Typography variant="h5">
            Convert JSON files to CSV format and vice versa
          </Typography>
          <Divider sx={{ margin: 2 }} />

          <Typography variant="h6">
            This space reserved for future functionality
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={2}>
        <CliDownloads
          fileNames={[
            "bpJson2Csv-linux",
            "bpJson2Csv-macos",
            "bpJson2Csv-win.exe",
          ]}
        />
      </Grid>
    </Grid>
  );
}
