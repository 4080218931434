import { useCallback, useMemo } from "react";
import useAppState from 'store/appState'
import {  useDemoConfig } from "store/serverState";

export default function useParamCache() {
  const paramCache = useMemo(() => new Map(), []);
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  const genValue = useCallback(
    function genValue(kind) {
      const alpha = "abcdefhijklmnopqrstuvwxyz",
        hex = "0123456789abcef",
        digits = "0123456789";
      let val;

      // If the org has a param of this type then return a random sampling of it's values
      let matchingParam = demoConfig.params.filter(
        (p) => p.name === kind
      );
      if (matchingParam.length > 0) {
        return sample(matchingParam[0].values);
      }

      // otherwise, interpret kind as a character class
      // this means that an unknown variable kind will be interpreted as a series of alphanumeric characters
      switch (kind) {
        case "ip_octet":
          val = Math.floor(Math.random() * 256).toString();
          break;
        case "aws_id":
          val = "i-" + crypto.randomUUID().replace(/-/g, '',).substring(0, 17)
          break;
        default:
          val = "";
          for (let char of kind) {
            if (char === "a") val += sample(alpha);
            else if (char === "d") val += sample(digits);
            else if (char === "h") val += sample(hex);
            else val += sample(`${alpha}${digits}_`);
          }
      }
      return val;
    },
    [demoConfig.params]
  );

  const getRnd = useCallback(
    function getRnd(variable) {
      let val = "";
      let match = variable.match(/\$\{rnd\.(.*?)\}/)[1];
      let params = match.split(".");
      let seed = params[1] ? params[1] : null;
      let kind = params[0] ? params[0] : null;
      let key = "rnd." + kind + "." + seed;

      if (!Boolean(seed)) {
        val = genValue(kind);
      } else if (paramCache.has(key)) {
        val = paramCache.get(key);
      } else {
        val = genValue(kind);
        paramCache.set(key, val);
      }
      return val;
    },
    [genValue, paramCache]
  );

  function sample(arr) {
    const len = arr == null ? 0 : arr.length;
    return len ? arr[Math.floor(Math.random() * len)] : undefined;
  }


  const renderParams = useCallback((tagVal) => {
    let newTagVal = tagVal
    let variables = newTagVal.match(/\$\{rnd\.(.*?)\}/g);
    for (let variable of variables) {
      let newVal = getRnd(variable);
      newTagVal = newTagVal.replace(variable, newVal);
    }
    return newTagVal;
  }, [getRnd]);

  const hasVariables = useCallback((tagVal) => /\$\{rnd\.(.*?)\}/.test(tagVal), [])

  const setScenarioTimes = useCallback((offsets) => {
    // Define a baseline timestamp (end) to represent "now"; i.e. when this function started
    // If both neg and positive offsets, calculate relative to total time:
    // - Set a start time for the entire scenario, based on earliest event start
    let scenarioStart = Math.round(new Date().getTime() / 1000);
    let scenarioEnd = Math.round(new Date().getTime() / 1000);
    let scenarioMidpoint, scenarioTimeRange;

    if (offsets.length === 0)
      return { scenarioStart, scenarioMidpoint, scenarioEnd };

    let minEventOffset = Math.min(...offsets);
    let maxEventOffset = Math.max(...offsets);
    if (minEventOffset < 0 && maxEventOffset >= 0) {
      scenarioTimeRange = Math.abs(minEventOffset) + Math.abs(maxEventOffset);
      scenarioMidpoint = scenarioEnd - maxEventOffset;
    } else {
      scenarioTimeRange = Math.max(...offsets.map(Math.abs));
    }
    scenarioStart = scenarioEnd - scenarioTimeRange;
    return { scenarioStart, scenarioMidpoint, scenarioEnd };
  }, []);

  const hydrateScenarioVariables = useCallback(({ eventData, changeData, orgIntegrations }) => {
    let inc_id_nonce = Math.random();
    let { scenarioStart, scenarioMidpoint, scenarioEnd } = setScenarioTimes(eventData.map((e) => e._offset))

    let newEventData = eventData.map((currentScenarioEvent, idx) => {
      let scenarioEvent = Object.assign({}, currentScenarioEvent)
      let {
        event_type,
        _offset,
        seconds,
        integration_type,
        primary_property,
        secondary_property,
        tags,
      } = scenarioEvent;

      // populate timestamp from _offset
      let timestamp = scenarioStart;
      if (typeof parseInt(_offset) == "number") {
        let offset = parseInt(_offset);
        if (scenarioMidpoint) {
          timestamp = scenarioMidpoint + offset;
        } else {
          if (offset < 0) timestamp = scenarioEnd + offset;
          if (offset >= 0) timestamp = scenarioStart + offset;
        }
      }

      // populate app_key from integration_type
      let app_key = "";
      if (integration_type) {
        app_key = orgIntegrations
          .filter((i) => i.integration_type === integration_type)
          .reduce((acc, integration) => {
            if (
              integration.hasOwnProperty("bp_integration") &&
              integration.bp_integration != null &&
              typeof integration.bp_integration == "object" &&
              integration.bp_integration.hasOwnProperty("stream_id")
            ) {
              return integration.bp_integration.stream_id;
            } else {
              return `${integration_type} UNASSIGNED!`;
            }
          }, "");
      }

      // populate variables in tag fields
      for (const tag in tags) {
        if (Object.hasOwnProperty.call(tags, tag)) {
          if (hasVariables(tags[tag])) {
            tags[tag] = renderParams(tags[tag]);
          }
          if (`${tags[tag]}`.indexOf("|") > -1) {
            tags[tag] = tags[tag].split("|");
          }
        }
      }

      let incident_identifier = event_type === "ALERT" ? `${tags[primary_property]}||${tags[secondary_property]}||${inc_id_nonce}` : undefined

      return {
        id: idx,
        event_type,
        timestamp,
        seconds,
        app_key,
        primary_property,
        secondary_property,
        tags,
        incident_identifier,
      };
    });

    let newChangeData = changeData.map((scenarioChange, idx) => {
      let {
        _offset,
        integration_type,
        identifier,
        status,
        summary,
        ticket_url,
        tags
      } = scenarioChange;

      let start = scenarioStart - Math.abs(parseInt(_offset));
      let end = scenarioEnd + Math.abs(parseInt(_offset));
      let app_key = "UNASSIGNED";
      if (integration_type) {
        app_key = orgIntegrations
          .filter((i) => i.integration_type === integration_type)
          .reduce((acc, integration) => {
            // logger.info(`working on integration:`, integration)
            if (
              integration.hasOwnProperty("bp_integration") &&
              integration.bp_integration != null &&
              typeof integration.bp_integration == "object" &&
              integration.bp_integration.hasOwnProperty("stream_id")
            ) {
              return integration.bp_integration.stream_id;
            } else {
              return `${integration_type} UNASSIGNED!`;
            }
          }, "");
      }

      let newScenarioChange = {
        identifier,
        start,
        end,
        status,
        summary,
        ticket_url,
        tags,
      };
      for (const tag in newScenarioChange) {
        if (Object.hasOwnProperty.call(newScenarioChange, tag)) {
          if (hasVariables(newScenarioChange[tag])) {
            newScenarioChange[tag] = renderParams(newScenarioChange[tag]);
          }
        }
      }
      for (const tag in newScenarioChange.tags) {
        if (Object.hasOwnProperty.call(newScenarioChange.tags, tag)) {
          if (hasVariables(newScenarioChange.tags[tag])) {
            newScenarioChange.tags[tag] = renderParams(newScenarioChange.tags[tag]);
          }
        }
      }

      return {
        id: idx,
        app_key,
        ...newScenarioChange,
      };
    });

    return { newEventData, newChangeData }
  }, [
    setScenarioTimes,
    hasVariables,
    renderParams
  ])

  /**
   * Exposed as an external function so that you can control whether the 
   * paramCache is persisted from the last scenario or cleared first.
   * 
   */
  const paramCacheClear = useCallback(() => {
    paramCache.clear()
  }, [paramCache])

  return {
    paramCacheClear,
    hydrateScenarioVariables
  }
}