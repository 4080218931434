import React, { useState, useEffect } from "react";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// custom components
import { useSnackbar } from "notistack";
import isJSON from "is-json";
import useScenarios from "hooks/scenarioFunctions";
import useParamCache from "hooks/paramCache";
import useAppState from "store/appState";
import { useDemoConfig } from "store/serverState";
import useNoticeAction from "hooks/noticeAction";

import {
  DataGridPro,
  useGridApiRef,
  // useGridApiContext,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  LicenseInfo,
} from "@mui/x-data-grid-pro";
import { Logger } from "aws-amplify";
const logger = new Logger("ScenarioTestModal", "INFO");

LicenseInfo.setLicenseKey(
  "2e0178950f56aab5a472ecae03c40653Tz04MzkzNCxFPTE3MzkwNTYzMDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const ScenarioTest = ({ scenarioName, eventData, changeData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { paramCacheClear, hydrateScenarioVariables } = useParamCache();
  const { postAlerts, postITAGS, postChanges } = useScenarios();
  const eventGridApiRef = useGridApiRef();
  const changeGridApiRef = useGridApiRef();
  const [pageSize, setPageSize] = React.useState(5);
  const [refreshTagValues, setRefreshTagValues] = useState(false);

  // Modal state
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setRefreshTagValues(true);
  };
  const handleClose = () => {
    paramCacheClear();
    setRefreshTagValues(false);
    setOpen(false);
  };

  const noticeAction = useNoticeAction();

  const [postToBigPanda, setPostToBigPanda] = useState(false);
  async function handlePostScenarioClick() {
    setPostToBigPanda(true);
  }

  function refreshVariables() {
    setRefreshTagValues(true);
  }

  const eventsToColumns = (events) =>
    events.reduce(
      (headers, event) => {
        let accessors = headers.map((h) => h.field);
        let tags =
          event.hasOwnProperty("tags") && Boolean(event.tags)
            ? Object.keys(event.tags)
            : [];
        let newTags = tags.filter((t) => !accessors.includes(`tag_${t}`));
        newTags.forEach((t) =>
          headers.push({
            headerName: t,
            field: `tag_${t}`,
            flex: 1,
            minWidth: 150,
            editable: false,
            valueGetter: (params) => {
              return params.row.tags
                ? params.row.tags[`${t}`]
                : params.row[`${t}`];
            },
            valueSetter: (params) => {
              return { ...params.row };
            },
          })
        );
        return [...headers];
      },
      [
        {
          field: "event_type",
          description: "ALERT, ITAG, or PAUSE",
          width: 120,
          editable: false,
        },
        {
          field: "timestamp",
          type: "number",
          description:
            'UNIX timestamp. Calculated from offset as seconds from scenario start. Scenario starts at "now" minus the highest scenario offset',
          width: 120,
          editable: false,
          valueFormatter: (params) => new Date(params.value * 1000),
        },
        {
          field: "seconds",
          description: "Seconds of delay for a PAUSE event",
          width: 120,
          editable: false,
          type: "number",
          valueFormatter: (params) => (params.value !== 0 ? params.value : ""),
        },
        {
          field: "app_key",
          description:
            "The app key from the assigned integration type for this event (assigned in the integrations tab.)",
          width: 180,
          editable: false,
        },
        {
          field: "primary_property",
          description: "The primary property for this event.",
          width: 190,
          editable: false,
        },
        {
          field: "secondary_property",
          description: "The secondary property for this event.",
          width: 210,
          editable: false,
        },
      ]
    );
  const [workingEventColumns, setWorkingEventColumns] = useState(
    eventsToColumns(eventData)
  );

  const changesToColumns = (changes) =>
    changes.reduce(
      (headers, change) => {
        let accessors = headers.map((h) => h.field);
        let tags =
          change.hasOwnProperty("tags") && Boolean(change.tags)
            ? Object.keys(change.tags)
            : [];
        let newTags = tags.filter((t) => !accessors.includes(`tag_${t}`));
        newTags.forEach((t) =>
          headers.push({
            headerName: t,
            field: `tag_${t}`,
            flex: 1,
            minWidth: 150,
            editable: false,
            valueGetter: (params) => {
              return params.row.tags
                ? params.row.tags[`${t}`]
                : params.row[`${t}`];
            },
            valueSetter: (params) => {
              return { ...params.row };
            },
          })
        );
        return [...headers];
      },
      [
        {
          field: "app_key",
          description:
            "The integration type for this change. Assign this type to an Org change integration in the integrations tab.",
          width: 180,
          editable: false,
        },
        {
          field: "identifier",
          description: "The pseudo change identifier for this change.",
          width: 190,
          editable: false,
        },
        {
          field: "start",
          type: "number",
          description: "Time the changes should/did start.",
          width: 120,
          editable: false,
          valueFormatter: (params) => new Date(params.value * 1000),
        },
        {
          field: "end",
          type: "number",
          description: "Time the change should/did end.",
          width: 120,
          editable: false,
          valueFormatter: (params) => new Date(params.value * 1000),
        },
        {
          field: "status",
          description: "The status of this change.",
          width: 210,
          editable: false,
        },
        {
          field: "summary",
          description: "The summary of this change.",
          width: 210,
          editable: false,
        },
        {
          field: "ticket_url",
          description: "A pseudo ticket url for this change.",
          width: 210,
          editable: false,
        },
      ]
    );
  const [workingChangeColumns, setWorkingChangeColumns] = useState(
    changesToColumns(changeData)
  );

  const [workingEventData, setWorkingEventData] = useState([]);
  const [workingChangeData, setWorkingChangeData] = useState([]);
  const [hasInvalidAppkey, setHasInvalidAppkey] = useState(false);

  // replace the variables with param values
  useEffect(() => {
    if (refreshTagValues) {
      paramCacheClear();
      setWorkingEventColumns(eventsToColumns(eventData));
      setWorkingChangeColumns(changesToColumns(changeData));
      let { newEventData, newChangeData } = hydrateScenarioVariables({
        eventData: JSON.parse(JSON.stringify(eventData)),
        changeData: JSON.parse(JSON.stringify(changeData)),
        orgIntegrations: demoConfig.integrations,
      });
      logger.info("Hydrated event data:", newEventData);
      logger.info("Hydrated change data:", newChangeData);
      let invalidEventAppkeys = newEventData.filter((e) =>
        e.app_key.includes("UNASSIGNED")
      );
      let invalidChangeAppkeys = newEventData.filter((e) =>
        e.app_key.includes("UNASSIGNED")
      );
      if (invalidEventAppkeys.length > 0 || invalidChangeAppkeys.length > 0)
        setHasInvalidAppkey(true);
      setWorkingEventData(newEventData);
      setWorkingChangeData(newChangeData);
      setRefreshTagValues(false);
    }
  }, [
    paramCacheClear,
    eventData,
    changeData,
    demoConfig.integrations,
    setWorkingEventColumns,
    setWorkingChangeColumns,
    setWorkingEventData,
    setWorkingChangeData,
    hydrateScenarioVariables,
    refreshTagValues,
  ]);

  useEffect(() => {
    if (postToBigPanda) {
      if (Array.isArray(workingEventData) && workingEventData.length > 0) {
        let eventsToPost = workingEventData
          .filter((event) => event.event_type === "ALERT")
          .map((event) => {
            let { tags, id, ...rest } = event;
            return {
              ...rest,
              ...tags,
            };
          });

        let itagsToPost = workingEventData
          .filter((event) => event.event_type === "ITAG")
          .map((event) => {
            let { tags, id, ...rest } = event;
            return {
              ...rest,
              ...tags,
            };
          });

        postAlerts(eventsToPost)
          .then((res) => {
            logger.info("postAlerts response:", res.data.postAlerts);
            let allIncidentIdentifiers = [];
            let responses = isJSON(res.data.postAlerts)
              ? JSON.parse(res.data.postAlerts)
              : res.data.postAlerts;
            if (Array.isArray(responses)) {
              responses.forEach((result) => {
                if (result.status === "fulfilled") {
                  if (result.value?.status === "rejected") {
                    logger.error(`postAlerts: Failed payload:`, eventsToPost);
                    enqueueSnackbar(
                      `${result.value.response.status} (hint: locate ${result.value.incident_identifiers[0]} in console log.)`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  }
                  if (
                    result.value.status === "fulfilled" &&
                    result.value.incident_identifiers
                  ) {
                    result.value.incident_identifiers.forEach((i) =>
                      allIncidentIdentifiers.push(i)
                    );
                    let integration = demoConfig.integrations.find(
                      (integration) =>
                        integration.bp_integration.stream_id ===
                        result.value.app_key
                    );
                    enqueueSnackbar(
                      `Posted ${result.value.incident_identifiers.length} ${integration.integration_type} alerts`,
                      {
                        variant: "success",
                      }
                    );
                  }
                }
                if (result.status === "rejected") {
                  logger.error(
                    "postAlerts: Alert post failure reason:",
                    result.reason
                  );
                  logger.error(`postAlerts: Failed payload:`, eventsToPost);
                  enqueueSnackbar(
                    `${JSON.stringify(
                      result.reason
                    )} See Failed Payload in console log.`,
                    {
                      variant: "error",
                      persist: true,
                      action: noticeAction,
                    }
                  );
                }
              });
            } else if (responses?.statusCode && responses?.body) {
              logger.error(`handling converted response:`, responses);
              // postAlert function defaults to returning an object with statusCode and body
              enqueueSnackbar(
                `PostAlerts Error ${responses.statusCode}: ${responses.body}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            } else {
              logger.error(`Error processing postAlerts response:`, responses);
              enqueueSnackbar(
                `Error processing postAlerts response: ${responses}`,
                {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                }
              );
            }

            return allIncidentIdentifiers;
          })
          .then((allIncidentIdentifiers) => {
            if (itagsToPost.length > 0) {
              logger.info(
                "posting itags:",
                itagsToPost,
                allIncidentIdentifiers
              );
              postITAGS(itagsToPost, allIncidentIdentifiers)
                .then((res) => {
                  logger.info("postITAGS response:", res.data.postITAGS);
                  let responses = isJSON(res.data.postITAGS)
                    ? JSON.parse(res.data.postITAGS)
                    : res.data.postITAGS;
                  if (Array.isArray(responses)) {
                    responses.forEach((result) => {
                      if (result.status === "fulfilled") {
                        if (result.value.incidentId) {
                          enqueueSnackbar(
                            `Tagged incident ${result.value.incidentId}`,
                            {
                              variant: "success",
                            }
                          );
                        }
                      }
                      if (result.status === "rejected") {
                        logger.error(
                          "postITAGS: Incident Tag failure reason:",
                          result.reason
                        );
                        logger.error(`postITAGS: Failed payload:`, itagsToPost);
                        enqueueSnackbar(
                          `${JSON.stringify(
                            result.reason
                          )} See "postITAGS: Failed payload" in console log.`,
                          {
                            variant: "error",
                            persist: true,
                            action: noticeAction,
                          }
                        );
                      }
                    });
                  } else if (responses?.statusCode && responses?.body) {
                    logger.error(`handling converted response:`, responses);
                    // postAlert function defaults to returning an object with statusCode and body
                    enqueueSnackbar(
                      `postITAGS Error ${responses.statusCode}: ${responses.body}`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  } else {
                    logger.error(
                      `Error processing postITAGS response:`,
                      responses
                    );
                    enqueueSnackbar(
                      `Error processing postITAGS response: ${responses}`,
                      {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      }
                    );
                  }
                })
                .catch((err) => {
                  logger.error("postITAGS", err);
                  if (typeof err == "object" && err.errors)
                    err.errors.forEach((error) =>
                      enqueueSnackbar(error.message, {
                        variant: "error",
                        persist: true,
                        action: noticeAction,
                      })
                    );
                });
            }
          })
          .catch((err) => {
            logger.error("postAlerts", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
        //TODO ITAG: filter for ITAG events then post incident tags
      }

      if (Array.isArray(workingChangeData) && workingChangeData.length > 0) {
        let changesToPost = workingChangeData.map((change) => {
          let { tags, id, ...rest } = change;
          return {
            ...rest,
            ...tags,
          };
        });
        postChanges(changesToPost)
          .then((res) => {
            logger.info(
              "postChanges response:",
              JSON.parse(res.data.postChanges)
            );
            let responses = JSON.parse(res.data.postChanges);
            responses.forEach((result) => {
              if (result.status === "fulfilled")
                enqueueSnackbar(
                  `Change posted: ${result.value.change_identifier}`,
                  {
                    variant: "success",
                    preventDuplicate: true,
                  }
                );
              if (result.status === "rejected")
                enqueueSnackbar(
                  `Failed to post change: ${JSON.stringify(result.reason)}`,
                  {
                    variant: "error",
                    persist: true,
                    action: noticeAction,
                  }
                );
            });
          })
          .catch((err) => {
            logger.error("postChanges", err);
            if (typeof err == "object" && err.errors)
              err.errors.forEach((error) =>
                enqueueSnackbar(error.message, {
                  variant: "error",
                  persist: true,
                  action: noticeAction,
                })
              );
          });
      }
      setPostToBigPanda(false);
    }
  }, [
    postToBigPanda,
    postAlerts,
    postChanges,
    postITAGS,
    demoConfig,
    workingEventData,
    workingChangeData,
    enqueueSnackbar,
    noticeAction,
  ]);

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="medium"
        sx={{ height: "max-content" }}
        onClick={handleClickOpen}
      >
        Test Scenario
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll="paper"
        disableEnforceFocus
      >
        <DialogTitle>Scenario Tester</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This dialog will post your events and changes to BigPanda. Refresh
            to re-evaluate Org Variables.
          </DialogContentText>
          <Stack direction="column" spacing={2}>
            <Alert severity="info">
              PAUSE events will be ignored (future functionality for this
              tester...)
            </Alert>
            {hasInvalidAppkey && (
              <Alert severity="error">
                Scenario has invalid app keys and will fail to post
                events/changes to BigPanda correctly.
              </Alert>
            )}
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Button
                id="btn-org-change-submit"
                variant="contained"
                color="secondary"
                onClick={handlePostScenarioClick}
              >
                Post {scenarioName} events{" "}
                {workingChangeData.length > 0 ? "and changes " : null}to{" "}
                {demoConfig.name}
              </Button>
            </Stack>

            <Card>
              <CardHeader
                avatar={<Assignment color="primary" />}
                title="Events"
                titleTypographyProps={{ color: "primary", variant: "h5" }}
              />
              <CardContent
                sx={{
                  // boxShadow: 2,
                  // padding: 1,
                  // border: 1,
                  "& .MuiDataGrid-cell--editing": {
                    backgroundColor: "rgb(255,215,115, 0.19)",
                    color: "#1a3e72",
                    "& .MuiInputBase-root": {
                      height: "100%",
                    },
                  },
                  "& .Mui-error": {
                    backgroundColor: `rgb(126,10,15,0.1)`,
                    color: "error.main",
                  },
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .invalidType": {
                    backgroundColor: "mistyrose",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .missingTag": {
                    backgroundColor: "yellow",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .invalidValue": {
                    backgroundColor: "red",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .tag": {
                    fontFamily: "Monospace",
                  },
                  "& .meta": {
                    fontStyle: "oblique",
                  },
                }}
              >
                <DataGridPro
                  apiRef={eventGridApiRef}
                  autoHeight
                  disableColumnMenu
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: { refreshVariables },
                  }}
                  rows={workingEventData}
                  columns={workingEventColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSizeOptions={[5, 10, 50, 100, 1000]}
                  pagination
                  getCellClassName={(params) => {
                    if (
                      params.row?.event_type === "ALERT" &&
                      params?.field === "app_key"
                    ) {
                      if (params.value.includes("UNASSIGNED"))
                        return "invalidValue";
                    }
                    if (
                      params.row?.event_type === "ALERT" &&
                      params?.field === "integration_type"
                    ) {
                      if (!Boolean(params.value)) return "invalidValue";
                      let target_integration = demoConfig.integrations.find(
                        (i) => i?.integration_type === params.value
                      );
                      if (!Boolean(target_integration)) return "invalidValue";
                    }
                    if (
                      params.row?.event_type === "ALERT" &&
                      params.field === "primary_property"
                    ) {
                      if (!Boolean(params.value)) return "invalidValue";
                      let target_integration = demoConfig?.integrations.find(
                        (i) =>
                          i?.bp_integration?.stream_id === params.row?.app_key
                      );
                      if (!params.row?.tags.hasOwnProperty(params.value))
                        return "missingTag";
                      if (params.value !== target_integration?.primary_property)
                        return "invalidType";
                      return "meta";
                    }
                    if (
                      params.row?.event_type === "ALERT" &&
                      params.field === "secondary_property"
                    ) {
                      if (!Boolean(params.value)) return "invalidValue";
                      let target_integration = demoConfig?.integrations.find(
                        (i) =>
                          i?.bp_integration?.stream_id === params.row?.app_key
                      );
                      if (!params.row?.tags.hasOwnProperty(params.value))
                        return "missingTag";
                      if (
                        params.value !== target_integration?.secondary_property
                      )
                        return "invalidType";
                      return "meta";
                    }
                    if (
                      params.row?.event_type === "ALERT" &&
                      params.field === "tag_status"
                    ) {
                      if (
                        !(
                          /[Oo][Kk]/.test(params.value) ||
                          /[Cc][Rr][Ii][Tt][Ii][Cc][Aa][Ll]/.test(
                            params.value
                          ) ||
                          /[Ww][Aa][Rr][Nn][Ii][Nn][Gg]/.test(params.value) ||
                          /[Aa][Cc][Kk][Nn][Oo][Ww][Ll][Ee][Dd][Gg][Ee][Dd]/.test(
                            params.value
                          ) ||
                          /[Uu][Nn][Kk][Nn][Oo][Ww][Nn]/.test(params.value)
                        )
                      )
                        return "invalidValue";
                    }
                    if (params.field.startsWith("tag_")) {
                      return "tag";
                    }
                    return "meta";
                  }}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                avatar={<Assignment color="primary" />}
                title="Changes"
                titleTypographyProps={{ color: "primary", variant: "h5" }}
              />
              <CardContent
                sx={{
                  // boxShadow: 2,
                  // padding: 1,
                  // border: 1,
                  "& .MuiDataGrid-cell--editing": {
                    backgroundColor: "rgb(255,215,115, 0.19)",
                    color: "#1a3e72",
                    "& .MuiInputBase-root": {
                      height: "100%",
                    },
                  },
                  "& .Mui-error": {
                    backgroundColor: `rgb(126,10,15,0.1)`,
                    color: "error.main",
                  },
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .invalidType": {
                    backgroundColor: "mistyrose",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .missingTag": {
                    backgroundColor: "yellow",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .invalidValue": {
                    backgroundColor: "red",
                    fontStyle: "oblique",
                    fontWeight: "bold",
                  },
                  "& .tag": {
                    fontFamily: "Monospace",
                  },
                  "& .meta": {
                    fontStyle: "oblique",
                  },
                }}
              >
                <DataGridPro
                  apiRef={changeGridApiRef}
                  autoHeight
                  disableColumnMenu
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: { refreshVariables },
                  }}
                  rows={workingChangeData}
                  columns={workingChangeColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pageSizeOptions={[5, 10, 50, 100, 1000]}
                  pagination
                  getCellClassName={(params) => {
                    if (
                      params?.field === "app_key" &&
                      params.value.includes("UNASSIGNED")
                    )
                      return "invalidValue";
                    if (
                      params.field === "_offset" ||
                      params.field === "integration_type"
                    )
                      return "meta";
                    if (params.field === "status") {
                      const re = new RegExp(
                        "Planned|In Progress|Done|Canceled",
                        "i"
                      );
                      if (!re.test(params.value)) return "invalidValue";
                    }
                    if (params.field.startsWith("tag_")) {
                      return "tag";
                    }
                    return "tag";
                  }}
                />
              </CardContent>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ScenarioTest;

function EditToolbar(props) {
  const { refreshVariables } = props;
  // const apiRef = useGridApiContext();
  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={2}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button color="primary" onClick={refreshVariables}>
          Refresh Variables
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
}
