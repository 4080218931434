import React from "react";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import DrawerHeader from "./DrawerHeader";
import Divider from "@mui/material/Divider";

const drawerWidth = 240;

const DrawerMobile = (props) => {
  const { mobileOpen, setMobileOpen, handleDrawerPopup, children } = props;
  const theme = useTheme();
  return (
    <Drawer
      anchor="left"
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerPopup}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "flex", md: "none" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
      }}
    >
      <Box
        role="presentation"
        onClick={() => {
          if (mobileOpen) setMobileOpen(false);
        }}
        onKeyDown={() => {
          if (mobileOpen) setMobileOpen(false);
        }}
      >
        <DrawerHeader theme={theme} />
        <Divider />
        {children}
      </Box>
    </Drawer>
  );
};

export default DrawerMobile;
