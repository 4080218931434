import React, { Fragment, useState, useEffect, ChangeEvent } from "react";

import { parse } from "csv-parse/browser/esm/sync";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Alert } from "@mui/material";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PublishIcon from "@mui/icons-material/Publish";
import { Logger } from "aws-amplify";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";

const logger = new Logger("UploadDataFormDialog", "INFO");

const Input = styled("input")({
  display: "none",
});

export default function UploadDataFormDialog({
  setScenarioChanged,
  setGridData,
}) {
  const apiRef = useGridApiContext();
  const [oldColumns, setOldColumns] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [newRows, setNewRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [schemaIsValid, setSchemaIsValid] = useState(false);
  const [schemaError, setSchemaError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function resetAll() {
    setOpen(false);
    setNewColumns([]);
    setOldColumns([]);
    setNewRows([]);
    setSchemaIsValid(false);
    setSchemaError(false);
  }
  const handleClose = () => {
    resetAll();
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      let columnObjects = apiRef.current
        .getAllColumns()
        .filter((c) => c.field !== "__check__");
      setOldColumns(
        columnObjects
          .map((c) => (c.headerName ? c.headerName : c.field))
          .filter((c) => c !== "id")
      );

      const reader = new FileReader();
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        if (readerEvent?.target?.result) {
          const csvResults = parse(readerEvent?.target?.result.toString(), {
            // columns: true,
            skip_empty_lines: true,
            trim: true,
            skip_records_with_empty_values: true,
            relax_column_count: true,
            relaxQuotes: true,
          });
          let { headers, rows } = tableToHeadersColumns(csvResults);
          let idIndex = headers.indexOf("id");
          if (idIndex >= 0) headers.splice(idIndex, 1);
          setNewColumns(headers);
          setNewRows(
            rows.map((obj) => {
              return {
                ...obj,
                id: uuidv4(),
              };
            })
          );
        }
      };
    }
    e.target.value = null;
  };

  useEffect(() => {
    if (newColumns.length > 0 && oldColumns.length > 0) {
      logger.info("oldColumns:", oldColumns);
      logger.info("newColumns:", newColumns);
      // validate schema values and order
      let schemaValidation = newColumns.reduce((bool, val, idx) => {
        return bool && val === oldColumns[idx];
      }, true);
      setSchemaIsValid(schemaValidation);
      setSchemaError(!schemaValidation);
    }
  }, [oldColumns, newColumns]);

  const handleAddRecordButtonClick = () => {
    // const id = uuidv4();
    // Wait for the grid to render with the new row
    setTimeout(() => {
      setGridData(eventsToRows(newRows));
      apiRef.current.resize();
      apiRef.current.forceUpdate();
    });

    setScenarioChanged(true);
    resetAll();
  };

  return (
    <Fragment>
      <Tooltip title="Replace all records from uploaded CSV">
        <Button
          variant="text"
          size="small"
          color="primary"
          startIcon={<UploadFileIcon />}
          onClick={handleClickOpen}
        >
          IMPORT
        </Button>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <DialogTitle>Replace Record Data</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>{`
              This dialog will change the record data (rows) but not the schema (columns). 
              It expects a CSV file in the exact format as exported from the scenario editor.
              You can change the cell contents and even the order and number of rows, but DON'T change row #1 (the column headers) values or order!
              First use the EXPORT button to create the CSV file, modify rows 2 through N, then use this dialog to upload the CSV and replace all the records with the new data.
            `}</DialogContentText>
            {!Boolean(newColumns.length) && (
              <Alert severity="info">
                The CSV file must retain the original header values and order.
              </Alert>
            )}
            {Boolean(newColumns.length) && schemaError && (
              <Alert severity="error">
                Invalid schema: the incoming CSV file has different header
                values or order!
              </Alert>
            )}
            {Boolean(newColumns.length) && !schemaError && (
              <Alert severity="success">Validated schema</Alert>
            )}
            <Divider variant="middle" />
            <label htmlFor="contained-button-file-import">
              <Input
                id="contained-button-file-import"
                accept=".csv"
                type="file"
                onChange={handleFileUpload}
              />
              <Button variant="contained" component="span">
                Select CSV File
              </Button>
            </label>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!schemaIsValid}
            onClick={handleAddRecordButtonClick}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function tableToHeadersColumns(table) {
  let cnt = 0;
  const headers = table[0].map((h) => (Boolean(h) ? h : `_${cnt++}`));
  const rows = table.slice(1).map((row) => {
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = Boolean(row[i]) ? row[i] : " ";
      return obj;
    }, {});
    return eachObject;
  });
  return { headers, rows };
}

function eventsToRows(events) {
  return events.map((event) => {
    let payload = {};
    if (event.hasOwnProperty("event_type")) {
      let {
        id,
        event_type,
        _offset,
        seconds,
        integration_type,
        primary_property,
        secondary_property,
        ...tags
      } = event;
      payload = {
        id,
        event_type,
        _offset: Number(_offset),
        seconds: Number(seconds),
        integration_type,
        primary_property,
        secondary_property,
        tags,
      };
    } else if (
      event.hasOwnProperty("identifier") &&
      event.hasOwnProperty("summary") & event.hasOwnProperty("status")
    ) {
      let {
        id,
        _offset,
        integration_type,
        identifier,
        status,
        summary,
        ticket_url,
        ...tags
      } = event;
      payload = {
        id,
        _offset: Number(_offset),
        integration_type,
        identifier,
        status,
        summary,
        ticket_url,
        tags,
      };
    } else {
    }

    return {
      id: uuidv4(),
      ...payload,
    };
  });
}
