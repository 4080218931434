import { useState } from "react";
import { Typography, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  item: any;
};

function valueCountLabel(count) {
  if (count === 0) {
    return "no values";
  }
  if (count > 99) {
    return `More than 99 values; click to show all`;
  }
  return `${count} values; click elipses to show all`;
}

export default function AlertTagSummary({ item: tag }: Props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 1,
        width: "100%",
        // wrap content to width of parent
        flexWrap: "wrap",
        marginBottom: 1,
        padding: 1,
        border: 1,
        borderColor: "primary.main",
        borderRadius: 1,
        position: "relative",
        paddingRight: 6,
        paddingLeft: 1,
        paddingBottom: 1.5,
      }}
    >
      <Typography sx={{ mr: 2, fontWeight: 700 }}>{tag.name}:</Typography>
      {Array.isArray(tag.value) ? (
        expanded ? (
          tag.value.map((value) => <Chip key={value} label={value} />)
        ) : (
          tag.value
            .slice(0, 5)
            .map((value) => <Chip key={value} label={value} />)
        )
      ) : (
        <Chip key={tag.value} label={tag.value} />
      )}
      {Array.isArray(tag.value) && tag.value.length > 5 && (
        <Tooltip
          title={expanded ? "Show first 5 values" : "Show all values"}
          placement="left"
          arrow
        >
          <IconButton
            color="primary"
            aria-label={valueCountLabel(tag.value.length)}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setExpanded(!expanded);

              // alert(event.currentTarget.value);
            }}
            sx={{
              mr: 2,
              fontWeight: 700,
              position: "absolute",
              right: 0,
            }}
          >
            <Badge badgeContent={tag.value.length} color="secondary" max={99}>
              <MoreHorizIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
