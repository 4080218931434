import React from "react";

// mui components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// custom components
import useAppState from "store/appState";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

export default function DeleteOrgDialog({ open, setOpen }) {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { deleteDemoConfig } = useServerStateMutations();

  const handleDeleteDemoConfig = async function handleDeleteDemoConfig() {
    deleteDemoConfig.mutateAsync(demoConfig.id)
    .then(() => setOpen(false))
  };

  return (
    <Dialog
      id="dlg-confirm-org-delete"
      data-test="dlg-confirm-org-delete"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="dlg-confirm-org-delete"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      disableEnforceFocus
      keepMounted
    >
      <DialogTitle id="dlg-confirm-org-delete">
        {"Delete this Demo Config?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action will PERMANENTLY delete this Demo Config!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="btn-cancel-org-delete"
          data-test="btn-cancel-org-delete"
          onClick={() => setOpen(false)}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          id="btn-confirm-org-delete"
          data-test="btn-confirm-org-delete"
          onClick={handleDeleteDemoConfig}
        >
          Yes, Delete this Demo Config
        </Button>
      </DialogActions>
    </Dialog>
  );
}
