/* 
Downloads these files:
  Not applicable
*/
import { useMemo } from "react";

// MUI components
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

// Utility functions and components
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";

// Custom hooks and functions
import useAppState from "../../../store/appState";
import { useDemoConfig, useBpUsers } from "../../../store/serverState";
import OrgSelector from "../../../components/OrgSelector/OrgSelector.js";
import MrtExportMenu from "../../../components/Tables/MrtExportMenu";

// Types
type BpScimUserType = {
  id: string;
  userName: string;
  displayName: string;
  meta: {
    created: string;
    location: string;
  };
  active: boolean;
  password: string;
  phoneNumbers: { value: string; type: string };
  roles: string[];
};

export default function BpUserTools() {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  // data for table
  const {
    isLoading: isLoadingUsers,
    isRefetching: isRefetchingUsers,
    data: bpUsers,
  } = useBpUsers({
    region: demoConfig.region,
    api_key: demoConfig.api_key,
  });

  const userDataRows = useMemo<BpScimUserType[]>(() => {
    if (!bpUsers?.Resources) return [];
    if (bpUsers?.Resources.length === 0) return [];
    if (bpUsers?.Resources && demoConfig.region && demoConfig.api_key) {
      return bpUsers?.Resources.map((bpUser) => ({
        id: bpUser.id,
        displayName: bpUser.displayName,
        userName: bpUser.userName,
        active: bpUser.active,
        roles: bpUser.roles.join(", "),
      }));
    }
    return [];
  }, [bpUsers, demoConfig.region, demoConfig.api_key]);

  const userDataColumns = useMemo<MRT_ColumnDef<BpScimUserType>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "User name",
        accessorKey: "userName",
      },
      {
        header: "Display name",
        accessorKey: "displayName",
      },
      {
        header: "Active",
        accessorKey: "active",
      },
      {
        header: "Roles",
        accessorKey: "roles",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns: userDataColumns,
    data: userDataRows,
    state: {
      isLoading: isLoadingUsers, //cell skeletons and loading overlay
      showProgressBars: isRefetchingUsers, //progress bars while refetching
    },
    muiTablePaperProps: {
      elevation: 1, //change the mui box shadow
    },
    enableFullScreenToggle: false,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <MrtExportMenu table={table} />
    ),
  });

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5">BigPanda Org User Accounts</Typography>
      <OrgSelector />
      <Divider sx={{ margin: 2 }} />
      <MaterialReactTable table={table} />
    </Stack>
  );
}
