/* 
  This page enables the user to load a CSV file of changes, 
  map the original schema to a BigPanda change schema, 
  edit the data and schema in a MUI DataGrid,
  and then post the changes into a running BigPanda org.
  It also enables the user to export the final (presumably acceptable) changes from the MUI DataGrid to a CSV or Excel file.
  It also enables the user to download a CLI tool that can be used to post changes into a running BigPanda org.

  Downloads these files:
    bpChangeLoader-linux-x64
    bpChangeLoader-macos-arm64
    bpChangeLoader-macos-x64
    bpChangeLoader-win-x64.exe

  Structure of the pages is as follows:
  It uses a WizardStepper component to display the steps.
  It also uses a MUI DataGrid to display the data and enable editing.
  Thirdly, if there are CLI versions of the tool available, it displays a CliDownloads component.

  Steps are defined in the steps array. Each step has the following properties:
  label: the text to display in the step header
  optional: whether the step is optional
  description: the text to display in the step body
  component: the component to display in the step body
  gate: a boolean that determines whether the step is enabled
*/

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
} from "react";
import { parse } from "csv-parse/browser/esm/sync";
import { Logger } from "aws-amplify";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";

// MUI components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

// Custom components
import OrgSelector from "../../../components/OrgSelector/OrgSelector.js";
import ChangeStatusMapDialog from "../../../components/ChangeStatusMapDialog/ChangeStatusMapDialog";
import CliDownloads from "./CliDownloads";
import InputFileOpen from "../../../components/Inputs/InputFileOpen";
import HorizontalLinearStepper, {
  WizardStepProps,
} from "../../../components/WizardStepper/HorizontalLinearStepper";
import MrtExportMenu from "../../../components/Tables/MrtExportMenu";
// Custom hooks and functions
import useAppState from "../../../store/appState";
// import useScenarios from "../../hooks/scenarioFunctions.js";
import { postChanges } from "../../../store/graphql-functions.js";
import { useDemoConfig } from "../../../store/serverState";
import { useSnackbar } from "notistack";
import {
  fixTimestamp,
  tableToHeadersColumns,
  randomizeAllNumbersInString,
} from "../../../lib/transform_funcs";
import Bottleneck from "bottleneck";

const logger = new Logger("SETools/BpChangeLoader", "INFO");

// The generic change type has these classes of fields, by these names or other names. This data must be mapped into a BigPanda change schema.
type GenericChangeRecord = {
  identifier?: string;
  status?: string;
  summary?: string;
  start?: number;
  end?: number;
  ticket_url?: string;
  [key: string]: string | number | undefined;
};

export default function BpChangeLoader() {
  // Global state
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgIntegrations } = useDemoConfig(currentDemoConfigId);
  // const { postChanges } = useScenarios();
  const { enqueueSnackbar } = useSnackbar();
  // const noticeAction = useNoticeAction();
  const [postLog, setPostLog] = useState<string[]>([]);

  const integrationOptions = useMemo(
    () =>
      bpOrgIntegrations
        .filter((i) => i.parent_source_system === "changes")
        .map((i) => {
          return {
            name: i.name,
            stream_id: i.stream_id,
          };
        }),
    [bpOrgIntegrations]
  );

  // local state
  const [changeDataRows, setChangeDataRows] = useState<GenericChangeRecord[]>(
    []
  );
  const [changeDataKeys, setChangeDataKeys] = useState<string[]>([]);
  const [integrationDest, setIntegrationDest] = useState(null);
  const [integrationDestInput, setIntegrationDestInput] = useState("");
  const [startColumn, setStartColumn] = useState<string>(null);
  const [startColumnInput, setStartColumnInput] = useState<string>("");
  const [endColumn, setEndColumn] = useState<string>(null);
  const [endColumnInput, setEndColumnInput] = useState<string>("");
  const [identifierColumn, setIdentifierColumn] = useState<string>(null);
  const [identifierColumnInput, setIdentifierColumnInput] =
    useState<string>("");
  const [statusColumn, setStatusColumn] = useState<string>(null);
  const [statusColumnInput, setStatusColumnInput] = useState<string>("");
  const [summaryColumn, setSummaryColumn] = useState<string>(null);
  const [summaryColumnInput, setSummaryColumnInput] = useState<string>("");
  const [ticketUrlColumn, setTicketUrlColumn] = useState<string>(null);
  const [ticketUrlColumnInput, setTicketUrlColumnInput] = useState<string>("");
  const [posting, setPosting] = useState<boolean>(false);

  const changeDataColumns = useMemo<MRT_ColumnDef<GenericChangeRecord>[]>(
    () =>
      changeDataKeys.map((key) => ({
        accessorKey: key,
        header: key,
      })),
    [changeDataKeys]
  );

  const allRequiredFieldsSet = useMemo(() => {
    return Boolean(
      identifierColumn && statusColumn && summaryColumn && startColumn
    );
  }, [identifierColumn, statusColumn, summaryColumn, startColumn]);

  const statusValuesValidated = useMemo(() => {
    return changeDataRows.every((row) => {
      return (
        row[statusColumn] === "Planned" ||
        row[statusColumn] === "In Progress" ||
        row[statusColumn] === "Done" ||
        row[statusColumn] === "Canceled"
      );
    });
  }, [changeDataRows, statusColumn]);

  const startValuesValidated = useMemo(() => {
    return (
      Boolean(startColumn) &&
      changeDataRows.every((row) => {
        return new Date(fixTimestamp(row[startColumn])).getTime() > 0;
      })
    );
  }, [changeDataRows, startColumn]);

  const endValuesValidated = useMemo(() => {
    return Boolean(endColumn)
      ? changeDataRows.every((row) => {
          return new Date(fixTimestamp(row[endColumn])).getTime() > 0;
        })
      : true;
  }, [changeDataRows, endColumn]);

  const table = useMaterialReactTable({
    columns: changeDataColumns,
    data: changeDataRows,
    enableFullScreenToggle: false,
    muiTablePaperProps: {
      elevation: 1, //change the mui box shadow
      //customize paper styles
      sx: {
        padding: 1,
        width: "100%",
        maxWidth: 1500,
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <MrtExportMenu table={table} />
    ),
  });

  // log the current state of the grid every time sourceFileRows or sourceFileColumns changes
  useEffect(() => {
    logger.info("changeDataColumns:", changeDataColumns);
    logger.info("changeDataRows:", changeDataRows);
    logger.info("bpOrgIntegrations:", bpOrgIntegrations);
    logger.info("integrationDest:", integrationDest);
    logger.info("integrationOptions:", integrationOptions);
  }, [
    changeDataColumns,
    changeDataRows,
    bpOrgIntegrations,
    integrationDest,
    integrationOptions,
  ]);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsText(e.target.files[0], "UTF-8");
      reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
        if (readerEvent?.target?.result) {
          const csvResults = parse(readerEvent?.target?.result.toString(), {
            // columns: true,
            skip_empty_lines: true,
            trim: true,
            skip_records_with_empty_values: true,
            relax_column_count: true,
            cast: (value, context) => value.replace(/[\r\n]/g, " "),
          });
          let { headers, rows } = tableToHeadersColumns(csvResults);
          setChangeDataRows(rows);
          setChangeDataKeys(headers);
        }
      };
    }
    e.target.value = null;
  };

  const handleRandomizeIdentifiers = useCallback(() => {
    if (!identifierColumn) return;
    setChangeDataRows(
      changeDataRows.map((row) => {
        return {
          ...row,
          [identifierColumn]: randomizeAllNumbersInString(
            row[identifierColumn]
          ),
        };
      })
    );
  }, [changeDataRows, identifierColumn]);

  // Convert start and end timestamps to offsets relative to now.
  const handleOffsets = useCallback(
    (endpoint) => {
      if (!startColumn) {
        enqueueSnackbar("You must select a start column", {
          variant: "error",
        });
        return;
      }
      if (endpoint === "end" && !endColumn) {
        enqueueSnackbar("You must select an end column", {
          variant: "error",
        });
        return;
      }
      // abort if startValuesValidated is false
      if (!startValuesValidated) {
        enqueueSnackbar("All start values must be valid timestamps", {
          variant: "error",
        });
        return;
      }
      // abort if endValuesValidated is false
      if (endpoint === "end" && !endValuesValidated) {
        enqueueSnackbar("All end values must be valid timestamps", {
          variant: "error",
        });
        return;
      }
      logger.info("changeDataRows:", changeDataRows);
      // get changeDataRows, set start and end fields to unix timestamps, and sort by start
      const dataWithEpoch = changeDataRows.map(
        (record: GenericChangeRecord) => {
          record[startColumn] = fixTimestamp(record[startColumn]);
          if (endColumn) record[endColumn] = fixTimestamp(record[endColumn]);
          return record;
        }
      );
      // .sort((a, b) => Number(b[startColumn]) - Number(a[startColumn]));
      logger.info("dataWithEpoch:", dataWithEpoch);
      let timestamps =
        endpoint === "end"
          ? dataWithEpoch.map((val) => Number(val[endColumn]))
          : dataWithEpoch.map((val) => Number(val[startColumn]));

      logger.info("timestamps:", timestamps);
      // get max and min timestamps
      let maxTS = timestamps.reduce(
        (a: number, b: number) => Math.max(a, b),
        -Infinity
      );
      logger.info("maxTS:", maxTS);
      let minTS = Math.min(...timestamps);
      logger.info("minTS:", minTS);
      let delta = fixTimestamp() - maxTS;
      logger.info("delta:", delta);
      // get range in seconds
      let range = maxTS - minTS;
      // get range in hours and minutes
      let hours = Math.trunc(range / 60 / 60);
      let days = hours / 24;
      let minutes = Math.trunc((range - hours * 60 * 60) / 60);
      logger.info(
        `Original start time range is ${days} days (${hours} hours and  ${minutes} minutes); from ${new Date(
          minTS * 1000
        )} to ${new Date(maxTS * 1000)}`
      );
      enqueueSnackbar(
        `Original start time range is ${days} days (${hours} hours and  ${minutes} minutes); from ${new Date(
          minTS * 1000
        )} to ${new Date(maxTS * 1000)}`,
        { variant: "info" }
      );

      // calculate offsets

      setChangeDataRows(
        dataWithEpoch.map((row) => {
          let data = {
            ...row,
            [startColumn]: new Date(
              (Number(row[startColumn]) + delta) * 1000
            ).toLocaleString(),
          };
          if (endColumn)
            data[endColumn] = new Date(
              (Number(row[endColumn]) + delta) * 1000
            ).toLocaleString();
          return data;
        })
      );
    },
    [
      changeDataRows,
      startColumn,
      endColumn,
      endValuesValidated,
      startValuesValidated,
      enqueueSnackbar,
    ]
  );

  // dynamically clear out the identifier, status, summary, start, end, and ticket_url columns if they're not in the headers
  useEffect(() => {
    if (
      identifierColumn &&
      !changeDataColumns.some(
        (column) => column.accessorKey === identifierColumn
      )
    ) {
      setIdentifierColumn(null);
      setIdentifierColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "identifier")
    ) {
      setIdentifierColumn("identifier");
      setIdentifierColumnInput("identifier");
    }
    if (
      statusColumn &&
      !changeDataColumns.some((column) => column.accessorKey === statusColumn)
    ) {
      setStatusColumn(null);
      setStatusColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "status")
    ) {
      setStatusColumn("status");
      setStatusColumnInput("status");
    }
    if (
      summaryColumn &&
      !changeDataColumns.some((column) => column.accessorKey === summaryColumn)
    ) {
      setSummaryColumn(null);
      setSummaryColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "summary")
    ) {
      setSummaryColumn("summary");
      setSummaryColumnInput("summary");
    }
    if (
      startColumn &&
      !changeDataColumns.some((column) => column.accessorKey === startColumn)
    ) {
      setStartColumn(null);
      setStartColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "start")
    ) {
      setStartColumn("start");
      setStartColumnInput("start");
    }
    if (
      endColumn &&
      !changeDataColumns.some((column) => column.accessorKey === endColumn)
    ) {
      setEndColumn(null);
      setEndColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "end")
    ) {
      setEndColumn("end");
      setEndColumnInput("end");
    }
    if (
      ticketUrlColumn &&
      !changeDataColumns.some(
        (column) => column.accessorKey === ticketUrlColumn
      )
    ) {
      setTicketUrlColumn(null);
      setTicketUrlColumnInput("");
    } else if (
      changeDataColumns.some((column) => column.accessorKey === "ticket_url")
    ) {
      setTicketUrlColumn("ticket_url");
      setTicketUrlColumnInput("ticket_url");
    }
  }, [
    changeDataColumns,
    identifierColumn,
    statusColumn,
    summaryColumn,
    startColumn,
    endColumn,
    ticketUrlColumn,
  ]);

  // const [posterRunning, setPosterRunning] = useState<boolean>(false);
  // useEffect(() => {
  //   if (posterRunning) {
  //     console.log('poster running');
  //   }

  //   return () => {
  //     second
  //   }
  // }, [third])

  const handlePost = useCallback(async () => {
    // Setup throttling handler
    const limiter = new Bottleneck({
      reservoir: 3, // initial value
      reservoirRefreshAmount: 3,
      reservoirRefreshInterval: 1000, // must be divisible by 250
      minTime: 100,
      maxConcurrent: 3,
    });
    limiter.on("empty", function () {
      // This will be called when `limiter.empty()` becomes true.
      setPosting(false);
    });
    limiter.on("failed", async (error, jobInfo: any) => {
      logger.error(`Failed to post change ${jobInfo.options.id}`, error);
      if (error.code === "ECONNABORTED") {
        logger.warn(`Throttle error posting change ${jobInfo.options.id}`);
        setPostLog((loghistory) => [
          `${new Date().toLocaleTimeString()}\t\tChange ${
            jobInfo.options.id
          }: ${error.message} (${error.code}), Retrying after 1 second\n`,
          ...loghistory,
        ]);

        return 1000; // wait 200ms before retrying
      } else {
        setPostLog((loghistory) => [
          `${new Date().toLocaleTimeString()}\tFailed to post change ${
            jobInfo.options.id
          }. Error: ${error.message} (${error.code})\n`,
          ...loghistory,
        ]);
      }
    });

    setPosting(true);
    logger.info("Posting to BigPanda");

    // removed  rows, rowsById
    let { flatRows } = table.getFilteredRowModel();
    let filteredChangeDataRows = flatRows.map((row) => row.original);

    let changesToPost = filteredChangeDataRows.map(
      (row: GenericChangeRecord) => {
        const rowClone: GenericChangeRecord = Object.assign({}, row);
        let change: GenericChangeRecord = {
          app_key: integrationDest.stream_id,
          identifier: row[identifierColumn] as string,
          status: row[statusColumn] as string,
          summary: row[summaryColumn] as string,
        };

        if (row[startColumn]) {
          change["start"] =
            typeof row[startColumn] === "string"
              ? Date.parse(row[startColumn] as string) / 1000
              : (row[startColumn] as number);
        }
        if (row[endColumn])
          change["end"] =
            typeof row[endColumn] === "string"
              ? Date.parse(row[endColumn] as string) / 1000
              : (row[endColumn] as number);

        if (row[ticketUrlColumn])
          change["ticket_url"] = row[ticketUrlColumn] as string;
        delete rowClone[identifierColumn];
        delete rowClone[statusColumn];
        delete rowClone[summaryColumn];
        delete rowClone[startColumn];
        delete rowClone[endColumn];
        delete rowClone[ticketUrlColumn];
        // change contains the required fields. rowClone contains the tags.
        return {
          ...change,
          ...rowClone,
        };
      }
    );
    logger.info("changesToPost:", changesToPost);
    enqueueSnackbar(
      "Posting changes to BigPanda. Please wait for confirmation popup before continuing.",
      { variant: "info" }
    );
    changesToPost.forEach((change) => {
      limiter
        .schedule({ id: change.identifier }, postChanges, {
          changes: [change],
          access_token: demoConfig.api_key,
          region: demoConfig.region,
        })
        .then((result: any) => {
          logger.info(
            `Post Result: ${JSON.stringify(result)} for change id ${
              change.identifier
            }`
          );
          setPostLog((loghistory) => [
            `${new Date().toLocaleTimeString()}\t\tChange ${
              change.identifier
            }: Posted\n`,
            ...loghistory,
          ]);
        })
        .catch((error: Error) => {
          if (error instanceof Bottleneck.BottleneckError) {
            logger.error(`Scheduling error`, error);
            setPostLog((loghistory) => [
              `${new Date().toLocaleTimeString()}\t\tScheduling error: ${error}\n`,
              ...loghistory,
            ]);
          } else {
            logger.error(error);
            setPostLog((loghistory) => [
              `${new Date().toLocaleTimeString()}\t\tError: ${error}\n`,
              ...loghistory,
            ]);
          }
        });
    });

    // handleNext();
  }, [
    identifierColumn,
    statusColumn,
    summaryColumn,
    startColumn,
    endColumn,
    ticketUrlColumn,
    integrationDest,
    demoConfig.api_key,
    demoConfig.region,
    table,
    enqueueSnackbar,
  ]);

  const steps: WizardStepProps[] = useMemo(() => {
    return [
      {
        label: `${
          demoConfig.bporgname
            ? `Using BigPanda Org:  ${demoConfig.bporgname}`
            : "Select/Create a Demo Config"
        }`,
        optional: false,
        description: `Change Loader enables you to:
      - load a CSV file of changes,
      - map the original schema to a BigPanda change schema,
      - edit the data and schema in a MUI DataGrid,
      - and then post the changes into a running BigPanda org.

      To perform the following steps you need a Demo Config record with a BigPanda Org User API Key.

      If you've already created a Demo Config record, select it.
      
      If you need to create a Demo Config, type a new record name into the Demo Config Selector.
      This will open a dialog; enter your BigPanda org's User API Key and your BigPanda email address.
      
      If you've entered the correct User API key, you'll see your BigPanda Org name in the box on the right
      and the step label will display the name of your BigPanda Org.`,
        component: <OrgSelector />,
        gate: Boolean(demoConfig.bporgname),
      },
      {
        label: "Load changes",
        optional: false,
        description: `Use the LOAD CHANGES FILE button to load a CSV file of change records.`,
        component: (
          <InputFileOpen
            type="file"
            text="Load changes file"
            accept=".csv"
            onChange={handleFileUpload}
          />
        ),
        gate: Boolean(changeDataRows.length > 0),
      },
      {
        label: "Set required fields",
        optional: false,
        description: `BigPanda's Changes schema requires identifier, status, summary, and start fields. End and ticket_url fields are optional.`,
        component: (
          <Stack
            direction="column"
            spacing={2}
            sx={{
              padding: 2,
              mt: 2,
              width: "100%",
              // height: "fit-content",
            }}
          >
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Identifier"
                  error={!identifierColumn}
                  required
                />
              )}
              value={identifierColumn}
              onChange={(event: any, newValue: string | null) => {
                setIdentifierColumn(newValue);
              }}
              inputValue={identifierColumnInput}
              onInputChange={(event, newInputValue) => {
                setIdentifierColumnInput(newInputValue);
              }}
            />
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Summary"
                  error={!summaryColumn}
                  required
                />
              )}
              value={summaryColumn}
              onChange={(event: any, newValue: string | null) => {
                setSummaryColumn(newValue);
              }}
              inputValue={summaryColumnInput}
              onInputChange={(event, newInputValue) => {
                setSummaryColumnInput(newInputValue);
              }}
            />
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  error={!statusColumn}
                  required
                />
              )}
              value={statusColumn}
              onChange={(event: any, newValue: string | null) => {
                setStatusColumn(newValue);
              }}
              inputValue={statusColumnInput}
              onInputChange={(event, newInputValue) => {
                setStatusColumnInput(newInputValue);
              }}
            />
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Start"
                  error={!startColumn}
                  required
                />
              )}
              value={startColumn}
              onChange={(event: any, newValue: string | null) => {
                setStartColumn(newValue);
              }}
              inputValue={startColumnInput}
              onInputChange={(event, newInputValue) => {
                setStartColumnInput(newInputValue);
              }}
            />
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => <TextField {...params} label="End" />}
              value={endColumn}
              onChange={(event: any, newValue: string | null) => {
                setEndColumn(newValue);
              }}
              inputValue={endColumnInput}
              onInputChange={(event, newInputValue) => {
                setEndColumnInput(newInputValue);
              }}
            />
            <Autocomplete
              sx={{ maxWidth: "50%" }}
              openOnFocus
              blurOnSelect
              size="small"
              options={changeDataColumns.map((column) => column.accessorKey)}
              renderInput={(params) => (
                <TextField {...params} label="TicketURL" />
              )}
              value={ticketUrlColumn}
              onChange={(event: any, newValue: string | null) => {
                setTicketUrlColumn(newValue);
              }}
              inputValue={ticketUrlColumnInput}
              onInputChange={(event, newInputValue) => {
                setTicketUrlColumnInput(newInputValue);
              }}
            />
          </Stack>
        ),
        gate: allRequiredFieldsSet,
      },
      {
        label: "Edit",
        optional: true,
        description: `Use these buttons to quickly "fix" data.
        
        Note: Required actions are highlighted in red; you cannot proceed to the next step until you've completed them.
        `,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={handleRandomizeIdentifiers}
              >
                Randomize identifiers
              </Button>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                Randomize all numbers in the identifier column.
              </Typography>
            </Stack>
            {!statusValuesValidated && (
              <Alert severity="error">
                All status values must be one of: Planned, In Progress, Done, or
                Canceled. Use Status Mapper to map current status values.
              </Alert>
            )}
            <Stack direction="row" spacing={2}>
              <ChangeStatusMapDialog
                statusColumn={statusColumn}
                changeDataRows={changeDataRows}
                setChangeDataRows={setChangeDataRows}
                statusValuesValidated={statusValuesValidated}
              />
              <Typography sx={{ whiteSpace: "pre-line" }}>
                Map status strings in the status column to conform to BigPanda
                Change API status values.
              </Typography>
            </Stack>
            {!startValuesValidated && (
              <Alert severity="error">
                All start values must be valid *NIX epoch timestamps or
                Date/Time strings. Cannot proceed. Repair in original data file
                and reload.
              </Alert>
            )}
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color={startValuesValidated ? "primary" : "error"}
                disabled={!startValuesValidated || !startColumn}
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => handleOffsets("start")}
              >
                Set Offsets: Start - Now
              </Button>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                Convert start and end timestamps to offsets relative to now.
                Assumes no future start times; most recent start time will be
                "now".
              </Typography>
            </Stack>
            {!endValuesValidated && (
              <Alert severity="error">
                All end values must be valid *NIX epoch timestamps or Date/Time
                strings. Cannot proceed. Repair in original data file and
                reload.
              </Alert>
            )}
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color={endValuesValidated ? "primary" : "error"}
                disabled={!endColumn || !endValuesValidated}
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => handleOffsets("end")}
              >
                Set Offsets: End - Now
              </Button>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                Convert start and end timestamps to offsets relative to now.
                Assumes no future end times; most recent end time will be "now".
              </Typography>
            </Stack>
          </Stack>
        ),
        gate:
          statusValuesValidated && startValuesValidated && endValuesValidated,
      },
      {
        label: "Post",
        optional: false,
        description: `Click the POST button to post the change records to your BigPanda org.`,
        component: (
          <Stack direction="column" spacing={2} margin={2}>
            <Stack direction="row" spacing={2} margin={2}>
              <Autocomplete
                sx={{ width: "50%" }}
                // fullWidth
                openOnFocus
                blurOnSelect
                size="small"
                options={integrationOptions}
                getOptionLabel={(option) =>
                  `${option.name} (***${option?.stream_id?.slice(-6)})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select Integration Target"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.stream_id === value.stream_id
                }
                value={integrationDest}
                onChange={(event: any, newValue: string | null) => {
                  setIntegrationDest(newValue);
                }}
                inputValue={integrationDestInput}
                onInputChange={(event, newInputValue) => {
                  setIntegrationDestInput(newInputValue);
                }}
              />
              <Button
                variant="outlined"
                sx={{
                  margin: 2,
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={handlePost}
                disabled={!integrationDest}
              >
                Post changes to BigPanda
              </Button>
            </Stack>
            <Alert severity="info">
              HINT: Use the table filters to limit which changes are posted
            </Alert>
            {posting && (
              <Stack direction="column" spacing={2} margin={2}>
                <Alert severity="info">Posting to BigPanda</Alert>
                <LinearProgress />
              </Stack>
            )}
          </Stack>
        ),
        gate: true,
      },
    ];
  }, [
    changeDataRows,
    handleOffsets,
    demoConfig.bporgname,
    integrationOptions,
    integrationDest,
    integrationDestInput,
    handlePost,
    identifierColumn,
    statusColumn,
    summaryColumn,
    startColumn,
    endColumn,
    ticketUrlColumn,
    identifierColumnInput,
    statusColumnInput,
    summaryColumnInput,
    startColumnInput,
    endColumnInput,
    ticketUrlColumnInput,
    changeDataColumns,
    allRequiredFieldsSet,
    endValuesValidated,
    posting,
    startValuesValidated,
    statusValuesValidated,
    handleRandomizeIdentifiers,
  ]);

  return (
    <Grid container direction="row" paddingTop={6}>
      <Grid container item direction="column" paddingRight={8} xs={12} md={10}>
        <HorizontalLinearStepper steps={steps} />
        <TextField
          sx={{ mt: 2 }}
          id="post-log"
          label="Post Log"
          multiline
          maxRows={10}
          // variant="filled"
          value={postLog.join("")}
          disabled
        />
        <Divider sx={{ margin: 2 }} />

        <MaterialReactTable table={table} />
      </Grid>
      <Grid item xs={12} md={2}>
        <CliDownloads
          fileNames={[
            "bpChangeLoader-linux-x64",
            "bpChangeLoader-macos-arm64",
            "bpChangeLoader-macos-x64",
            "bpChangeLoader-win-x64.exe",
          ]}
        />
      </Grid>
    </Grid>
  );
}
