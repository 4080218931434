import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import useAppState from "store/appState";
import {  useDemoConfig } from "store/serverState";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";

export default function AddRowFormDialog({ setScenarioChanged, setGridData }) {
  const apiRef = useGridApiContext();
  const [columns, setColumns] = useState(() =>
    apiRef.current.getAllColumns().filter((c) => c.field !== "__check__")
  );
  const [newRecord, setNewRecord] = useState({});
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  const [open, setOpen] = React.useState(false);
  const [isValidRecord, setIsValidRecord] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setIsValidRecord(false);
    let newColumns = apiRef.current
      .getAllColumns()
      .filter((c) => c.field !== "__check__");

    // ensure there's at least one tag
    if (!newColumns.find((c) => c.field.startsWith("tag_"))) {
      newColumns.push({
        field: `tag_new_tag`,
        headerName: "new_tag",
        flex: 1,
        minWidth: 150,
        editable: true,
        valueGetter: (params) => {
          return params.row.tags
            ? params.row.tags[`new_tag`]
            : params.row[`new_tag`];
        },
        valueSetter: (params) => {
          return { ...params.row };
        },
      });
    }
    setColumns(newColumns);
    // use columns to create an empty record
    let record = newColumns.reduce((o, c) => {
      if (c.field.startsWith("tag_")) {
        return {
          ...o,
          tags: {
            ...o.tags,
            [c.headerName]: "",
          },
        };
      } else if (c.type === "number") {
        return {
          ...o,
          [c.field]: 0,
        };
      } else if (c.type === "singleSelect") {
        return {
          ...o,
          [c.field]: "",
        };
      } else
        return {
          ...o,
          [c.field]: "",
        };
    }, {});
    setNewRecord(record);

    // NOTE: abandoned this because it's too restrictive and actually changes the integration_type choices in the entire datagrid
    // let integrationTypeColumnIndex = newColumns.findIndex(c => c.field === 'integration_type')
    // newColumns[integrationTypeColumnIndex].valueOptions = newColumns[integrationTypeColumnIndex].valueOptions.filter(vo => demoConfig.integrations.some(coi => coi.integration_type === vo && record.tags.hasOwnProperty(coi.primary_property) && record.tags.hasOwnProperty(coi.secondary_property)))
    // setColumns(newColumns);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValidRecord(false);
  };

  // validation logic
  useEffect(() => {
    setIsValidRecord(
      () =>
        (typeof newRecord === "object" &&
          newRecord.event_type === "ALERT" &&
          newRecord.integration_type &&
          newRecord.primary_property &&
          newRecord.secondary_property &&
          newRecord.hasOwnProperty("tags") &&
          newRecord?.tags?.status &&
          newRecord?.tags?.hasOwnProperty(newRecord.primary_property) &&
          newRecord?.tags?.hasOwnProperty(newRecord.secondary_property)) ||
        newRecord.event_type === "PAUSE" ||
        newRecord.event_type === "ITAG" ||
        (newRecord.event_type === undefined &&
          newRecord.integration_type === "change-man" &&
          newRecord.identifier &&
          newRecord.summary &&
          newRecord.status)
    );
  }, [isValidRecord, newRecord, setIsValidRecord]);

  const handleAddRecordButtonClick = () => {
    const id = uuidv4();
    apiRef.current.updateColumns(columns);
    apiRef.current.updateRows([{ ...newRecord, id, isNew: true }]);

    // Wait for the grid to render with the new row
    setTimeout(() => {
      let rowModelsMap = apiRef.current.getRowModels();
      setGridData(Array.from(rowModelsMap.values()));
      apiRef.current.resize();
      apiRef.current.forceUpdate();
    });
    setScenarioChanged(true);
    setOpen(false);
  };

  const setIntegrationType = (integration_type) => {
    let target_integration =
      demoConfig.integrations.find(
        (i) => i.integration_type === integration_type
      ) || {};
    setNewRecord((s) => ({
      ...s,
      integration_type,
      primary_property: target_integration.primary_property,
      secondary_property: target_integration.secondary_property,
    }));
  };

  return (
    <div>
      <Tooltip title="Add a new row">
        <Button
          variant="text"
          size="small"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          New Row
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} disableEnforceFocus>
        <DialogTitle>New Row</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              Fill in these fields to create the new row
            </DialogContentText>
            <Divider variant="middle" />
            {columns.map((c, idx) => {
              if (c.field === "event_type") {
                return (
                  <TextField
                    required
                    error={newRecord[c.field] === ""}
                    key={idx}
                    id={`input-${c.field}`}
                    select
                    label={c.field}
                    helperText={c.description}
                    margin="dense"
                    value={newRecord[c.field]}
                    onChange={(e) => {
                      setNewRecord((s) => ({
                        ...s,
                        [c.field]: e.target.value,
                      }));
                    }}
                  >
                    {c.valueOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }
              if (c.field === "_offset") {
                return (
                  <TextField
                    required
                    error={
                      newRecord[c.field] === "" || newRecord[c.field] === null
                    }
                    key={idx}
                    id={`input-${c.field}`}
                    type="number"
                    label={c.field}
                    helperText={c.description}
                    margin="dense"
                    value={newRecord[c.field]}
                    onChange={(e) => {
                      setNewRecord((s) => ({
                        ...s,
                        [c.field]: Number(e.target.value),
                      }));
                    }}
                  />
                );
              }
              if (newRecord["event_type"] === "PAUSE") {
                if (c.field === "seconds") {
                  return (
                    <TextField
                      required={newRecord["event_type"] === "PAUSE"}
                      error={
                        newRecord["event_type"] === "PAUSE" &&
                        (newRecord[c.field] === "" ||
                          newRecord[c.field] === null)
                      }
                      disabled={newRecord["event_type"] === "ALERT"}
                      key={idx}
                      id={`input-${c.field}`}
                      type="number"
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: Number(e.target.value),
                        }));
                      }}
                    />
                  );
                }
              }
              if (
                newRecord["event_type"] === "ALERT" ||
                newRecord["event_type"] === undefined
              ) {
                if (c.field === "integration_type") {
                  return (
                    <TextField
                      required
                      error={newRecord[c.field] === ""}
                      disabled={newRecord["event_type"] === "PAUSE"}
                      key={idx}
                      id={`input-${c.field}`}
                      select
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setIntegrationType(e.target.value);
                      }}
                    >
                      {c.valueOptions
                        .filter((o) =>
                          newRecord["event_type"] === "ALERT"
                            ? o !== "change-man"
                            : true
                        )
                        .map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  );
                }
                if (c.field === "primary_property") {
                  let target_integration =
                    demoConfig.integrations.find(
                      (i) => i.integration_type === newRecord.integration_type
                    ) || {};
                  return (
                    <TextField
                      required
                      error={
                        newRecord[c.field] === "" ||
                        newRecord[c.field] !==
                          target_integration.primary_property ||
                        !newRecord?.tags?.hasOwnProperty(newRecord[c.field])
                      }
                      key={idx}
                      id={`input-${c.field}`}
                      label={c.field}
                      helperText={`Primary property (set by this org's integration type)`}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                      disabled={true}
                    />
                  );
                }
                if (c.field === "secondary_property") {
                  let target_integration =
                    demoConfig.integrations.find(
                      (i) => i.integration_type === newRecord.integration_type
                    ) || {};
                  return (
                    <TextField
                      required
                      error={
                        newRecord[c.field] === "" ||
                        newRecord[c.field] !==
                          target_integration.secondary_property ||
                        !newRecord?.tags?.hasOwnProperty(newRecord[c.field])
                      }
                      key={idx}
                      id={`input-${c.field}`}
                      // select
                      label={c.field}
                      helperText={`Secondary property (set by this org's integration type)`}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                      disabled={true}
                    />
                  );
                }
                // alerts have status as a tag property
                if (
                  c.field === "tag_status" &&
                  newRecord.hasOwnProperty("tags")
                ) {
                  return (
                    <TextField
                      required
                      error={
                        !newRecord?.tags[c.headerName] ||
                        newRecord?.tags[c.headerName]?.length === 0
                      }
                      disabled={newRecord["event_type"] === "PAUSE"}
                      key={idx}
                      id={`input-${c.field}`}
                      select
                      label={c.headerName}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord?.tags[c.headerName]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          tags: {
                            ...s.tags,
                            [c.headerName]: e.target.value,
                          },
                        }));
                      }}
                    >
                      {c.valueOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }
                // changes have status as a meta property
                if (c.field === "status") {
                  return (
                    <TextField
                      required
                      error={!newRecord[c.field]}
                      key={idx}
                      id={`input-${c.field}`}
                      select
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                    >
                      {c.valueOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }
                if (c.field === "identifier" || c.field === "summary") {
                  return (
                    <TextField
                      required
                      error={!newRecord[c.field]}
                      key={idx}
                      id={`input-${c.field}`}
                      type="text"
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                    />
                  );
                }
                if (
                  c.field.startsWith("tag_" && newRecord.hasOwnProperty("tags"))
                ) {
                  return (
                    <TextField
                      key={idx}
                      id={`input-${c.field}`}
                      type="text"
                      label={c.headerName}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord?.tags[c.headerName]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          tags: {
                            ...s.tags,
                            [c.headerName]: e.target.value,
                          },
                        }));
                      }}
                    />
                  );
                }
              }
              return <React.Fragment key={idx} />;
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!isValidRecord}
            onClick={handleAddRecordButtonClick}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


