import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";

// MUI components
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

// Custom components
import DownloadButton from "../../../components/Buttons/ButtonDownload";

type Props = {
  fileNames: string[];
};

Storage.configure({ level: "public" });

export default function CliDownloads({ fileNames }: Props) {
  const [files, setFiles] = useState(() =>
    fileNames.map((filename) => ({ filename, href: null }))
  );
  useEffect(() => {
    const getLinks = fileNames.map((filename) =>
      Storage.get(`setools/${filename}`, { expires: 3600 })
    );
    Promise.all(getLinks).then((links) => {
      setFiles(
        links.map((link, index) => ({ filename: fileNames[index], href: link }))
      );
    });
  }, [fileNames, setFiles]);

  return (
    <Stack direction={"column"} spacing={2}>
      <Typography variant="h6">CLI EXECUTABLES</Typography>
      <Typography variant="body2">
        Use CLI executable to perform the same function from command shell,
        scripts, etc. Use --help to see options.
      </Typography>
      <Divider variant="middle" />
      {files.map((file) => (
        <DownloadButton
          key={file.filename}
          href={file.href}
          filename={file.filename}
        />
      ))}
    </Stack>
  );
}
