import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@mui/material/Tooltip";

type newRecordType = {
  id: string;
  event_type: string;
  seconds: number;
  _offset: number;
  integration_type: string;
  primary_property: string;
  secondary_property: string;
  status: string;
  tag_status: string;
  tags: {
    [key: string]: string;
  };
};

export default function CopyRowFormDialog({
  rowsSelected,
  selectionModel,
  setScenarioChanged,
  setGridData,
}) {
  const apiRef = useGridApiContext();
  const [columns, setColumns] = useState<any>(() =>
    apiRef.current.getAllColumns().filter((c) => c.field !== "__check__")
  );
  const [newRecord, setNewRecord] = useState<newRecordType>({
    id: "",
    event_type: "",
    seconds: 0,
    _offset: 0,
    integration_type: "",
    primary_property: "",
    secondary_property: "",
    status: "",
    tag_status: "",
    tags: {},
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    let newColumns = apiRef.current
      .getAllColumns()
      .filter((c) => c.field !== "__check__");
    setColumns(newColumns);
    let selectedRowsMap = apiRef.current.getSelectedRows();
    setNewRecord(selectedRowsMap.values().next().value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRecordButtonClick = () => {
    const id = uuidv4();
    apiRef.current.updateRows([{ ...newRecord, id, isNew: true }]);
    setTimeout(() => {
      let rowModelsMap = apiRef.current.getRowModels();
      setGridData(Array.from(rowModelsMap.values()));
    });
    setScenarioChanged(true);
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Copy the selected row">
        <Button
          variant="text"
          size="small"
          color="primary"
          startIcon={<ContentCopyIcon />}
          onClick={handleClickOpen}
          disabled={selectionModel.length !== 1}
        >
          Copy row
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} disableEnforceFocus>
        <DialogTitle>Copy Row</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              Change these fields to create a new row
            </DialogContentText>
            <Divider variant="middle" />

            {columns
              .filter((c) => {
                let editColumn = true;
                if (newRecord.event_type === "PAUSE") {
                  switch (c.field) {
                    case "event_type":
                    case "seconds":
                    case "_offset":
                      editColumn = true;
                      break;

                    default:
                      editColumn = false;
                      break;
                  }
                } else if (newRecord.event_type === "ITAG") {
                  switch (c.field) {
                    case "event_type":
                    case "seconds":
                    case "integration_type":
                    case "primary_property":
                    case "secondary_property":
                    case "status":
                    case "tag_status":
                      editColumn = false;
                      break;

                    default:
                      editColumn = true;
                      break;
                  }
                } else if (newRecord.event_type === "ALERT") {
                  switch (c.field) {
                    case "seconds":
                      editColumn = false;
                      break;

                    default:
                      editColumn = true;
                      break;
                  }
                }
                return editColumn;
              })
              .map((c) => {
                if (c.field.startsWith("tag_")) {
                  return (
                    <TextField
                      key={`input-${c.field}`}
                      id={`input-${c.field}`}
                      type="text"
                      label={c.headerName}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord.tags[c.headerName]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          tags: {
                            ...s.tags,
                            [c.headerName]: e.target.value,
                          },
                        }));
                      }}
                    />
                  );
                }
                if (c.field === "event_type" && c.valueOptions) {
                  return (
                    <TextField
                      disabled={true}
                      key={`input-${c.field}`}
                      id={`input-${c.field}`}
                      select
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                    >
                      {c?.valueOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }
                if (c.type === "singleSelect") {
                  return (
                    <TextField
                      key={`input-${c.field}`}
                      id={`input-${c.field}`}
                      select
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                    >
                      {c.valueOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }
                if (c.type === "number") {
                  return (
                    <TextField
                      key={`input-${c.field}`}
                      id={`input-${c.field}`}
                      type="number"
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: Number(e.target.value),
                        }));
                      }}
                    />
                  );
                }
                if (c.type === "string") {
                  return (
                    <TextField
                      key={`input-${c.field}`}
                      id={`input-${c.field}`}
                      type="text"
                      label={c.field}
                      helperText={c.description}
                      margin="dense"
                      value={newRecord[c.field]}
                      onChange={(e) => {
                        setNewRecord((s) => ({
                          ...s,
                          [c.field]: e.target.value,
                        }));
                      }}
                    />
                  );
                }
                return <></>;
              })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddRecordButtonClick}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
