import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// Icons
// MUI components
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// Custom components
import { initialOrgFormState } from "templates/initialStateTemplates";
import RegionSelector from "components/RegionSelector/RegionSelector";
import useAppState from "store/appState";
import useServerStateMutations from "store/serverState";

import { Logger } from "aws-amplify";
const logger = new Logger("NewOrgDialog", "INFO");

export default function NewOrgDialog({
  open,
  setOpen,
  orgFormState,
  setOrgFormState,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setCurrentDemoConfigId } = useAppState();
  const { createDemoConfig } = useServerStateMutations();

  // prime with current demo config data
  const [apiKey, setApiKey] = useState("");
  const [spocEmail, setSpocEmail] = useState("");
  const [region, setRegion] = useState("USA");
  const [name, setName] = useState(orgFormState.name);

  useEffect(() => {
    if (open) setName(orgFormState.name);
  }, [open, orgFormState.name]);

  const handleCloseDialog = () => {
    setOrgFormState(initialOrgFormState);
    setName("");
    setApiKey("");
    setSpocEmail("");
    setRegion("USA");
    setOpen(false);
  };

  async function handleNewOrgFormDialogSubmit(event) {
    event.preventDefault();
    const newOrgRecord = {
      ...orgFormState,
      name,
      api_key: apiKey,
      spoc_email: spocEmail,
      region,
    };
    logger.info("Creating new DemoConfig:", newOrgRecord);
    createDemoConfig
      .mutateAsync(newOrgRecord)
      .then((newOrgData) => setCurrentDemoConfigId(newOrgData.id))
      .then(handleCloseDialog);
  }

  return (
    <Dialog
      id="new-org-form-dialog"
      data-test="new-org-form-dialog"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="new-org-form-dialog"
      fullScreen={fullScreen}
      //   sx={{ width: 375 }}
      fullWidth={true}
      maxWidth="xl"
      disableEnforceFocus
    >
      <form onSubmit={handleNewOrgFormDialogSubmit}>
        <DialogTitle>Adding a new Demo Config</DialogTitle>
        <DialogContent>
          <DialogContentText>All fields are required.</DialogContentText>
          <Stack spacing={2}>
            <TextField
              id="new-org-fld-name"
              inputProps={{ "data-test": "new-org-fld-name" }}
              fullWidth
              autoFocus
              required
              margin="dense"
              value={name}
              onChange={(event) => setName(event.target.value)}
              label="Name"
              type="text"
              variant="standard"
            />
            <TextField
              id="new-org-fld-api_key"
              inputProps={{
                "data-test": "new-org-fld-api_key",
                "data-lpignore": true,
              }}
              fullWidth
              required
              margin="dense"
              value={apiKey}
              onChange={(event) => setApiKey(event.target.value)}
              label="BigPanda Org User API Key"
              type="text"
              variant="standard"
            />
            <TextField
              id="new-org-fld-spoc_email"
              inputProps={{
                "data-test": "new-org-fld-spoc_email",
                "data-lpignore": true,
              }}
              fullWidth
              required
              margin="dense"
              value={spocEmail}
              onChange={(event) => setSpocEmail(event.target.value)}
              label="SPOC Email"
              type="email"
              variant="standard"
            />
            <RegionSelector region={region} setRegion={setRegion} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            id="btn-submit-new-org-form"
            data-test="btn-submit-new-org-form"
            type="submit"
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
