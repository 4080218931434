/**
 * @module transform_funcs
 * @author Sean Onion 
 * @description Composable data transform functions
 */

var R = require('ramda');
// import R from 'ramda'

// function isString(x: string): boolean {
//     return Object.prototype.toString.call(x) === '[object String]';
// }

function isObject(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

/**
 * @summary Takes Object or JSON and returns Object
 * @function toObject
 * @param {string|Object} payload
 * @requires isValidJSON
 * @requires Ramda
 * @returns {Object} (immutable)
 */
export const toObject = R.cond([
    [isValidJSON, JSON.parse],
    [isObject, R.identity],
    [R.T, () => { throw new Error('ValidationError: Payload is not an object and cannot be parsed as JSON') }]
]);

const pipe = <T>(...fns: Array<(arg: T) => T>) => (value: T) => fns.reduce((acc, fn) => fn(acc), value);

/**
 * @summary Compose promises and non-promises in reverse order (eg left to right) 
 * @param {Object} funcs One or more higher order functions
 * @async 
 * @returns composed functions in reverse order (ie left to right)
 * @requires Ramda
 */
// var pipeP = <T>(...fns: Array<(arg: T) => T>) => (value: T) => fns.reduce((acc, fn) => acc.then(fn), Promise.resolve(value))
export var pipeP = (...fns: any) => (x: any) => fns.reduce((y: any, f: any) => y.then(f), Promise.resolve(x))

/**
 * @summary Formatted mid-composition logger. Used to print out the value in the pipe without altering it.
 * @param {string} msg - A message to prepend to the passed-through value for logging
 * @param {*} x - Any value to write to log and then pass through
 * @returns {*} x
 * @requires Ramda 
 * @example <caption>Example of logging an object and passing through.</caption>
 * // Outputs x and writes to log: "My object: {key1: 'val1'}"
 * trace("My object:")({key1: 'val1'})
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const traceObject = trace("My object:")
 * let cleanedEvent = pipe(traceObject,removeNewLine,traceObject,removeTab,traceObject)(event)
 */
var trace = (msg: string) => R.tap((x: any) => { void (console.log(msg, x)) })

/**
 * kvPropNames is an array of key/value pairs; used to flatten array of objects where object has key and value properties. eg [{name: "host", value: "myhost"},{name: "check", value: "mycheck"}]
 * @summary Convert a nested object to an array of key/value pair arrays
 * @description Unique to BigPanda alert schema, final object is flat, consisting of native types or flat arrays of native types.
 * @param {Array} kvPropNames An array of key/value pair arrays
 * @param {Object} origObj A POJO with any number of levels
 * @requires Ramda
 * @returns {Object} Original object has been flattened (immutable)
 * @example <caption>See tests for examples.</caption>
 */
export var flattenObject = (kvPropNames = [
    ["key", "value"],
    ["name", "value"],
    ["name", "content"],
]) => {
    const flatten: any = (obj_: object) => R.chain(([k, v]: [string, any]) => {
        for (let [keyName, valName] of kvPropNames) {
            if (R.type(v) === 'Object' && R.has(keyName, v) && R.has(valName, v)) {
                return [[v[keyName], v[valName]]]
            }
        }
        if (R.type(v) === 'Object') {
            return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
        }
        if (R.type(v) === 'Array') {
            if (R.any((e: any) => R.type(e) === 'Array')(v)) return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
            if (R.any((e: any) => R.type(e) === 'Object')(v)) return R.map(([k_, v_]: [string, any]) => [`${k}_${k_}`, v_], flatten(v))
        }
        return [[k, v]]
    }, Object.entries(obj_))
    return pipe(flatten, Object.fromEntries)
};

type KeyLTrimMap = string[]
/**
 * @summary Left-trim object keys
 * @param {Array} keyLTrimMap A list used to "left-trim" keys. eg [ "_", "instance_"]
 * @param {Object} origObj flat object
 * @returns {Object} Clone of object with property names (keys) trimmed
 * @example See tests for examples.
 */
export var keyLTrim = (keyLTrimMap: KeyLTrimMap = []) => (origObj: object) =>
    Object.fromEntries(
        Object.entries(origObj).map(pair => {
            let key = pair[0]
            keyLTrimMap.forEach(val => {
                if (key.startsWith(val)) {
                    key = key.substring(key.indexOf(val) + val.length)
                }
            })
            return [key, pair[1]]
        })
    );

interface KeyRenameMap { [index: string]: string }
/**
 * @summary Rename object keys
 * @param {Object} keyRenameMap An enumerator used to rename keys. eg {
      source: 'alert_source',
      hostname: 'host'
    }
 * @param {Object} origObj flat object
 * @returns {Object} Clone of object with property names (keys) renamed
 * @example See tests for examples.
 */
export var renameKeys = (keyRenameMap: KeyRenameMap = {}) => (origObj: object) =>
    Object.fromEntries(
        Object.entries(origObj).map(pair => {
            let key = pair[0]
            for (let prop in keyRenameMap) {
                if (prop === pair[0]) key = keyRenameMap[prop]
            }
            return [key, pair[1]]
        })
    );

type KeyDeleteMap = string[]
/**
 * @summary Delete object keys
 * @param {Object} keyDeleteMap A list used to remove (filter out) keys. eg [
      "emailText",
      "emailHtml",
    ]
 * @param {Object} origObject flat object
 * @returns {Object} Clone of object with specified propterties (keys) filtered out
 * @example See tests for examples.
 */
export function deleteKeys(keyDeleteMap: KeyDeleteMap = []) {
    return (origObj: object) =>
        Object.fromEntries(
            Object.entries(origObj).filter(pair => !keyDeleteMap.includes(pair[0])
            )
        )
}

/**
 * @returns {Boolean}
 */
export function valueMatchesRegex(pattern: string, value: any) { return Boolean(pattern) && new RegExp(pattern, 'mi').test(value) }

const toEpochNumber = (val: number | string): number => Number(String(val.toString()).match(/^[0-9]{1,10}/)[0]);

// function that returns a 10 digit unix epoch value
export function fixTimestamp(tsValue: number | string | undefined | void): number {
    if (Boolean(tsValue) && typeof tsValue === "number") return toEpochNumber(tsValue)
    if (Boolean(tsValue) && typeof tsValue === "string") {
        // handle string of only digits
        if (/^[\d.]+$/.test(tsValue)) return toEpochNumber(tsValue)
        // assume it's a date string
        if (Number.isSafeInteger(Date.parse(tsValue))) return Math.floor(Date.parse(tsValue) / 1000);
    }
    return Math.floor(Date.now() / 1000);
}


/**
 * Sometimes a monitoring tool introduces characters that are illegal for JSON. Note that escape character must be escaped (ie \ becomes \\\\).
 * @summary Replace characters (that prevent JSON.parse) with a space character
 * @param {Array} charRemoveMap - Array of regex patterns
 * @param {String} payload 
 * @returns {Object} New event object
 * @example <caption>Example of removing "\n" from event body.</caption>
 * cleanPayload(['\\\n'])(event.body)
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const removeNewline = cleanPayload(['\\\n'])
 * const removeTab = cleanPayload(['\\\t'])
 * let cleanedPayload = pipe(removeNewline, removeTab)(payload)
 * @requires Ramda
 */
export var cleanPayload = (charRemoveMap = ['\\\n', '\\\t']) => (payload: string) => {
    let newPayload = R.clone(payload)
    let pattern = new RegExp(charRemoveMap.join('|'), 'g')
    return newPayload.replace(pattern, " ")
};

/**
 * Property name is trimmed and non-word characters are replaced with an underscore. Value is trimmed if it's a string.
 * @summary Normalize raw KV pairs 
 * @param {Array} kvPair - An array of two strings representing key and value
 * @param {string} kvPair[].key - A string to be used as the property name
 * @param {*} kvPair[].value - Any type to be used as the property value
 * @example <caption>Example usage of normalizeKV.</caption>
 * // returns ["my_key_1", "ABCdef123!"]
 * normalizeKV(["My-key/1 ", " ABCdef123! "])
 * @returns {Array} new kv pair (immutable)
 */
var normalizeKV = ([...kvPair]) => [
    kvPair[0].trim().replace(/[^0-9a-zA-Z_-]+/g, '_'),
    typeof kvPair[1] === 'string' ? kvPair[1].trim() : kvPair[1]
]

/**
 * Note: Higher order function; returns a function that parses text. Intended for specialization and composition.
 * @function getPropsFromText
 * @summary Parse text into an object using a filter and an array of possible delimiters
 * @param {String} splitCharsExp A RegEx pattern of delimiters. example: '[=:>]{1,}'
 * @param {String} matchCharsExp A RegEx pattern for filtering Key candidates. example: '[\\w \\(\\)]+'
 * @param {String} text text containing potential key/value pairs
 * @requires cleanKvPair
 * @requires Ramda
 * @returns {Object} Plain object with properties parsed from the text
 * @example <caption>Example of single use.</caption>
 * let newObjectFromText = getPropsFromText('[=:>]{1,}', '[\\w \\(\\)]+')('host: myhost \n check: mycheck')
 * // {host: "myhost", check: "mycheck"}
 * @example <caption>Example of specializing (curry).</caption>
 * const extractWordsWithParens = getPropsFromText('[=:>]{1,}', '[\\w \\(\\)]+')
 * let newObjectFromText = extractWordsWithParens('host: myhost \n check: mycheck')
 * // {host: "myhost", check: "mycheck"}
 */
export var getPropsFromText = (splitCharsExp: string, matchCharsExp: string) => {
    let splitChars = new RegExp(splitCharsExp, 'g')
    let matchChars = new RegExp('^' + matchCharsExp + splitCharsExp + '.*', 'i')
    return pipe(
        R.split('\n'),
        R.filter((line: string) => matchChars.test(line)),
        trace('K/V PAIR CANDIDATES:'),
        R.reduce((obj: { [index: string]: any }, str: string) => {
            // grab all matches of the split characters
            let split = Array.from(str.matchAll(splitChars), m => m[0])
            // split on first occurance of split char(s) to create k/v pair, then 'clean' it
            let kvPair = normalizeKV([
                str.slice(0, str.search(split[0])),
                str.slice(str.search(split[0]) + split[0].length)
            ])
            obj[kvPair[0]] = kvPair[1]
            return obj
        }, {})
    )
};

export const setKeysToLowerCase = (origObj: object) => Object.entries(origObj).reduce((acc, [key, val]) => {
    return {
        ...acc,
        [key.toLowerCase()]: val
    }
}, {})

type CharRemoveMap = string[]
/**
 * Sometimes a monitoring tool introduces characters that are illegal for JSON. Note that escape character must be escaped (ie \ becomes \\\\).
 * @summary Replace characters (that prevent JSON.parse) with a space character
 * @param {Array} charRemoveMap - Array of regex patterns
 * @param {String} payload 
 * @returns {String} New string, minus the illegal characters
 * @example <caption>Example of removing "\n" from event body (JSON).</caption>
 * cleanString(['\\\n'])(event.body)
 * @example <caption>Example of specializing (curry) and composition.</caption>
 * const removeNewline = cleanString(['\\\n'])
 * const removeTab = cleanString(['\\\t'])
 * let cleanedBody = pipe(removeNewLine, removeTab)(event.body)
 */
export var cleanString = (charRemoveMap: CharRemoveMap = []) => (payload: string) =>
    payload.replace(new RegExp(charRemoveMap.join('|'), 'g'), " ");

export function absolute(num: number) {
    if (num < 0) return num * -1;
    return num;
}
export type ConvertedCsvTable = ReadonlyArray<any[]>

export type HeadersAndRows = {
    headers: string[];
    rows: { [x: string]: any }[];
};

export const tableToHeadersColumns = function tableToHeadersColumns(table: ConvertedCsvTable): HeadersAndRows {
    let cnt = 0;
    const headers = table[0].map((h) => (Boolean(h) ? h.replace(/\W/g, "_").toLowerCase() : `_${cnt++}`));
    const rows = table.slice(1).map((row) => {
        const eachObject = headers.reduce((obj, header, i) => {
            obj[header] = Boolean(row[i]) ? row[i] : " ";
            return obj;
        }, {});
        return eachObject;
    });
    return { headers, rows };
};

export const randomizeAllNumbersInString = function randomizeAllNumbersInString(str) {
    return str.replace(/[0-9]+/g, (match) => {
        return Math.floor(Math.random() * 10 ** match.length);
    });
}

export const replace = function replace(
    data: any,
    searchPattern: string,
    replacementPattern: string,
    searchGlobal: boolean,
    ignoreCase: boolean
) {
    if (!searchPattern) return data;
    let searchRegex = new RegExp(
        searchPattern,
        `${searchGlobal ? "g" : ""}${ignoreCase ? "i" : ""}`
    );
    if (typeof data === "string") {
        return data.replace(searchRegex, replacementPattern);
    }
    if (typeof data === "number") {
        return Number(data.toString().replace(searchRegex, replacementPattern));
    }
    if (typeof data === "boolean") {
        // replace value of boolean as string then marshal it to boolean
        let replacedValue = data
            .toString()
            .replace(searchRegex, replacementPattern);
        return replacedValue === "true" ? true : false;
    }
    if (Array.isArray(data)) {
        return data.map((item) =>
            replace(item, searchPattern, replacementPattern, searchGlobal, ignoreCase)
        );
    }
    if (typeof data === "object") {
        let newData = {};
        for (let key in data) {
            newData[key] = replace(
                data[key],
                searchPattern,
                replacementPattern,
                searchGlobal,
                ignoreCase
            );
        }
        return newData;
    }
    return data;
}

