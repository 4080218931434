/* 
Downloads these files:
bpClearIncidents-linux-x64
bpClearIncidents-macos-arm64
bpClearIncidents-macos-x64
bpClearIncidents-win-x64.exe
 */

// MUI components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

// Custom components
import CliDownloads from "./CliDownloads";

export default function BpClearIncidents() {
  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Paper
          sx={{
            borderRadius: 2,
            padding: 2,
            margin: 2,
            height: "100%",
          }}
          elevation={1}
        >
          <Typography variant="h5">
            Mass-clear incidents in a running BP org
          </Typography>
          <Divider sx={{ margin: 2 }} />

          <Typography variant="h6">
            This space reserved for future functionality
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={2}>
        <CliDownloads
          fileNames={[
            "bpClearIncidents-linux-x64",
            "bpClearIncidents-macos-arm64",
            "bpClearIncidents-macos-x64",
            "bpClearIncidents-win-x64.exe",
          ]}
        />
      </Grid>
    </Grid>
  );
}
