import React, { useState, Fragment } from "react";
import { Logger } from "aws-amplify";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { replace } from "../../lib/transform_funcs";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import Tooltip from "@mui/material/Tooltip";

const logger = new Logger("SearchReplaceFormDialog", "INFO");

const SearchReplaceFormDialog = ({ setScenarioChanged, setGridData }) => {
  const apiRef = useGridApiContext();
  const [open, setOpen] = useState(false);
  const [searchPattern, setSearchPattern] = useState("");
  const [replacementPattern, setReplacementPattern] = useState("");
  const [ignoreCase, setIgnoreCase] = useState(false);
  const [searchGlobal, setSearchGlobal] = useState(false);

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setSearchPattern("");
    setReplacementPattern("");
    setIgnoreCase(false);
    setSearchGlobal(false);
    setOpen(false);
  };

  const mutateRows = React.useCallback(
    ({ rows, searchPattern, replacementPattern, searchGlobal, ignoreCase }) => {
      if (!searchPattern) return rows;
      logger.info(
        `mutateRows - searchPattern: ${searchPattern}, replacementPattern: ${replacementPattern}, searchGlobal: ${searchGlobal}, ignoreCase: ${ignoreCase}, rows:`,
        rows
      );
      return [...rows].map((row) => {
        logger.info("mutating row", row);
        logger.info("typeof row.tags", typeof row.tags);
        logger.info("array.isArray(row.tags)", Array.isArray(row.tags));
        return replace(
          row,
          searchPattern,
          replacementPattern,
          searchGlobal,
          ignoreCase
        );
      });
    },
    []
  );

  const handleReplace = React.useCallback(() => {
    let currentRows = JSON.parse(
      JSON.stringify(apiRef.current.getSortedRows())
    );
    logger.info("currentRows", currentRows);
    let newRows = mutateRows({
      rows: currentRows,
      searchPattern,
      replacementPattern,
      searchGlobal,
      ignoreCase,
    });
    logger.info("newRows", newRows);
    setTimeout(() => {
      setGridData(newRows);
    });
    setScenarioChanged(true);
  }, [
    apiRef,
    setGridData,
    setScenarioChanged,
    mutateRows,
    searchPattern,
    replacementPattern,
    searchGlobal,
    ignoreCase,
  ]);

  return (
    <Fragment>
      <Tooltip title="Replace value in all records using regex">
        <Button
          variant="text"
          size="small"
          startIcon={<FindReplaceIcon />}
          onClick={handleClickOpen}
        >
          Replace
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} disableEnforceFocus>
        <DialogTitle>Search & Replace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Search and replace using a regex pattern (ECMAscript flavor). For
            help with regex, try{" "}
            <Link href="https://regex101.com/" rel="noreferrer" target="_blank">
              https://regex101.com/
            </Link>
          </DialogContentText>
          <p />
          <TextField
            autoFocus
            margin="dense"
            id="search-pattern"
            label="Search pattern"
            type="search"
            fullWidth
            variant="standard"
            value={searchPattern}
            onChange={(e) => setSearchPattern(e.target.value)}
          />
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ignoreCase}
                  onChange={() => setIgnoreCase(!ignoreCase)}
                />
              }
              label="Ignore Case"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchGlobal}
                  onChange={() => setSearchGlobal(!searchGlobal)}
                />
              }
              label="Global"
            />
          </FormGroup>
          <p />
          <TextField
            margin="dense"
            id="replacement-pattern"
            label="Replacement pattern"
            type="search"
            fullWidth
            variant="standard"
            value={replacementPattern}
            onChange={(e) => setReplacementPattern(e.target.value)}
          />
          <p />
          <Button variant="contained" onClick={handleReplace}>
            Replace
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SearchReplaceFormDialog;

