import * as React from "react";
import { GridColumnDeleteMenuItem } from "./GridColumnDeleteMenuItem";
import { GridColumnInsertMenuItem } from "./GridColumnInsertMenuItem";
import { GridColumnCloneMenuItem } from "./GridColumnCloneMenuItem";

import { GridColumnMenu } from "@mui/x-data-grid-pro";

export default function CustomGridColumnMenu(props) {
  const {
    hideMenu,
    colDef,
    setScenarioChanged,
    setGridData,
    setGridColumns,
    ...other
  } = props;
  // const apiRef = useGridApiContext();

  // const handleMenuItemClick = () => {
  //   hideMenu();
  // };

  if (colDef.field === "tag_status")
    return (
      <GridColumnMenu
        autoFocus={false}
        hideMenu={hideMenu}
        colDef={colDef}
        {...other}
        slots={{
          gridColumnCloneMenuItem: GridColumnCloneMenuItem,
        }}
        slotProps={{
          gridColumnCloneMenuItem: {
            onClick: hideMenu,
            colDef: colDef,
            setScenarioChanged: setScenarioChanged,
            setGridData: setGridData,
            setGridColumns: setGridColumns,
          },
        }}
      />
    );
  if (colDef.field.startsWith("tag_"))
    return (
      <GridColumnMenu
        autoFocus={false}
        hideMenu={hideMenu}
        colDef={colDef}
        {...other}
        slots={{
          gridColumnDeleteMenuItem: GridColumnDeleteMenuItem,
          gridColumnInsertMenuItem: GridColumnInsertMenuItem,
          gridColumnCloneMenuItem: GridColumnCloneMenuItem,
        }}
        slotProps={{
          gridColumnDeleteMenuItem: {
            onClick: hideMenu,
            colDef: colDef,
            setScenarioChanged: setScenarioChanged,
            setGridData: setGridData,
            setGridColumns: setGridColumns,
          },
          gridColumnInsertMenuItem: {
            onClick: hideMenu,
            colDef: colDef,
            setScenarioChanged: setScenarioChanged,
            setGridData: setGridData,
            setGridColumns: setGridColumns,
          },
          gridColumnCloneMenuItem: {
            onClick: hideMenu,
            colDef: colDef,
            setScenarioChanged: setScenarioChanged,
            setGridData: setGridData,
            setGridColumns: setGridColumns,
          },
        }}
      />
    );

  return <GridColumnMenu hideMenu={hideMenu} colDef={colDef} {...other} />;
}
