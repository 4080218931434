import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";
import EditToolbar from "./EditToolbar";
import { DataGridPro, useGridApiRef, LicenseInfo } from "@mui/x-data-grid-pro";
import { Logger } from "aws-amplify";
import CustomGridColumnMenu from "./CustomGridColumnMenu";
import useAppState from "../../store/appState";
import { useDemoConfig } from "../../store/serverState";
const logger = new Logger("DataGridEditor", "INFO");
LicenseInfo.setLicenseKey(
  "2e0178950f56aab5a472ecae03c40653Tz04MzkzNCxFPTE3MzkwNTYzMDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    setScenarioChanged: React.Dispatch<React.SetStateAction<boolean>>;
    setGridData: React.Dispatch<React.SetStateAction<never[]>>;
    setGridColumns: React.Dispatch<React.SetStateAction<never[]>>;
    rowsSelected: boolean;
    selectionModel: string[];
  }
}

const DataGridEditor = ({
  setScenarioChanged,
  defaultPageSize = 10,
  gridData = [],
  setGridData,
  gridColumns = [],
  setGridColumns,
  isLoading = false,
}) => {
  const defaultTheme = useTheme();
  const fullScreen = useMediaQuery(defaultTheme.breakpoints.up("md"));
  const gridApiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: defaultPageSize,
    page: 0,
  });
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);

  const handleProcessRowUpdate = function handleProcessRowUpdate(
    newRow,
    oldRow
  ) {
    setScenarioChanged(true);
    setGridData((state) => {
      const newState = [...state];
      // update existing rows or add a new row
      if (newState.some((row) => row.id === newRow.id)) {
        newState[newState.findIndex((row) => row.id === newRow.id)] = newRow;
      } else newState.push(newRow);
      return newState;
    });
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleProcessRowError = (e) =>
    console.error("onProcessRowUpdateError:", e);

  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rowsSelected, setRowsSelected] = React.useState(false);
  // handleSelectionModelChange runs every time you check or uncheck a row
  const handleSelectionModelChange = function handleSelectionModelChange(
    newSelectionModel
  ) {
    logger.info("SelectionModel changed:", newSelectionModel);
    if (newSelectionModel.length > 0) {
      setRowsSelected(true);
    } else setRowsSelected(false);
    setSelectionModel(newSelectionModel);
  };

  return fullScreen ? (
    <DataGridPro
      sx={{
        boxShadow: 2,
        padding: 1,
        maxWidth: {
          xs: "1024px",
          lg: "1300px",
          xl: "1834px",
        },
        border: 1,
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: "rgb(255,215,115, 0.19)",
          color: "#1a3e72",
          "& .MuiInputBase-root": {
            height: "100%",
          },
        },
        "& .Mui-error": {
          backgroundColor: `rgb(126,10,15,0.1)`,
          color: "error.main",
        },
        borderColor: "primary.light",
        "& .MuiDataGrid-cell:hover": {
          color: "primary.main",
        },
        "& .invalidType": {
          backgroundColor: "mistyrose",
          fontStyle: "oblique",
          fontWeight: "bold",
        },
        "& .missingTag": {
          backgroundColor: "yellow",
          fontStyle: "oblique",
          fontWeight: "bold",
        },
        "& .invalidValue": {
          backgroundColor: "red",
          fontStyle: "oblique",
          fontWeight: "bold",
        },
        "& .tag": {
          fontFamily: "Monospace",
        },
        "& .meta": {
          fontStyle: "oblique",
        },
      }}
      apiRef={gridApiRef}
      autoHeight
      checkboxSelection
      loading={isLoading}
      disableRowSelectionOnClick
      editMode="row"
      // experimentalFeatures={{ newEditingApi: true }}
      processRowUpdate={handleProcessRowUpdate}
      onProcessRowUpdateError={handleProcessRowError}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      slots={{
        toolbar: EditToolbar,
        columnMenu: CustomGridColumnMenu,
      }}
      slotProps={{
        toolbar: {
          setScenarioChanged,
          setGridData,
          setGridColumns,
          rowsSelected,
          selectionModel,
        },
        columnMenu: {
          // @ts-ignore
          setScenarioChanged,
          setGridData,
          setGridColumns,
        },
      }}
      rows={gridData}
      columns={gridColumns}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[5, 10, 50, 100, 1000]}
      pagination
      isCellEditable={(params) => {
        let editable = true;
        if (params.row?.event_type === "PAUSE") {
          switch (params.field) {
            case "seconds":
            case "_offset":
              editable = true;
              break;

            default:
              editable = false;
              break;
          }
        } else if (params.row?.event_type === "ITAG") {
          switch (params.field) {
            case "event_type":
            case "seconds":
            case "integration_type":
            case "primary_property":
            case "secondary_property":
            case "status":
            case "tag_status":
              editable = false;
              break;

            default:
              editable = true;
              break;
          }
        } else if (params.row?.event_type === "ALERT") {
          switch (params.field) {
            case "seconds":
              editable = false;
              break;

            default:
              editable = true;
              break;
          }
        }
        return editable;
      }}
      getCellClassName={(params) => {
        if (params.row.event_type) {
          if (
            params.row?.event_type === "ALERT" &&
            params?.field === "integration_type"
          ) {
            if (!Boolean(params.value)) return "invalidValue";
            let target_integration = demoConfig.integrations.find(
              (i) => i?.integration_type === params.value
            );
            if (!Boolean(target_integration)) return "invalidValue";
          }
          if (
            params.row?.event_type === "ALERT" &&
            params.field === "primary_property"
          ) {
            if (!Boolean(params.value)) return "invalidValue";
            let target_integration = demoConfig?.integrations.find(
              (i) => i?.integration_type === params.row?.integration_type
            );
            if (!params.row?.tags.hasOwnProperty(params.value))
              return "missingTag";
            if (params.value !== target_integration?.primary_property) {
              return "invalidType";
            }
            return "meta";
          }
          if (
            params.row?.event_type === "ALERT" &&
            params.field === "secondary_property"
          ) {
            if (!Boolean(params.value)) return "invalidValue";
            let target_integration = demoConfig?.integrations.find(
              (i) => i.integration_type === params.row?.integration_type
            );
            if (!params.row?.tags.hasOwnProperty(params.value))
              return "missingTag";
            if (params.value !== target_integration?.secondary_property)
              return "invalidType";
            return "meta";
          }
          if (
            params.row?.event_type === "ALERT" &&
            params.field === "tag_status"
          ) {
            if (
              !(
                /[Oo][Kk]/.test(params.value) ||
                /[Cc][Rr][Ii][Tt][Ii][Cc][Aa][Ll]/.test(params.value) ||
                /[Ww][Aa][Rr][Nn][Ii][Nn][Gg]/.test(params.value) ||
                /[Aa][Cc][Kk][Nn][Oo][Ww][Ll][Ee][Dd][Gg][Ee][Dd]/.test(
                  params.value
                ) ||
                /[Uu][Nn][Kk][Nn][Oo][Ww][Nn]/.test(params.value)
              )
            )
              return "invalidValue";
          }
          if (params.field.startsWith("tag_")) {
            return "tag";
          }
          return "meta";
        }
        if (params.row?.integration_type === "change-man") {
          if (params.field === "_offset" || params.field === "integration_type")
            return "meta";
          if (params.field.startsWith("tag_")) {
            return "tag";
          }
          return "tag";
        }
      }}
    />
  ) : (
    <Alert sx={{ m: 2 }} elevation={6} variant="filled" severity="info">
      Screen is too small to display the grid editor
    </Alert>
  );
};

export default DataGridEditor;
