import React, { useState, useEffect, useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
// utilities
import { Logger } from "aws-amplify";
import { useSnackbar } from "notistack";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// mui components
import Grid from "@mui/material/Unstable_Grid2";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
// custom components
import ScenarioTestModal from "components/ScenarioTest/ScenarioTestModal";
import DataGridEditor from "components/DataGridEditor/DataGridEditor";
import AuthContext from "store/AuthContext";
import MultipleSelectChip from "components/MultipleSelectChip";
import { userGroups } from "templates/initialStateTemplates";
import useAppState from "store/appState";
import useServerStateMutations, {
  useDemoConfig,
  useScenario,
  useOrgScenarios,
} from "store/serverState";
import { fetchScenarioOrgs } from "store/graphql-functions";
import useNoticeAction from "hooks/noticeAction";

const logger = new Logger("ScenarioEditor", "INFO");
const filter = createFilterOptions();

const ScenarioEditor = ({ scenarioID, menuCategories }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { orgScenerios } = useOrgScenarios(currentDemoConfigId);
  const {
    removeScenariosFromOrg,
    addOrgScenarios,
    updateScenario,
    deleteScenario,
    createScenario,
  } = useServerStateMutations();

  const {
    data: scenarioData,
    isLoading: scenerioLoading,
    // error: scenarioError, //TODO
    // isFetching: scenarioFetching,
    isInitialLoading,
  } = useScenario(scenarioID);

  const noticeAction = useNoticeAction();

  // Component state
  const [scenarioChanged, setScenarioChanged] = useState(false);
  const [applyScenarioUpdates, setApplyScenarioUpdates] = useState(false);
  const [cancelScenarioUpdates, setCancelScenarioUpdates] = useState(false);
  const { user } = useContext(AuthContext);
  // Scenario State
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [eventData, setEventData] = useState([]);
  const [eventColumns, setEventColumns] = useState([]);
  const [changeData, setChangeData] = useState([]);
  const [changeColumns, setChangeColumns] = useState([]);
  const [groupsCanRead, setGroupsCanRead] = useState([]);
  const [groupsCanEdit, setGroupsCanEdit] = useState([]);
  const [owners, setOwners] = useState([]);

  async function handleChangeScenarioName(e) {
    setScenarioChanged(true);
    setName(e.target.value);
  }
  async function handleChangeScenarioCategory(e) {
    setScenarioChanged(true);
    setCategory(e.target.value);
  }
  async function handleChangeScenarioDescription(e) {
    setScenarioChanged(true);
    setDescription(e.target.value);
  }

  const integrationTypes = React.useMemo(
    () => demoConfig.integrations.map((i) => i.integration_type),
    [demoConfig.integrations]
  );
  const eventsToColumns = useCallback(
    function eventsToColumns(events) {
      return events.reduce(
        (headers, event) => {
          if (event.event_type === "ALERT" || event.event_type === "ITAG") {
            let accessors = headers.map((h) => h?.field);
            let tags = Boolean(event?.tags)
              ? Object.keys(JSON.parse(event.tags))
              : [];
            let newTags = tags.filter((t) => !accessors.includes(`tag_${t}`));
            newTags.forEach((t) =>
              headers.push({
                headerName: t,
                field: `tag_${t}`,
                flex: 1,
                minWidth: 150,
                editable: true,
                valueGetter: (params) => {
                  return params.row.tags
                    ? params.row.tags[`${t}`]
                    : params.row[`${t}`];
                },
                valueSetter: (params) => {
                  return {
                    ...params.row,
                    tags: {
                      ...params.row.tags,
                      [t]: params.value,
                    },
                  };
                },
              })
            );
          }
          return [...headers];
        },
        [
          {
            field: "event_type",
            description: "ALERT, ITAG, or PAUSE",
            width: 120,
            editable: false,
            type: "singleSelect",
            valueOptions: ["ALERT", "PAUSE", "ITAG"],
          },
          {
            field: "_offset",
            description:
              'Seconds from scenario start. Scenario starts at "now" minus the highest scenario offset',
            width: 120,
            editable: true,
            type: "number",
            valueFormatter: ({ value }) => Number(value),
          },
          {
            field: "seconds",
            description: "Seconds to pause (for PAUSE events)",
            width: 120,
            editable: true,
            type: "number",
            valueFormatter: (params) =>
              params.value !== 0 ? params.value : "",
          },
          {
            field: "integration_type",
            description:
              "The integration type for this event. Assign this type to an Org integration in the integrations tab.",
            width: 180,
            editable: true,
            type: "singleSelect",
            valueOptions: [
              ...integrationTypes.filter((t) => t !== "change-man"),
              " ",
            ],
          },
          {
            field: "primary_property",
            description: "The primary property for this event.",
            width: 190,
            editable: true,
            preProcessEditCellProps: (params) => {
              // reset error to false
              const newProp = { ...params.props, error: false };
              logger.info("params:", params);
              //TODO cannot read 'value' if undefined
              if (params.row.event_type === "ALERT") {
                let target_integration = demoConfig.integrations.find(
                  (i) =>
                    i.integration_type ===
                    params.otherFieldsProps.integration_type?.value
                );
                if (
                  params.hasChanged &&
                  target_integration.primary_property !== params.props.value
                )
                  newProp.error = true;
                if (
                  !params.hasChanged &&
                  target_integration.primary_property !== params.props.value
                )
                  newProp.value = target_integration.primary_property;
                if (
                  !params.otherFieldsProps.hasOwnProperty(
                    `tag_${newProp.value}`
                  )
                )
                  newProp.error = true;
              }
              return newProp;
            },
          },
          {
            field: "secondary_property",
            description: "The secondary property for this event.",
            width: 210,
            editable: true,
            preProcessEditCellProps: (params) => {
              const newProp = { ...params.props, error: false };
              if (params.row.event_type === "ALERT") {
                let target_integration = demoConfig.integrations.find(
                  (i) =>
                    i.integration_type ===
                    params.otherFieldsProps.integration_type?.value
                );
                if (
                  params.hasChanged &&
                  target_integration.secondary_property !== params.props.value
                )
                  newProp.error = true;
                if (
                  !params.hasChanged &&
                  target_integration.secondary_property !== params.props.value
                )
                  newProp.value = target_integration.secondary_property;
                if (
                  !params.otherFieldsProps.hasOwnProperty(
                    `tag_${newProp.value}`
                  )
                )
                  newProp.error = true;
              }
              return newProp;
            },
          },
          {
            headerName: "status",
            field: `tag_status`,
            flex: 1,
            minWidth: 150,
            editable: true,
            type: "singleSelect",
            valueOptions: [
              "critical",
              "warning",
              "ok",
              "unknown",
              "acknowledged",
            ],
            valueGetter: (params) => {
              return params.row.tags
                ? params.row.tags[`status`]
                : params.row[`status`];
            },
            valueSetter: (params) => {
              return {
                ...params.row,
                tags: {
                  ...params.row.tags,
                  status: params.value,
                },
              };
            },
          },
        ]
      );
    },
    [demoConfig.integrations, integrationTypes]
  );

  const changesToColumns = useCallback(function changesToColumns(
    columnChanges
  ) {
    return columnChanges.reduce(
      (headers, change) => {
        let accessors = headers.map((h) => h.field);
        let tags = Boolean(change?.tags)
          ? Object.keys(JSON.parse(change.tags))
          : [];
        let newTags = tags.filter((t) => !accessors.includes(`tag_${t}`));
        newTags.forEach((t) =>
          headers.push({
            headerName: t,
            field: `tag_${t}`,
            flex: 1,
            minWidth: 150,
            editable: true,
            valueGetter: (params) => {
              return params.row.tags
                ? params.row.tags[`${t}`]
                : params.row[`${t}`];
            },
            valueSetter: (params) => {
              return {
                ...params.row,
                tags: {
                  ...params.row.tags,
                  [t]: params.value,
                },
              };
            },
          })
        );
        return [...headers];
      },
      [
        {
          field: "_offset",
          description:
            'Seconds from scenario start. Scenario starts at "now" minus the highest scenario offset',
          width: 120,
          editable: true,
          type: "number",
          valueFormatter: ({ value }) => Number(value),
        },
        {
          field: "integration_type",
          description:
            "The integration type for this change. Assign this type to an Org change integration in the integrations tab.",
          width: 180,
          editable: false,
          type: "singleSelect",
          valueOptions: ["change-man"],
        },
        {
          field: "identifier",
          description: "The pseudo change identifier for this change.",
          width: 190,
          editable: true,
        },
        {
          field: "status",
          description: "The status of this change.",
          width: 210,
          editable: true,
          type: "singleSelect",
          valueOptions: ["Planned", "In Progress", "Done", "Canceled"],
        },
        {
          field: "summary",
          description: "The summary of this change.",
          width: 210,
          editable: true,
        },
        {
          field: "ticket_url",
          description: "A pseudo ticket url for this change.",
          width: 210,
          editable: true,
        },
      ]
    );
  }, []);

  useEffect(() => {
    if (!scenerioLoading && scenarioData?.id && !scenarioChanged) {
      //TODO can we use better state than scenarioChanged? (useServerState?)
      logger.info("scenarioData:", scenarioData);
      setName(scenarioData.name || "");
      setCategory(scenarioData.category);
      setDescription(scenarioData.description || "");
      setOwners(scenarioData.owners || []);
      setGroupsCanRead(scenarioData.groupsCanRead || []);
      setGroupsCanEdit(scenarioData.groupsCanEdit || []);
      setEventColumns(eventsToColumns(scenarioData.events));
      setEventData(eventsToRows(scenarioData.events));
      if (scenarioData.changes) {
        setChangeColumns(changesToColumns(scenarioData.changes));
        setChangeData(changesToRows(scenarioData.changes));
      }
    }
  }, [
    scenarioChanged,
    isInitialLoading,
    scenarioID,
    scenarioData,
    demoConfig.name,
    user.username,
    scenerioLoading,
    eventsToColumns,
    changesToColumns,
  ]);

  // Post scenario updates to library
  useEffect(() => {
    if (applyScenarioUpdates) {
      setApplyScenarioUpdates(false);
      const updatedScenarioData = {
        id: scenarioID,
        name: name,
        description: description,
        category: category,
        groupsCanRead: [...groupsCanRead],
        groupsCanEdit: [...groupsCanEdit],
        owners: [...owners],
      };
      updatedScenarioData.events = buildEvents(eventData);
      updatedScenarioData.changes = buildChanges(changeData);
      logger.info(
        `[updateScenario] posting updateScenario mutation with updatedScenarioData:`,
        updatedScenarioData
      );
      updateScenario
        .mutateAsync(updatedScenarioData)
        .then(() => setScenarioChanged(false));
    }
  }, [
    applyScenarioUpdates,
    updateScenario,
    scenarioID,
    name,
    description,
    category,
    groupsCanRead,
    groupsCanEdit,
    eventData,
    changeData,
    owners,
    setApplyScenarioUpdates,
    setScenarioChanged,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (
      (!scenarioChanged && !cancelScenarioUpdates) ||
      (scenarioChanged && cancelScenarioUpdates)
    ) {
      setScenarioChanged(false);
      setCancelScenarioUpdates(false);
    }
  }, [cancelScenarioUpdates, scenarioChanged]);

  const [applyDeleteScenario, setApplyDeleteScenario] = useState(false);
  const [openScenarioDeleteVerifyDialog, setOpenScenarioDeleteVerifyDialog] =
    useState(false);
  const handleVerifiedDeleteScenario = () => {
    setOpenScenarioDeleteVerifyDialog(false);
    setApplyDeleteScenario(true);
  };

  async function handleDeleteScenarioClick(e) {
    let scenarioOrgs = await fetchScenarioOrgs(scenarioID);
    let otherOrgs = scenarioOrgs.orgs.items
      .map((item) => item.org)
      .filter((org) => org.id !== currentDemoConfigId)
      .map((org) => org.name);
    if (otherOrgs.length) {
      enqueueSnackbar(
        `Cannot delete; first remove from these other demo configs: ${otherOrgs.join(
          ", "
        )}`,
        { variant: "error", persist: true, action: noticeAction }
      );
    } else if (groupsCanRead.length && !scenarioChanged) {
      // this scenario is shared; reject request
      enqueueSnackbar(`Cannot delete; un-share it first!`, {
        variant: "error",
      });
    } else if (scenarioChanged) {
      // abandon or commit changes first
      enqueueSnackbar(`Cannot delete; commit or abandon your updates first!`, {
        variant: "error",
      });
    } else setOpenScenarioDeleteVerifyDialog(true);
  }

  useEffect(() => {
    if (applyDeleteScenario) {
      setApplyDeleteScenario(false);
      removeScenariosFromOrg
        .mutateAsync([scenarioID])
        .then(() => deleteScenario.mutateAsync(scenarioID))
        .catch((error) => {
          logger.error("Error deleting scenario:", error);
          if (typeof error === "object" && error.errors) {
            error.errors.forEach((error) =>
              enqueueSnackbar(`Error deleting scenario.`, { variant: "error" })
            );
          } else
            enqueueSnackbar(`Error deleting scenario.`, { variant: "error" });
        });
    }
  }, [
    applyDeleteScenario,
    demoConfig,
    scenarioID,
    scenarioChanged,
    setApplyDeleteScenario,
    enqueueSnackbar,
    deleteScenario,
    removeScenariosFromOrg,
  ]);

  const [removeFromOrg, setRemoveFromOrg] = useState(false);
  async function handleRemoveFromOrgClick(e) {
    setRemoveFromOrg(true);
  }
  useEffect(() => {
    if (removeFromOrg) {
      setRemoveFromOrg(false);
      removeScenariosFromOrg.mutateAsync([scenarioID]);
    }
  }, [
    demoConfig.id,
    removeScenariosFromOrg,
    removeFromOrg,
    scenarioID,
    setRemoveFromOrg,
    enqueueSnackbar,
  ]);

  const handleChangeOwners = (newOwners) => {
    logger.info(`[handleChangeOwners] user.username:`, user.username);
    logger.info(`[handleChangeOwners] owners:`, owners);
    logger.info(`[handleChangeOwners] newOwners:`, newOwners);
    if (!newOwners.includes(user.username)) {
      enqueueSnackbar(
        `Sorry, you can't remove yourself (${user.username}). Add someone else to the owners list, then they can remove you.`,
        { variant: "error" }
      );
    } else {
      setOwners(newOwners);
      setScenarioChanged(true);
    }
  };

  const handleChangeGroupsCanRead = React.useCallback(
    (newGroups) => {
      setGroupsCanRead(newGroups);
      setScenarioChanged(true);
    },
    [setGroupsCanRead, setScenarioChanged]
  );

  const [cloneScenario, setCloneScenario] = useState(false);
  const [openCloneScenarioDialog, setOpenCloneScenarioDialog] = useState(false);
  async function handleCloseCloneScenarioDialog() {
    setOpenCloneScenarioDialog(false);
  }
  const scenarioTemplate = React.useMemo(() => {
    return {
      name: "",
      description: "",
      category: "",
      groupsCanRead: [],
      groupsCanEdit: groupsCanEdit,
    };
  }, [groupsCanEdit]);

  const [newScenario, setNewScenario] = useState(scenarioTemplate);
  async function handleCloneScenarioClick(e) {
    setCloneScenario(true);
    setOpenCloneScenarioDialog(false);
  }
  useEffect(() => {
    if (cloneScenario) {
      setCloneScenario(false);
      setApplyScenarioUpdates(false);
      const clonedScenarioData = { ...newScenario };
      clonedScenarioData.groupsCanEdit = groupsCanEdit;
      clonedScenarioData.owners = [user.username];
      logger.info(
        `[cloneScenario] Cloning scenario with current event data:`,
        eventData
      );
      let updatedEvents = buildEvents(
        Array.isArray(eventData) && eventData.length > 0 ? eventData : []
      );
      updatedEvents.sort((a, b) => a["_offset"] - b["_offset"]);
      logger.info(`[cloneScenario] updated events:`, updatedEvents);
      clonedScenarioData.events = updatedEvents;
      logger.info(
        `[cloneScenario] Cloning scenario with current change data::`,
        changeData
      );
      let updatedChanges = buildChanges(
        Array.isArray(changeData) && changeData.length > 0 ? changeData : []
      );
      updatedChanges.sort((a, b) => a["_offset"] - b["_offset"]);
      logger.info(`[cloneScenario] updated changes:`, updatedChanges);
      clonedScenarioData.changes = updatedChanges;
      logger.info(
        `[cloneScenario] posting new scenario mutation with clonedScenarioData:`,
        clonedScenarioData
      );
      createScenario.mutateAsync(clonedScenarioData).then(
        (
          newScenario //TODO
        ) =>
          addOrgScenarios.mutateAsync({
            orgID: demoConfig.id,
            scenarioIDs: [newScenario.id],
            owners: demoConfig.owners,
          })
      );
      setNewScenario(scenarioTemplate);
    }
  }, [
    demoConfig.id,
    demoConfig.owners,
    groupsCanEdit,
    cloneScenario,
    newScenario,
    changeData,
    eventData,
    createScenario,
    user.username,
    enqueueSnackbar,
    scenarioTemplate,
    addOrgScenarios,
  ]);

  return (
    <Grid
      container
      spacing={2}
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ScenarioTestModal
          eventData={eventData}
          changeData={changeData}
          scenarioName={name}
        />
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          color="warning"
          variant="contained"
          size="medium"
          onClick={handleRemoveFromOrgClick}
          disabled={
            !orgScenerios
              .find((os) => os.scenarioID === scenarioID)
              ?.owners?.includes(user.username)
          }
        >
          REMOVE FROM CONFIG
        </Button>
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          color="success"
          variant="contained"
          size="medium"
          onClick={() => setOpenCloneScenarioDialog(true)}
        >
          CLONE SCENARIO
        </Button>
      </Grid>
      <Grid
        xs={12}
        md={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          data-test="scenario-editor-delete-button"
          color="error"
          variant="contained"
          size="medium"
          sx={{ height: "max-content" }}
          onClick={handleDeleteScenarioClick}
          disabled={owners && !owners.includes(user.username)}
        >
          DELETE SCENARIO
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ButtonGroup
          sx={{ my: 2 }}
          variant="contained"
          // orientation="vertical"
          aria-label="outlined primary button group"
          disabled={!!!scenarioChanged}
        >
          <Button
            id="btn-org-change-submit"
            size="small"
            sx={{ height: "max-content" }}
            color="success"
            onClick={() => setApplyScenarioUpdates(true)}
            disabled={owners && !owners.includes(user.username)}
          >
            POST SCENARIO UPDATES TO STORE
          </Button>
          <Button
            id="btn-org-change-abandon"
            size="small"
            sx={{ height: "max-content" }}
            color="error"
            onClick={() => setCancelScenarioUpdates(true)}
            disabled={owners && !owners.includes(user.username)}
          >
            Abandon updates
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="scenario-name"
          label="Scenario Name"
          fullWidth
          sx={{ minHeight: "max-content" }}
          disabled={owners && !owners.includes(user.username)}
          // helperText="Displays on the Chrome Extension"
          // variant="standard"
          value={name}
          onChange={handleChangeScenarioName}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="select-scenario-category"
          select
          label="Category"
          sx={{ minHeight: "max-content" }}
          fullWidth
          disabled={owners && !owners.includes(user.username)}
          value={category}
          onChange={handleChangeScenarioCategory}
          // helperText="Category for the Chrome Extension"
        >
          {menuCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="scenario-description"
          label="Scenario Description"
          fullWidth
          sx={{ minHeight: "max-content" }}
          multiline
          disabled={owners && !owners.includes(user.username)}
          // helperText="Summarize the scenario script"
          // variant="standard"
          value={description}
          onChange={handleChangeScenarioDescription}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MultipleSelectChip
          label="Groups Can Use"
          data={groupsCanRead}
          setData={handleChangeGroupsCanRead}
          names={userGroups}
          disabled={owners && !owners.includes(user.username)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Autocomplete
          freeSolo
          multiple
          clearIcon={false}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          fullWidth
          sx={{ minHeight: "max-content", pt: 1 }}
          size="small"
          id="scenario-owners"
          options={owners}
          disabled={owners && !owners.includes(user.username)}
          value={owners}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            logger.info("filterOptions options:", options);
            logger.info("filterOptions params:", params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }
            // if (inputValue !== "" && !isExisting) {
            //   filtered.push({
            //     inputValue,
            //     title: `Add "${inputValue}"`,
            //   });
            // }
            logger.info("filterOptions filtered:", filtered);

            return filtered;
          }}
          onChange={(event, newOwners) => {
            logger.info("onchange event:", event);
            logger.info("onchange newOwners:", newOwners);
            if (newOwners?.inputValue) {
              // Create a new value from the user input
              handleChangeOwners(newOwners.inputValue);
            } else {
              handleChangeOwners(newOwners);
            }
          }}
          // getOptionLabel={(option) => {
          //   // Value selected with enter, right from the input
          //   if (typeof option === "string") {
          //     return option;
          //   }
          //   // Add "xxx" option created dynamically
          //   if (option.inputValue) {
          //     return option.inputValue;
          //   }
          //   // Regular option
          //   return option.title;
          // }}
          onInputChange={(event, newInputValue) => {
            logger.info("onInputChange newInputValue:", newInputValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                key={option}
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Scenario Owners" />
          )}
        />
      </Grid>

      <Grid xs={12} display="flex">
        <Card sx={{ boxShadow: 2, flex: 1 }}>
          <CardHeader
            avatar={<Assignment color="primary" />}
            title="Events"
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          <CardContent>
            <DataGridEditor
              setScenarioChanged={setScenarioChanged}
              defaultPageSize={10}
              gridData={eventData}
              setGridData={setEventData}
              gridColumns={eventColumns}
              setGridColumns={setEventColumns}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} display="flex">
        <Card sx={{ boxShadow: 2, flex: 1 }}>
          <CardHeader
            avatar={<Assignment color="primary" />}
            title="Changes"
            titleTypographyProps={{ color: "primary", variant: "h4" }}
          />
          <CardContent>
            <DataGridEditor
              setScenarioChanged={setScenarioChanged}
              defaultPageSize={10}
              gridData={changeData}
              setGridData={setChangeData}
              gridColumns={changeColumns}
              setGridColumns={setChangeColumns}
            />
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        id="clone-scenario-dialog"
        open={openCloneScenarioDialog}
        onClose={handleCloseCloneScenarioDialog}
        disableEnforceFocus
      >
        <DialogTitle>Clone Scenario</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <DialogContentText>
              This will clone the scenario with the current data (ie from the
              editor, not from the store).
            </DialogContentText>
            <Divider variant="middle" />
            <Typography>
              Provide a title, category, and description to help you (and
              others) locate and use your scenario.
            </Typography>
            <TextField
              id="scenario-name"
              label="Scenario Name"
              helperText="The name that will show on DemoSim Chrome Extension"
              variant="standard"
              value={newScenario.name}
              onChange={(e) =>
                setNewScenario({ ...newScenario, name: e.target.value })
              }
            />
            <TextField
              id="select-scenario-category"
              select
              label="Category"
              value={newScenario.category}
              onChange={(e) => {
                setNewScenario({ ...newScenario, category: e.target.value });
              }}
              helperText="Select the category for display in the DemoSim Chrome Extension"
            >
              {menuCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="scenario-description"
              label="Scenario Description"
              helperText="Summarize the scenario script"
              variant="standard"
              value={newScenario.description}
              onChange={(e) =>
                setNewScenario({
                  ...newScenario,
                  description: e.target.value,
                })
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCloneScenarioDialog}>Cancel</Button>
          <Button onClick={handleCloneScenarioClick}>Clone</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openScenarioDeleteVerifyDialog}
        onClose={() => setOpenScenarioDeleteVerifyDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Scenario?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will PERMANENTLY delete this scenario from the Scenario
            Store!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenScenarioDeleteVerifyDialog(false)}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            data-test="dlg-scenario-delete-confirm-button"
            onClick={handleVerifiedDeleteScenario}
          >
            Yes, Delete this scenario
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ScenarioEditor;

function eventsToRows(events) {
  return events.map((event) => {
    return {
      id: uuidv4(),
      event_type: event.event_type,
      _offset: Number(event._offset),
      seconds: Number(event.seconds),
      integration_type: event.integration_type,
      primary_property: event.primary_property,
      secondary_property: event.secondary_property,
      tags: JSON.parse(event.tags),
    };
  });
}

function changesToRows(changes) {
  return changes.map((change) => {
    return {
      id: uuidv4(),
      _offset: Number(change._offset),
      integration_type: change.integration_type,
      identifier: change.identifier,
      status: change.status,
      summary: change.summary,
      ticket_url: change.ticket_url,
      tags: JSON.parse(change.tags),
    };
  });
}

function buildEvents(data) {
  return data.reduce((arr, row) => {
    // ignore id tag
    let {
      event_type,
      _offset,
      seconds,
      integration_type,
      primary_property,
      secondary_property,
      tags,
    } = row;
    return [
      ...arr,
      {
        event_type,
        _offset,
        seconds,
        integration_type,
        primary_property,
        secondary_property,
        tags: JSON.stringify(tags),
      },
    ];
  }, []);
}

function buildChanges(data) {
  return data.reduce((arr, row) => {
    // ignore id tag
    let {
      _offset,
      integration_type,
      identifier,
      status,
      summary,
      ticket_url,
      tags,
    } = row;
    return [
      ...arr,
      {
        _offset,
        integration_type,
        identifier,
        status,
        summary,
        ticket_url,
        tags: JSON.stringify(tags),
      },
    ];
  }, []);
}
