import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// MUI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
// custom components
import useAppState from "store/appState";
import AddVariableDialog from "components/AddVariableDialog/AddVariableDialog";
import DeleteVariableDialog from "components/DeleteVariableDialog/DeleteVariableDialog";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useServerStateMutations, { useDemoConfig } from "store/serverState";

const OrgVariablesTab = () => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { currentDemoConfigId } = useAppState();
  const { demoConfig, bpOrgInfo } = useDemoConfig(currentDemoConfigId);
  const { updateDemoConfig } = useServerStateMutations();
  const [params, setParams] = useState(demoConfig.params);

  useEffect(() => {
    setParams(demoConfig.params);
  }, [demoConfig.params]);

  function handleMutateTags(name, tags) {
    setParams((prevParams) => {
      let newParams = [...prevParams];
      let idx = newParams.findIndex((p) => p.name === name);
      newParams[idx] = { name, values: tags };
      updateDemoConfig.mutate(
        {
          ...demoConfig,
          params: newParams,
        },
      );
      return newParams;
    });
  }

  return (
    <div>
      <LoadingSpinner />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={0} lg={2} />
          <Grid
            item
            xs={12}
            lg={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5">Scenario Variable Data</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption">{`Customize these variables in order to make your scenarios unique to ${bpOrgInfo.organization.name}.`}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={2}
            container
            justifyContent={() => (mobileScreen ? "center" : "flex-end")}
            alignItems="center"
          >
            <AddVariableDialog />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={4} xl={2} margin={1}>
            <Card>
              <CardHeader title="Variable Classes" />
              <CardContent>
                ip_octet, aws_id, a (alpa), d (digit), h (hex)
              </CardContent>
            </Card>
          </Grid>
          {params &&
            [...params]
              .sort((a, b) => -b.name.localeCompare(a.name))
              .map((param) => (
                <Grid
                  item
                  key={param.name}
                  xs={12}
                  sm={8}
                  md={6}
                  lg={4}
                  xl={2}
                  margin={1}
                  minWidth="min-content"
                >
                  <Card sx={{ minWidth: "min-content" }}>
                    <CardHeader
                      title={`Variable: ${param.name}`}
                      action={
                        <DeleteVariableDialog variableCode={param.name} />
                      }
                    />
                    <CardContent>
                      <Autocomplete
                        clearIcon={false}
                        freeSolo
                        multiple
                        sx={{ minHeight: "max-content" }}
                        size="small"
                        id={`org-params-${param.name}`}
                        data-test={`org-params-${param.name}`}
                        options={params
                          .filter((p) => p.name === param.name)
                          .flatMap((p) => p.values)}
                        value={params
                          .filter((p) => p.name === param.name)
                          .flatMap((p) => p.values)}
                        onChange={(event, tags) =>
                          handleMutateTags(param.name, tags)
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              key={option}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{ minWidth: "min-content" }}
                            label={`${param.name} values`}
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default OrgVariablesTab;
