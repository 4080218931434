const initialOrgFormState = {
  name: "",
  bporgname: "",
  api_key: "",
  spoc_email: "",
  region: "USA",
  params: [
    {
      name: "city_code",
      values: ["nyc", "dfw", "sfo"],
    },
    {
      name: "app_code",
      values: ["Card Services", "Billing", "BizForcaster", "ClaimsProcessing"],
    },
    {
      name: "cloud_code",
      values: ["aws", "azure"],
    },
    {
      name: "tier_code",
      values: ["t1", "t2"],
    },
    {
      name: "env_code",
      values: ["prod", "dev"],
    },
    {
      name: "db_code",
      values: ["ora", "mongo", "mysql"],
    },
    {
      name: "geo_code",
      values: ["ap", "eu", "cn", "us"],
    },
    {
      name: "az_code",
      values: ["north-1a", "east-2a", "south-1b", "west-1c"],
    },
    {
      name: "company",
      values: ["acme"],
    },
    {
      name: "urldomain",
      values: ["acme.com"],
    },
  ],
  integrations: [
    {
      integration_type: "net-mon",
      primary_property: "device",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "infra-mon",
      primary_property: "host",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "cloud-mon",
      primary_property: "object",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "log-mon",
      primary_property: "target",
      secondary_property: "search",
      bp_integration: null,
    },
    {
      integration_type: "app-mon",
      primary_property: "object",
      secondary_property: "health_rule",
      bp_integration: null,
    },
    {
      integration_type: "metrics-mon",
      primary_property: "target",
      secondary_property: "condition",
      bp_integration: null,
    },
    {
      integration_type: "synth-mon",
      primary_property: "url",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "cluster-mon",
      primary_property: "object",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "change-man",
      primary_property: "identifier",
      secondary_property: "summary",
      bp_integration: null,
    },
  ],
  owners: []
};

const initialOrgState = {
  id: "",
  name: "",
  bporgname: "",
  api_key: "",
  spoc_email: "",
  region: "",
  params: [
    {
      name: "city_code",
      values: ["nyc", "dfw", "sfo"],
    },
    {
      name: "app_code",
      values: ["Card Services", "Billing", "BizForcaster", "ClaimsProcessing"],
    },
    {
      name: "cloud_code",
      values: ["aws", "azure"],
    },
    {
      name: "tier_code",
      values: ["t1", "t2"],
    },
    {
      name: "env_code",
      values: ["prod", "dev"],
    },
    {
      name: "db_code",
      values: ["ora", "mongo", "mysql"],
    },
    {
      name: "geo_code",
      values: ["ap", "eu", "cn", "us"],
    },
    {
      name: "az_code",
      values: ["north-1a", "east-2a", "south-1b", "west-1c"],
    },
    {
      name: "company",
      values: ["acme"],
    },
    {
      name: "urldomain",
      values: ["acme.com"],
    },
  ],
  integrations: [
    {
      integration_type: "change-man",
      primary_property: "identifier",
      secondary_property: "summary",
      bp_integration: null
    },
    {
      integration_type: "net-mon",
      primary_property: "device",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "infra-mon",
      primary_property: "host",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "cloud-mon",
      primary_property: "object",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "log-mon",
      primary_property: "target",
      secondary_property: "search",
      bp_integration: null,
    },
    {
      integration_type: "app-mon",
      primary_property: "object",
      secondary_property: "health_rule",
      bp_integration: null,
    },
    {
      integration_type: "metrics-mon",
      primary_property: "target",
      secondary_property: "condition",
      bp_integration: null,
    },
    {
      integration_type: "synth-mon",
      primary_property: "url",
      secondary_property: "check",
      bp_integration: null,
    },
    {
      integration_type: "cluster-mon",
      primary_property: "object",
      secondary_property: "check",
      bp_integration: null,
    },
  ],
  owners: []
};

const initialBpOrgInfo = {
  _id: "",
  username: "",
  name: "",
  organization: {
    _id: "",
    name: "",
    display_name: "",
    created_at: "",
    api_token: "",
    config: {
      trial_limit_date: 0,
      periscope_url: "",
      entity_tags_sorter: {},
      type: ""
    },
    feature_toggles: {}
  },
  createdAt: "",
  measure: true,
  roles: [],
  state: "",
  firstLoginAt: "",
  lastLoginAt: "",
  ip: "",
  intercom_user_hash: "",
  permissions: [],
  granular_permissions: {}
}

const menuCategories = [
  'APP',
  'BACKGROUND',
  'CLOUD',
  'CONTAINER',
  'DATABASE',
  'NET',
  'MISC',
  'SECURITY',
  'STORAGE',
  'CUSTOMER',
  'POV',
]

const userGroups = [
  "SalesEngineers",
  "CustomerSuccess",
  "Enablement",
  "ProductManagement",
  "RnD",
  "Guests"
]

const initialBpOrgConfig = {
  _id: "",
  name: "",
  description: "",
  integrations: [],
  environments: [],
  environmentGroups: [],
  correlations: [],
  dashboards: [],
  alertEnrichments: [],
  incidentEnrichments: [],
  requiredTags: []
}
export { initialOrgFormState, initialOrgState, initialBpOrgInfo, menuCategories, userGroups, initialBpOrgConfig };