import React, { useState, useContext } from 'react'

// icons
import MenuIcon from '@mui/icons-material/Menu'
import { styled, useTheme } from "@mui/material/styles";
// mui components
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// utilities
import { Link as RouterLink } from "react-router-dom";
import { Auth } from "aws-amplify";
// custom components
import DrawerMobile from "./DrawerMobile";
import DrawerDesktop from "./DrawerDesktop";
import bplogo from "assets/img/bpse-logo-mobile.jpg";
import AuthContext from "store/AuthContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const SiteAppBar = ({ appRoutes }) => {
  const theme = useTheme();
  const authCtx = useContext(AuthContext);
  const { user, setUser } = authCtx;
  const [userGroups] = useState(
    user.signInUserSession.accessToken.payload["cognito:groups"]
  );
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerPopup = () => setMobileOpen(!mobileOpen);

  const getRoutes = (routes) => {
    return routes
      .filter(
        (route) =>
          route.auth.filter((group) => userGroups.includes(group)).length > 0
      )
      .map((route, index) => (
        <ListItem
          data-test={route.name}
          id={route.name}
          component={RouterLink}
          to={route.path}
          button
          key={index}
        >
          <Tooltip title={route.name} enterDelay={500} placement="right">
            <ListItemIcon>{<route.icon />}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={route.name} />
        </ListItem>
      ));
  };

  return (
    <div id="SiteAppBar">
      <AppBar position="fixed" theme={theme} open={open}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <IconButton
              id="xs-drawer-toggle-menu"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerPopup}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              id="sm-drawer-toggle-menu"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                display: { xs: "none", md: "flex" },
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ mr: 2, display: { xs: "none", md: "flex" } }}>
              <img
                src={bplogo}
                width={100}
                alt="BigPanda Sales Engineering logo"
              />
            </Box>
          </Stack>
          <Typography variant="h6" noWrap component="div">
            BigPanda Sales Engineering
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Button
              id="logout"
              onClick={async () => {
                setUser(undefined);
                Auth.signOut();
              }}
              color="inherit"
            >
              Logout
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <DrawerMobile
        id="DrawerMobile"
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleDrawerPopup={handleDrawerPopup}
      >
        <List id="MobileDrawerList">{getRoutes(appRoutes)}</List>
      </DrawerMobile>
      <DrawerDesktop id="DrawerDesktop" open={open} setOpen={setOpen}>
        <List id="DesktopDrawerList">{getRoutes(appRoutes)}</List>
      </DrawerDesktop>
    </div>
  );
};

export default SiteAppBar
