/* eslint-disable no-template-curly-in-string */
import React, { useState, useCallback, useContext } from "react";

// material-ui icons
import UploadFileIcon from "@mui/icons-material/UploadFile";

// mui components
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";

// custom components
import useAppState from "store/appState";
import {
  newScenarioTemplate,
  baseScenarioTemplate,
} from "templates/scenarioTemplates";
import useServerStateMutations, { useDemoConfig } from "store/serverState";
import AuthContext from "store/AuthContext";

import { Logger } from "aws-amplify";
const logger = new Logger("ScenarioCreator", "INFO");

const ScenarioCreator = ({ menuCategories, newEvents, enqueueSnackbar }) => {
  const { currentDemoConfigId } = useAppState();
  const { demoConfig } = useDemoConfig(currentDemoConfigId);
  const { addOrgScenarios, createScenario } = useServerStateMutations();
  const { user } = useContext(AuthContext);

  // Local state
  const [open, setOpen] = React.useState(false);
  const [newScenario, setNewScenario] = useState(newScenarioTemplate);
  const [publishing, setPublishing] = useState(false);

  const handleClickOpen = () => {
    logger.info("newEvents", newEvents);
    if (newEvents) {
      setNewScenario({
        ...baseScenarioTemplate,
        events: newEvents.map((event) => {
          delete event.id;
          return {
            ...event,
            tags: JSON.stringify(event.tags),
          };
        }),
      });
    }
    setOpen(true);
  };

  const resetDefaults = () => {
    setNewScenario(newScenarioTemplate);
    setPublishing(false);
  };

  const handleClose = () => {
    resetDefaults();
    setActiveStep(0);
    setOpen(false);
  };

  // Stepper functions
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    // return step === 1;
  };
  const isStepSkipped = useCallback(
    (step) => {
      return skipped.has(step);
    },
    [skipped]
  );
  const handleNext = useCallback(() => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }, [activeStep, isStepSkipped, skipped]);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  function getSteps() {
    return ["Configure", "Publish"];
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Configure the Scenario</Typography>
              <Typography>
                Provide a title, category, and description to help you locate
                and use your scenario.
              </Typography>
              <TextField
                id="scenario-creator-name-input"
                inputProps={{ "data-test": "scenario-creator-name-input" }}
                label="Scenario Name"
                helperText="The name that will show on DemoSim Chrome Extension"
                variant="standard"
                value={newScenario.name}
                onChange={(e) =>
                  setNewScenario({ ...newScenario, name: e.target.value })
                }
              />
              <TextField
                id="scenario-creator-category-input"
                data-test="scenario-creator-category-input"
                // inputProps={{ 'data-test': "scenario-creator-category-input" }}
                select
                label="Category"
                value={newScenario.category}
                onChange={(e) => {
                  setNewScenario({ ...newScenario, category: e.target.value });
                }}
                helperText="Select the category for display in the DemoSim Chrome Extension"
              >
                {menuCategories.map((category) => (
                  <MenuItem
                    key={category}
                    value={category}
                    data-test={`category-option-${category}`}
                  >
                    {category}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="scenario-creator-description-input"
                inputProps={{
                  "data-test": "scenario-creator-description-input",
                }}
                label="Scenario Description"
                helperText="Summarize the scenario script"
                variant="standard"
                value={newScenario.description}
                onChange={(e) => {
                  logger.info(
                    "setting decription to e.target.value",
                    e.target.value
                  );
                  setNewScenario({
                    ...newScenario,
                    description: e.target.value,
                  });
                }}
              />
            </Stack>
          </CardContent>
        );
      case 1:
        return (
          <CardContent>
            <Stack direction="column">
              <Typography variant="h5">
                Publish to the Scenario Library
              </Typography>
              <Typography sx={{ m: 2 }}>
                This will store the scenario in the Scenario Library, making it
                available for you to attach to your Demo Configs.
              </Typography>
              {!Boolean(newScenario.name) && (
                <Alert severity="error">
                  No scenario name; go back to the Configure step!
                </Alert>
              )}
              {!Boolean(newScenario.category) && (
                <Alert severity="error">
                  No scenario category; go back to the Configure step!
                </Alert>
              )}
              {!Boolean(newScenario.description) && (
                <Alert severity="error">
                  No scenario description; go back to the Configure step!
                </Alert>
              )}
            </Stack>
          </CardContent>
        );
      default:
        return <Typography variant="h2">All steps completed</Typography>;
    }
  }

  function handlePublishScenario() {
    setPublishing(true);
    let finalScenario = {
      ...newScenarioTemplate,
      ...newScenario,
      owners: [user.username],
    };
    logger.info("finalScenario", finalScenario);
    createScenario
      .mutateAsync(finalScenario)
      .then((newScenario) =>
        addOrgScenarios.mutateAsync({
          orgID: demoConfig.id,
          scenarioIDs: [newScenario.id],
          owners: demoConfig.owners,
        })
      )
      .then(() => {
        enqueueSnackbar(
          `Scenario ${newScenario.name} created and added to Demo Config ${demoConfig.name}`,
          {
            variant: "success",
          }
        );
      })
      .then(handleClose);
  }

  return (
    <Box>
      <Button
        id="btn-scenario-create"
        data-test="btn-scenario-create"
        sx={{ minWidth: "max-content" }}
        variant="outlined"
        color="info"
        size="small"
        onClick={handleClickOpen}
        startIcon={<UploadFileIcon />}
      >
        Create New Scenario
      </Button>
      <Dialog
        id="dlg-scenario-creator"
        data-test="dlg-scenario-creator"
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus
      >
        <DialogTitle>Create New DemoSim Scenario</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, mx: 2 }}>
            This wizard will create a new DemoSim scenario with examples of both
            alert events and changes.
          </DialogContentText>
          <Stack direction="column">
            <Stepper
              id="stepper-header"
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack
                id="stepper-content-complete"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography>All steps completed</Typography>
              </Stack>
            ) : (
              <Stack
                id="stepper-content-incomplete"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                {getStepContent(activeStep)}
                <Stack
                  id="stepper-buttons"
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={12}
                >
                  <Button
                    variant="outlined"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      data-test="scenario-creator-publish-button"
                      variant="outlined"
                      color="success"
                      disabled={
                        !Boolean(newScenario.name) ||
                        !Boolean(newScenario.category) ||
                        !Boolean(newScenario.description) ||
                        publishing
                      }
                      onClick={handlePublishScenario}
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      data-test="scenario-creator-nextstep-button"
                      variant="outlined"
                      color="primary"
                      onClick={handleNext}
                    >
                      NEXT
                    </Button>
                  )}
                </Stack>
                {publishing && <Alert severity="info">Publishing...</Alert>}
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ScenarioCreator;
