import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import AddRowFormDialog from "./AddRowFormDialog";
import CopyRowFormDialog from "./CopyRowFormDialog";
import SearchReplaceFormDialog from "./SearchReplaceFormDialog";
import UploadDataFormDialog from "./UploadDataFormDialog";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  LicenseInfo,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
// import { Logger } from "aws-amplify";
// const logger = new Logger("EditToolBar", "INFO");
LicenseInfo.setLicenseKey(
  "2e0178950f56aab5a472ecae03c40653Tz04MzkzNCxFPTE3MzkwNTYzMDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const EditToolbar = (props) => {
  const {
    setScenarioChanged,
    setGridData,
    // setGridColumns,
    rowsSelected,
    selectionModel,
  } = props;

  const apiRef = useGridApiContext();

  const handleDeleteRecordsButtonClick = () => {
    let selectedRows = apiRef.current.getSelectedRows();
    apiRef.current.updateRows(
      Array.from(selectedRows.keys()).map((id) => {
        return { id, _action: "delete" };
      })
    );
    let rowModels = apiRef.current.getRowModels(); // gets all the rows as a Map (after the updateRows action)
    let updatedRowIds = Array.from(rowModels.keys());

    setGridData((state) => {
      const newState = [...state].filter((row) =>
        updatedRowIds.some((updatedRowId) => row.id === updatedRowId)
      );
      return newState;
    });
    setScenarioChanged(true);
  };

  return (
    <GridToolbarContainer sx={{ borderBottom: 1, pb: 1 }}>
      <Grid
        container
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        columns={9}
        width="100%"
      >
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <GridToolbarColumnsButton />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <GridToolbarFilterButton />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <GridToolbarDensitySelector />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <GridToolbarExport csvOptions={{ allColumns: true }} />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <UploadDataFormDialog
            setScenarioChanged={setScenarioChanged}
            setGridData={setGridData}
          />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <SearchReplaceFormDialog
            setScenarioChanged={setScenarioChanged}
            setGridData={setGridData}
          />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <AddRowFormDialog
            setScenarioChanged={setScenarioChanged}
            setGridData={setGridData}
          />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <CopyRowFormDialog
            setScenarioChanged={setScenarioChanged}
            rowsSelected={rowsSelected}
            selectionModel={selectionModel}
            setGridData={setGridData}
          />
        </Grid>
        <Grid xs={1} display="flex" justifyContent="center" alignItems="center">
          <Tooltip title="Delete the selected row">
            <Button
              variant="text"
              size="small"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteRecordsButtonClick}
              disabled={!rowsSelected}
            >
              Delete row(s)
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default EditToolbar;
