/**
 * @abstract Top-level app structure. App bar, side drawer, and 'main' view.
 * @description Use react-router to display different View components in the main box.
 * Specifically, the ListItem's in Drawer use "component={RouterLink}" and "to" to set the path;
 * Routes then use the path to choose a Component to present in the main Box.
 * Views are added through the appRoutes.js file
 */

// utilities
import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import appRoutes from "./appRoutes";
import AuthContext from "./store/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import amplifyStyles from "assets/css/Amplify.css";
import setools from "assets/css/setools.css";
import { deepmerge } from "@mui/utils";
// mui components
import Box from "@mui/material/Box";

// custom components
import SiteAppBar from "./navigation/SiteAppBar";
import DrawerHeader from "./navigation/DrawerHeader";
import Login from "./views/auth/Login";
import Dashboard from "./views/admin/Dashboard";

import { Amplify, Auth, Hub, Logger, Storage } from "aws-amplify";
import awsExports from "./aws-exports";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "2e0178950f56aab5a472ecae03c40653Tz04MzkzNCxFPTE3MzkwNTYzMDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

window.LOG_LEVEL = isLocalhost() ? "INFO" : "ERROR";
const logLevel = isLocalhost() ? "INFO" : "ERROR";
const logger = new Logger("App", logLevel);

// logger.info('info bar');
// logger.info('debug bar');
// logger.warn('warn bar');
// logger.error('error bar');

//  Old Okta instructions: https://aws.amazon.com/premiumsupport/knowledge-center/cognito-okta-saml-identity-provider/
// Nov 21: Okta/SAML configured per https://www.techpearl.com/blog/integrating-okta-with-amazon-cognito/index.html
Amplify.configure({
  ...awsExports,
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: `${window.location.origin}/admin/dashboard/`,
    redirectSignOut: `${window.location.origin}/auth/login-page/`,
  },
});
// Amplify.Logger.LOG_LEVEL = 'DEBUG'

Storage.configure({ track: true });

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const siteTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            // BP Blue
            main: "#0072ce",
            light: "#66baff",
            dark: "#002ad6",
          },
          secondary: {
            // BP Yellow
            main: "#f8c800",
          },
          bigpanda: {
            blue: "#0072ce",
            bluelight: "#66baff",
            bluedark: "#002ad6",
            gray: "#6c6c6c",
            yellow: "#f8c800",
            purple: "#75399b",
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                backgroundColor: prefersDarkMode ? "#121212" : "#f5f5f5",
              },
            },
          },
          MuiAppBar: {
            defaultProps: {
              elevation: 0,
            },
          },
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true,
            },
          },
          MuiStack: {
            defaultProps: {
              useFlexGap: true,
              spacing: 2,
              direction: "row",
            },
          },
        },
      }),
    [prefersDarkMode]
  );
  let theme = React.useMemo(
    () => responsiveFontSizes(createTheme(deepmerge(siteTheme, amplifyStyles))),
    [siteTheme]
  );

  const authCtx = useContext(AuthContext);
  const { user, setUser } = authCtx;

  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        setUser(data);
      })
      .catch((err) => setUser(undefined));
  }, [setUser]);

  useEffect(() => {
    let authListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        logger.debug(
          `Hub auth: event: ${event}, user: ${JSON.stringify(data)}`
        );
        switch (event) {
          case "signIn":
            // case 'cognitoHostedUI':
            let data = await Auth.currentAuthenticatedUser().catch((err) => {
              setUser(undefined);
              return undefined;
            });
            setUser(data);
            break;
          case "signOut":
          case "oAuthSignOut":
            setUser(undefined);
            break;
          case "signIn_failure":
          case "cognitoHostedUI_failure":
            logger.error("Sign in failure", data);
            break;
          default:
            break;
        }
      }
    );

    return () => {
      authListener();
    };
  }, [setUser]);

  return user ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex", width: "fill-available" }}>
        <SiteAppBar appRoutes={appRoutes} />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader theme={theme} id="DrawerHeader" />
          <Routes>
            {appRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route index element={<Dashboard />} />
          </Routes>
          <ReactQueryDevtools initialIsOpen />
          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Login />
    </ThemeProvider>
  );
};

export default App;

function isLocalhost() {
  return Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
  );
}
