import { ReactNode, SyntheticEvent, useState } from "react";

// MUI components
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom components
import SiConfigGen from "./AiAssistPanels/SiConfigGen";
import CorrPattGen from "./AiAssistPanels/CorrPattGen";
// Custom hooks and functions

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export default function AiAssist() {
  // Global state
  // Local state
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper id="Ai Assist Page" className="RouteContainer">
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          aria-label="full width tabs example"
        >
          <Tab label="Similar Incidents" {...a11yProps(0)} />
          <Tab label="Correlation Patterns" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SiConfigGen />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CorrPattGen />
      </TabPanel>
    </Paper>
  );
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
