import React, { Fragment } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { useGridApiContext } from '@mui/x-data-grid-pro'

const GridColumnDeleteMenuItem = props => {
  const { colDef, onClick, setScenarioChanged, setGridData, setGridColumns } =
    props;
  const apiRef = useGridApiContext()

  if (!colDef) {
    return null;
  }

  const deleteColumn = () => event => {
    let allColumns = apiRef.current.getAllColumns()
    let mutatedColumns = allColumns.filter((c) => c.field !== colDef.field);
    let rowModelsMap = apiRef.current.getRowModels()
    let unmutatedRowModels = Array.from(rowModelsMap.values())
    let mutatedRowModels = unmutatedRowModels.map(row => {
      let newRow = Object.assign({}, row)
      if (newRow.tags) {
        delete newRow.tags[colDef.headerName];
      }
      return newRow
    })

    // Wait for the grid to render with the new row
    setTimeout(() => {
      setGridData(mutatedRowModels)
      setGridColumns(mutatedColumns)
    })
    setScenarioChanged(true)
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Fragment>
      <MenuItem 
      onClick={deleteColumn()}>Delete Tag</MenuItem>
    </Fragment>
  )
}

export { GridColumnDeleteMenuItem }
